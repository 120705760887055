import React, { useRef } from 'react';
import { Field } from 'formik';
import { Form } from 'react-bootstrap';
import { Hint, Typeahead } from 'react-bootstrap-typeahead';
import Tooltip from '../Tooltip/Tooltip';
import FlagNone from '../../../assets/flags/flags_none.png';
import ChevronDown from '../../../assets/icons/chevron_down.svg';
import ChevronUp from '../../../assets/icons/chevron_up.svg';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { directionLayout } from '../../../utils/constants';

import './Typeahead.css';

type TFieldInfo = {
  info: string;
  placement: any;
};

type TTypeahead = {
  label: string;
  mandatoryLabel?: string;
  name: string;
  type: string;
  labelKey?: string;
  placeholder: string;
  options: any[];
  filterBy?: [];
  onChange?: (e) => {};
  onInputChange?: (e) => {};
  menuItemChildren?: () => {};
  checkMark?: boolean;
  ariaLabel?: string;
  emptyLabel?: string;
  fieldInfo?: TFieldInfo;
};

const FormikTypeahead = (props: TTypeahead) => {
  const {
    label,
    mandatoryLabel,
    name,
    type,
    placeholder,
    labelKey = 'name',
    filterBy = ['name', 'code'],
    onChange,
    onInputChange,
    menuItemChildren,
    options,
    checkMark = false,
    ariaLabel,
    emptyLabel,
    fieldInfo,
    ...rest
  } = props;
  const typeaheadRef = useRef(null as any);
  const focusTypeahead = () => {
    typeaheadRef.current?.focus();
  };
  const isMediumDevice = useMediaQuery('only screen and (min-width : 320px) and (max-width : 767px)');

  return (
    <Field name={name}>
      {({ field, form }) => {
        const { errors, touched, setFieldValue, setFieldTouched } = form;
        const { value } = field;

        const error = name.split('.').reduce((acc, current) => acc && acc[current], errors);
        const touch = name.split('.').reduce((acc, current) => acc && acc[current], touched);

        const renderIcon = () => {
          if (value?.length) {
            return (
              <span
                className={`fi fi-${value[0]?.code?.toLowerCase()} flag-icon`}
                onClick={focusTypeahead}
              ></span>
            );
          } else {
            return (
              <img
                className="flag-icon"
                alt="flag-icon"
                src={FlagNone}
                onClick={focusTypeahead}
              />
            );
          }
        };
        const renderFieldInfo = () => {
          if (!fieldInfo || !fieldInfo?.info) {
            return null;
          }

          return (
            <Tooltip
              info={fieldInfo.info}
              placement={
                isMediumDevice
                  ? 'bottom'
                  : document.documentElement.dir === directionLayout.ltr
                  ? 'right'
                  : document.documentElement.dir === directionLayout.rtl
                  ? 'left'
                  : 'right'
              }
            />
          );
        };

        return (
          <>
            <Typeahead
              ref={typeaheadRef}
              id={name}
              multiple={false}
              options={options}
              labelKey={labelKey}
              filterBy={filterBy}
              align="left"
              placeholder={placeholder}
              {...rest}
              {...field}
              onChange={(value) => {
                setFieldValue(name, value);
                if (onChange) onChange(value);
              }}
              emptyLabel={emptyLabel}
              onInputChange={onInputChange}
              onBlur={() => setFieldTouched(name)}
              renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
                <Hint>
                  <Form.Floating>
                    <Form.Control
                      {...inputProps}
                      ref={(node) => {
                        inputRef(node);
                        referenceElementRef(node);
                      }}
                      className="custom-typeahead"
                      type="text"
                      autoComplete="new-password"
                      aria-label={ariaLabel}
                      title={label + (mandatoryLabel ?? '')}
                      value={inputProps.value as string[]}
                      isValid={checkMark && !!value.length && !error}
                      isInvalid={!!error && !!touch}
                      id={name}
                    />
                    {renderIcon()}
                    <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                    <label
                      htmlFor={name}
                      className="field-label"
                    >
                      {label} <span className="legal-id-mandatory">{mandatoryLabel}</span>
                      {renderFieldInfo()}
                    </label>
                  </Form.Floating>
                </Hint>
              )}
              selectHint={(shouldSelect, e) => {
                setFieldTouched(name, false);

                // Select the hint if the user hits 'enter' or ','
                return e.keyCode === 13 || e.keyCode === 188 || shouldSelect;
              }}
              renderMenuItemChildren={menuItemChildren}
              selected={value}
            >
              {({ isMenuShown }) => (
                <img
                  className="chevron-icon"
                  src={isMenuShown ? ChevronUp : ChevronDown}
                  alt=""
                  onClick={focusTypeahead}
                />
              )}
            </Typeahead>
          </>
        );
      }}
    </Field>
  );
};

export default FormikTypeahead;
