import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import shipment from '../../../assets/icons/shipment.svg';
import plan from '../../../assets/icons/plan.svg';
import cart from '../../../assets/icons/cart.svg';

import { Carousel } from '../../common';
import './ThankYouPage.css';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

const Services = () => {
  const { translations } = useSelector((state: any) => state.account);
  const { thankyou: thankyouContent } = translations;
  const { services, accessibility } = thankyouContent;
  const { serviceList } = services;
  const { services: accessibilityServices } = accessibility;
  const { serviceList: accessibilityServiceList } = accessibilityServices;

  const serviceImages = { 0: shipment, 1: plan, 2: cart };
  const isMobileDevice = useMediaQuery('only screen and (min-width : 320px) and (max-width : 767px)');

  return (
    <>
      <div className="row">
        <div className="text-center mx-auto p-0 mt-4 mb-5 thankyou-page-services-section col-md-10">
          <h2 className="thankyou-card-header">{services?.HEADING}</h2>
          {isMobileDevice ? (
            <Carousel
              slide={true}
              interval={null}
              controls={false}
              wrap={true}
              fade={true}
              className="dao-services-mobile"
            >
              {Object.keys(serviceList).map((item) => {
                return (
                  <Carousel.Item key={item}>
                    <a
                      className="thankyou-card"
                      href={serviceList[item].URLROUTE}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="shadow rounded bg-white text-center p-4 h-100">
                        <img
                          src={serviceImages[item]}
                          alt={accessibilityServiceList[item].IMG_ALT}
                          className="thankyou-card-image"
                        />
                        <p className="thankyou-card-title">{serviceList[item].LABEL}</p>
                        <p className="thankyou-card-desc">{serviceList[item].DESC}</p>
                      </div>
                    </a>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          ) : (
            <Row className="dao-services-desktop">
              {Object.keys(serviceList).map((item) => {
                return (
                  <Col key={item}>
                    <a
                      className="thankyou-card"
                      href={serviceList[item].URLROUTE}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className="shadow rounded bg-white text-center p-4 h-100">
                        <img
                          src={serviceImages[item]}
                          alt={accessibilityServiceList[item].IMG_ALT}
                          className="thankyou-card-image"
                        />
                        <p className="thankyou-card-title">{serviceList[item].LABEL}</p>
                        <p className="thankyou-card-desc">{serviceList[item].DESC}</p>
                      </div>
                    </a>
                  </Col>
                );
              })}
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

export default Services;
