import React from 'react';
import { useSelector } from 'react-redux';

import { Button } from '../../common';
import DynamicBackgroundImage from '../../common/DynamicBackgroundImage/DynamicBackgroundImage';
import DynamicImage from '../../common/DynamicImage/DynamicImage';
import ShippingRates from '../../../assets/icons/shipping_rates.svg';
import TRUSTEDSERVICES from '../../../assets/icons/trusted_service.svg';
import FlexibleBenefits from '../../../assets/icons/flexible.svg';
import WorldwideServices from '../../../assets/icons/worldwide.svg';
import './Account.css';
import { directionLayout } from '../../../utils/constants';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

function Account({ openCorporateAccount }) {
  const { translations, loadingTranslationsSuccess } = useSelector((state: any) => state.account);
  const { countryConfigData, loading, loadingCountryConfig } = useSelector((state: any) => state.corporateAccount);
  const defaultDisplayLang = countryConfigData?.ctryCfgs?.find(
    (ctryCfg) => ctryCfg.typeCd === 'DEFAULT_DISPLAY_LANGUAGE'
  )?.ctryCd;

  const {
    common: { marketing },
    image,
    direction,
  } = translations;

  const isDaoCountry = countryConfigData?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DAO_COUNTRY')?.value === 'Y';
  const urlDomain = process.env.REACT_APP_URL;
  const isApiLoading = loadingTranslationsSuccess || loading || loadingCountryConfig;
  const isMobileDevice = useMediaQuery('only screen and (min-width : 320px) and (max-width : 767px)');
  const isDesktopDevice = useMediaQuery('only screen and (min-width : 768px)');
  const bannerImgSrc =
    isMobileDevice && direction === directionLayout.ltr
      ? 'mobile-'
      : isDesktopDevice && direction === directionLayout.rtl
      ? 'rtl-'
      : isMobileDevice && direction === directionLayout.rtl
      ? 'rtl-mobile-'
      : '';

  return (
    <>
      {countryConfigData?.ctryCfgs?.length > 0 && (isDesktopDevice || isMobileDevice) && (
        <>
          <DynamicBackgroundImage
            src={`${urlDomain}/assets/${defaultDisplayLang}/images/${bannerImgSrc}banner-marketing-img.png?${image?.value}`}
            defaultSrc={`${urlDomain}/assets/DEFAULT/images/${bannerImgSrc}banner-marketing-img.png?${image?.value}`}
            isDaoCountry={isDaoCountry}
            direction={direction}
            className="account-banner"
          >
            <div
              className="banner-title"
              dangerouslySetInnerHTML={{
                __html: marketing.BANNER_TITLE,
              }}
            ></div>
            <p
              className="banner-text"
              dangerouslySetInnerHTML={{
                __html: marketing.BANNER_TEXT,
              }}
            ></p>
            <Button
              variant="primary"
              className={`px-4 py-2 m-0 open-account ${isMobileDevice ? 'w-100' : ''}`}
              onClick={openCorporateAccount}
              disabled={isApiLoading}
            >
              {marketing.LABEL_OPEN_CORPORATE_ACCOUNT}
            </Button>
          </DynamicBackgroundImage>
        </>
      )}
      <div className="exclusive-container">
        <h1 className="exclusive-title">{marketing.EXCLUSIVE_BENEFITS_TITLE}</h1>
        <p className="exclusive-text">{marketing.EXCLUSIVE_BENEFITS_TEXT}</p>
        <div className="row">
          <div className={`exclusive-section col-md-6 ${isMobileDevice ? 'order-sm-1' : 'order-md-0'}`}>
            <div className="d-flex exclusive-mb-2">
              <div className="exclusive-icon">
                <img
                  alt={marketing.IMG_SHIPPING_RATES_ALT}
                  src={ShippingRates}
                />
              </div>
              <div className="w-75">
                <h2 className="benefits-title">{marketing.SHIPPING_RATES_TITLE}</h2>
                <p className="benefits-text m-0">{marketing.SHIPPING_RATES_TEXT}</p>
              </div>
            </div>
            <div className="d-flex exclusive-mb-2">
              <div className="exclusive-icon">
                <img
                  alt={marketing.IMG_DELIVERY_OPTIONS_ALT}
                  src={FlexibleBenefits}
                />
              </div>
              <div className="w-75">
                <h2 className="benefits-title">{marketing.DELIVERY_OPTIONS_TITLE}</h2>
                <p className="benefits-text m-0">{marketing.DELIVERY_OPTIONS_TEXT}</p>
              </div>
            </div>
            <div className="d-flex exclusive-mb-2">
              <div className="exclusive-icon">
                <img
                  alt={marketing.IMG_DELIVERY_OPTIONS_ALT}
                  src={WorldwideServices}
                />
              </div>
              <div className="w-75">
                <h2 className="benefits-title">{marketing.SHIPPING_SERVICES_TITLE}</h2>
                <p className="benefits-text m-0">{marketing.SHIPPING_SERVICES_TEXT}</p>
              </div>
            </div>
            <div className="d-flex">
              <div className="exclusive-icon">
                <img
                  alt={marketing.IMG_TRUSTED_SERVICES_ALT}
                  src={TRUSTEDSERVICES}
                />
              </div>
              <div className="w-75">
                <h2 className="benefits-title">{marketing.TRUSTED_SERVICES_TITLE}</h2>
                <p className="benefits-text m-0">{marketing.TRUSTED_SERVICES_TEXT}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {countryConfigData?.ctryCfgs?.length > 0 && (
              <DynamicImage
                src={`${urlDomain}/assets/${defaultDisplayLang}/images/exclusive-img.png?${image?.value}`}
                defaultSrc={`${urlDomain}/assets/DEFAULT/images/exclusive-img.png?${image?.value}`}
                isDaoCountry={isDaoCountry}
                alt={marketing.IMG_EXCLUSIVE_BENEFITS_ALT}
                className="exclusive-img"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Account;
