import { takeEvery, put, select } from 'redux-saga/effects';
import { Etl } from '../../utils/types';

import { SET_REVIEW_ETL_LIST, UPDATE_CUSTOMER_CONSENT } from '../actions/actionTypes';
import { setEtlList, setEtlStatus } from '../actions/appActions';
import { setCustomerConsent } from '../actions/reviewActions';

export function* reviewSaga() {
  yield takeEvery(UPDATE_CUSTOMER_CONSENT, updateCustomerConsentSaga);
  yield takeEvery(SET_REVIEW_ETL_LIST, setReviewEtlListSaga);
}

export function* updateCustomerConsentSaga(action) {
  yield put(setCustomerConsent(action.payload.key, action.payload.value));
}

export function* setReviewEtlListSaga(action) {
  let currentEtlList: any = [];
  const breDetails = action.payload;
  const { breRes } = breDetails;
  const reviewEtlList: Etl[] = [];
  const { breErrorCode } = yield select((state) => state.DAOReducer);
  const { contactCompanyEtlList } = yield select((state) => state.app);

  const breConventional = !!breRes?.fldErrs?.find((error) => error?.ErrMsgDtl?.Sev === 40);
  const isExistingCustomer = !!breRes?.fldWarns?.find((error) => error?.ErrMsgDtl?.MsgCIgd === '231301');
  const noBillingTerms = !!breRes?.fldErrs?.find((error) => error?.ErrMsgDtl?.MsgCIgd === '2006');

  if (isExistingCustomer) {
    reviewEtlList.push({ code: '2001', source: 'review' });
  } else if (breConventional && !noBillingTerms) {
    reviewEtlList.push({ code: '4004', source: 'review' });
  } else if (noBillingTerms && breConventional) {
    reviewEtlList.push({ code: '2006', source: 'review' });
  } else if (breErrorCode >= 500 || breErrorCode === 0) {
    reviewEtlList.push({ code: '9005', source: 'review' });
  }

  currentEtlList = [...contactCompanyEtlList, ...reviewEtlList];
  const isEtl = currentEtlList.length > 0;

  yield put(setEtlStatus(isEtl));
  yield put(setEtlList(currentEtlList));
}

export default reviewSaga;
