import React from 'react';
import { useSelector } from 'react-redux';

import { Modal, Button } from '../../common';

type TPaymentModal = {
  showModal: boolean;
  handleClose: () => void;
  addLater: () => void;
};

const AddLaterModal = ({ showModal, handleClose, addLater }: TPaymentModal) => {
  const { translations } = useSelector((state: any) => state.account);
  const {
    payment: { modal },
  } = translations;

  return (
    <Modal
      className="custom-modal"
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={true}
    >
      <Modal.Header>
        <Modal.Title>{modal.ADD_LATER_TITLE}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modal.ADD_LATER_MSG}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="link"
          className="custom-modal-link"
          onClick={addLater}
        >
          {modal.LABEL_ADD_LATER}
        </Button>
        <Button
          variant="secondary"
          onClick={handleClose}
        >
          {modal.LABEL_ADD_PAYMENT_METHOD}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddLaterModal;
