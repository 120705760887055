import {
  ADDRESS_DISABLED_CATEGORIES,
  bAddressMapper,
  companyFieldsMapper,
  vAddressMapper,
} from '../../utils/constants';
import { getMappedFields } from '../../utils/helper';
import {
  GET_COUNTRY_REG_TYPE_DATA,
  SET_REGISTRATION_DETAILS,
  GET_REGISTERED_COUNTRY_CONFIG,
  SET_REGISTERED_COUNTRY_CONFIG,
  SET_COMPANY_RELATED_DETAILS,
  SET_REGISTRATION_RELATED_DETAILS,
  GET_COMPANY_INFO,
  GET_BRE_INFO,
  SET_COMPANY_AND_ADDRESSES,
  UPDATE_ADDRESS_DETAIL,
  SET_COMPANY_PROP,
  SET_COMPANY,
  GET_REGISTERED_COUNTRY_DETAILS,
  RESET_COMPANY_INFO,
  GET_LEGAL_ID_TRANSLATIONS,
  SET_LEGAL_ID_TRANSLATIONS,
  GET_MANDATORY_DISABLED_VISITING_ADDRESS_FIELDS,
  GET_VISITING_ADDRESS,
  SET_VISITING_ADDRESS,
  GET_REGEX_VISITING_COUNTRY,
  SET_COMPANY_KEY,
  SET_BILLING_ADDRESS,
  GET_BILLING_ADDRESS,
  GET_MANDATORY_DISABLED_BILLING_ADDRESS_FIELDS,
  GET_REGEX_BILLING_COUNTRY,
  GET_DOC_UPLOAD,
  GET_DOC_DELETE,
  GET_DOC_UPLOAD_SUCCESS,
  GET_DOC_UPLOAD_STATUS_CODE,
  GET_SELECTED_FILES,
  GET_DELETED_FILES,
  SET_COMPANY_COUNTRY_MANDATORY_DISABLED_FIELDS,
} from './actionTypes';

export const getCountryRegTypeData = (request) => {
  return {
    type: GET_COUNTRY_REG_TYPE_DATA,
    payload: request,
  };
};

export const setRegistrationDetails = (response) => {
  return {
    type: SET_REGISTRATION_DETAILS,
    payload: response,
  };
};

export const setRegistrationRelatedDetails = (response) => {
  const filteredLegalIds: any = [];
  const mandatoryLegalIds: any = [];

  const { legalIds, optionalLegalIds, conditionalLegalIds } = response;

  if (legalIds.length) {
    legalIds.forEach((legalId) => {
      if (legalId.mandatory) {
        filteredLegalIds.unshift({
          legalIdCode: legalId.refDatCd,
          legalIdDsc: legalId.refDatDsc,
          legalIdRegEx: legalId.extPrm?.rgsNoRegExp,
          mandatory: legalId.mandatory,
        });
        mandatoryLegalIds.push(legalId.refDatCd);
      }
      if (optionalLegalIds.includes(legalId.refDatCd)) {
        filteredLegalIds.push({
          legalIdCode: legalId.refDatCd,
          legalIdDsc: legalId.refDatDsc,
          legalIdRegEx: legalId.extPrm?.rgsNoRegExp,
          mandatory: legalId.mandatory,
        });
      }
    });
  }

  return {
    type: SET_REGISTRATION_RELATED_DETAILS,
    payload: {
      mandatoryLegalIds,
      conditionalLegalIds,
      filteredLegalIds,
    },
  };
};

export const setCompanyCountryMandatoryDisbledRules = (response) => {
  const { mandatoryFields, companyDisabledFields } = response;
  const mandatoryVisitingAddress: any = getMappedFields(mandatoryFields, vAddressMapper);
  const mandatoryBillingAddress: any = getMappedFields(mandatoryFields, bAddressMapper);

  const vAddressDisabledFields: any = getMappedFields(companyDisabledFields, vAddressMapper).filter((field) =>
    ADDRESS_DISABLED_CATEGORIES.includes(field)
  );

  const bAddressDisabledFields: any = getMappedFields(companyDisabledFields, bAddressMapper).filter((field) =>
    ADDRESS_DISABLED_CATEGORIES.includes(field)
  );

  return {
    type: SET_COMPANY_COUNTRY_MANDATORY_DISABLED_FIELDS,
    payload: {
      mandatoryVisitingAddress,
      mandatoryBillingAddress,
      mandatoryFields,
      vAddressDisabledFields,
      bAddressDisabledFields,
    },
  };
};

export const getRegisteredCountryDetails = (registeredCountry) => {
  return {
    type: GET_REGISTERED_COUNTRY_DETAILS,
    payload: registeredCountry,
  };
};

export const getRegisteredCountryConfig = (request) => {
  return {
    type: GET_REGISTERED_COUNTRY_CONFIG,
    payload: request,
  };
};

export const setRegisteredCountryConfig = (resposne) => {
  return {
    type: SET_REGISTERED_COUNTRY_CONFIG,
    payload: resposne,
  };
};

export const setCompanyRelatedDetails = (registeredCntryConfig) => {
  let regnoCheckBox = false;
  let mergeAddr1Addr2 = false;
  const optionalLegalIds: any = [];

  if (registeredCntryConfig && registeredCntryConfig.ctryCfgs?.length > 0) {
    regnoCheckBox = registeredCntryConfig.ctryCfgs.find((attr) => attr.typeCd === 'REGNO_CHECK_BOX')?.value === 'Y';

    mergeAddr1Addr2 = registeredCntryConfig.ctryCfgs.find((attr) => attr.typeCd === 'MERGE_ADDR_2_3')?.value === 'Y';

    registeredCntryConfig.ctryCfgs.forEach((ctryCfg) => {
      if (ctryCfg.typeCd === 'LEGAL_ID_OPTIONAL') {
        optionalLegalIds.push(ctryCfg.value);
      }
    });
  }

  return {
    type: SET_COMPANY_RELATED_DETAILS,
    payload: {
      regnoCheckBox,
      mergeAddr1Addr2,
      optionalLegalIds,
    },
  };
};

export const getCompanyInfo = (legalCodes) => {
  return {
    type: GET_COMPANY_INFO,
    payload: legalCodes,
  };
};

export const getBreInfo = (legalCodes) => {
  return {
    type: GET_BRE_INFO,
    payload: legalCodes,
  };
};

export const setCompanyAndAddresses = (response) => {
  const { companyName, companyAddresses } = response;
  let vAddress;
  let bAddress;

  if (companyAddresses.length) {
    vAddress = companyAddresses?.find((address) => address.addressType === 'COM');
    bAddress = companyAddresses?.find((address) => address.addressType === 'BIL');
  }

  return {
    type: SET_COMPANY_AND_ADDRESSES,
    payload: {
      companyName,
      vAddress,
      bAddress,
    },
  };
};

export const updateAddressDetail = (addressDetail, addressType) => {
  return {
    type: UPDATE_ADDRESS_DETAIL,
    payload: {
      addressDetail,
      addressType,
    },
  };
};

export const setCompanyProp = (key, value) => {
  return {
    type: SET_COMPANY_PROP,
    payload: { key, value },
  };
};

export const setCompany = (company) => {
  const newCompany = {
    ...company,
    companyName: company.companyName?.trim(),
  };

  return {
    type: SET_COMPANY,
    payload: newCompany,
  };
};

export const resetCompanyInfo = () => {
  return {
    type: RESET_COMPANY_INFO,
  };
};

export const getLegalIdTranslations = (request) => {
  return {
    type: GET_LEGAL_ID_TRANSLATIONS,
    payload: request,
  };
};

export const setLegalIdTranslations = (response) => {
  return {
    type: SET_LEGAL_ID_TRANSLATIONS,
    payload: response,
  };
};

export const getMandatoryDisabledVisitingAddressFields = (request) => {
  return {
    type: GET_VISITING_ADDRESS,
    payload: request,
  };
};

export const setMandatoryDisabledVisitingAddressFields = (response) => {
  const { mandatoryVisitAddressFields, visitAddressDisabledFields, visitingAddressCountryCode } = response;

  if (visitingAddressCountryCode[0]?.ctryCd === 'IE') {
    mandatoryVisitAddressFields.push('acc_accAddrCntSDOs_addrSDO_zip__COM');
    mandatoryVisitAddressFields.push('acc_accAddrCntSDOs_addrSDO_zip__BIL');
  }

  const mandatoryVisitingAddressFields: any = getMappedFields(mandatoryVisitAddressFields, vAddressMapper);

  const visitingAddressDisabledFields: any = getMappedFields(visitAddressDisabledFields, vAddressMapper).filter(
    (field) => ADDRESS_DISABLED_CATEGORIES.includes(field)
  );

  return {
    type: SET_VISITING_ADDRESS,
    payload: {
      mandatoryVisitingAddressFields,
      mandatoryVisitAddressFields,
      visitingAddressDisabledFields,
    },
  };
};

export const getMandatoryDisabledBillingAddressFields = (request) => {
  return {
    type: GET_BILLING_ADDRESS,
    payload: request,
  };
};

export const setMandatoryDisabledBillingAddressFields = (response) => {
  const { mandatoryBillAddressFields, billAddressDisabledFields, billingAddressCountryCode } = response;

  if (billingAddressCountryCode[0]?.ctryCd === 'IE') {
    mandatoryBillAddressFields.push('acc_accAddrCntSDOs_addrSDO_zip__COM');
    mandatoryBillAddressFields.push('acc_accAddrCntSDOs_addrSDO_zip__BIL');
  }

  const mandatoryBillingAddressFields: any = getMappedFields(mandatoryBillAddressFields, bAddressMapper);

  const billingAddressDisabledFields: any = getMappedFields(billAddressDisabledFields, bAddressMapper).filter((field) =>
    ADDRESS_DISABLED_CATEGORIES.includes(field)
  );

  return {
    type: SET_BILLING_ADDRESS,
    payload: {
      mandatoryBillingAddressFields,
      mandatoryBillAddressFields,
      billingAddressDisabledFields,
    },
  };
};

export const getVisitingAddressMandatoryDisabledFields = (visitingCountry) => {
  return {
    type: GET_MANDATORY_DISABLED_VISITING_ADDRESS_FIELDS,
    payload: visitingCountry,
  };
};

export const getBillingAddressMandatoryDisabledFields = (billingCountry) => {
  return {
    type: GET_MANDATORY_DISABLED_BILLING_ADDRESS_FIELDS,
    payload: billingCountry,
  };
};

export const getRegexVisitingCountry = (request) => {
  return {
    type: GET_REGEX_VISITING_COUNTRY,
    payload: request,
  };
};

export const getRegexBillingCountry = (request) => {
  return {
    type: GET_REGEX_BILLING_COUNTRY,
    payload: request,
  };
};

export const setCompanyKey = (key, value) => {
  return {
    type: SET_COMPANY_KEY,
    payload: { key, value },
  };
};

export const getDocUpload = (request) => {
  return {
    type: GET_DOC_UPLOAD,
    payload: request,
  };
};

export const getDocUploadSuccess = (response) => {
  return {
    type: GET_DOC_UPLOAD_SUCCESS,
    payload: response,
  };
};

export const getDocUploadStatusCode = (statusCode) => {
  return {
    type: GET_DOC_UPLOAD_STATUS_CODE,
    payload: statusCode,
  };
};

export const getDocDelete = (response) => {
  return {
    type: GET_DOC_DELETE,
    payload: response,
  };
};

export const getSelectedFiles = (selectedFiles) => {
  return {
    type: GET_SELECTED_FILES,
    payload: selectedFiles,
  };
};

export const getDeletedFiles = (selectedFiles) => {
  return {
    type: GET_DELETED_FILES,
    payload: selectedFiles,
  };
};

export const setFileUploadConfig = (configurations) => {
  const fileUploadSize = configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DOC_SIZE')?.value || '';
  const fileUploadTypes = configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DOC_TYPES')?.value || '';
  const fileUpload = configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DOC_UPLOAD')?.value === 'Y';

  return {
    type: SET_COMPANY_KEY,
    payload: {
      key: 'fileUploadConfig',
      value: {
        fileUploadSize,
        fileUploadTypes,
        fileUpload,
      },
    },
  };
};

export const setCompanyConfig = (configurations) => {
  const hideCountryAndLegalIdSection =
    configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'HIDE_LGL_ID')?.value === 'Y';
  const hideRetrieveCompanyDetailButton =
    configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'HIDE_RETRIEVE_CPNY_DTL')?.value === 'Y';
  const daoCountry = configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DAO_COUNTRY')?.value || null;
  const companyCtryCd = configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'REGNO_CHECK_BOX')?.ctryCd;
  const showTradingField =
    configurations?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'SHOW_TRD_NM ')?.value === 'Y';

  return {
    type: SET_COMPANY_KEY,
    payload: {
      key: 'companyConfig',
      value: {
        hideCountryAndLegalIdSection,
        hideRetrieveCompanyDetailButton,
        daoCountry,
        companyCtryCd,
        showTradingField,
      },
    },
  };
};

export const setCompanyFieldRules = (configurations) => {
  let mandatoryCompanyList = [];
  if (configurations.ctryRules.rules) {
    mandatoryCompanyList = configurations.ctryRules.rules
      .filter((e) => {
        return e.mandatoryInd === 'Y';
      })
      .map((e) => {
        return e.attributeName;
      });
  }
  const companyMandatoryFields = getMappedFields(mandatoryCompanyList, companyFieldsMapper);

  return {
    type: SET_COMPANY_KEY,
    payload: { key: 'companyMandatoryFields', value: companyMandatoryFields },
  };
};
