import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

type TStyle = {
  children?: any;
};

const LtrLayout = ({ children }: TStyle) => {
  return <>{children}</>;
};

export default LtrLayout;
