import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { setThankYouPage } from '../../../store/actions/corporateAccountActions';
import { createAccount } from '../../../store/actions/daoActions';
import { ContactPanel, CompanyPanel, PaymentPanel } from '../../common';
import { OPTION_FLOW_1, pageMapper, shippingCardDocType, thankyouPageType } from '../../../utils/constants';
import ShippingCard from '../Shipping/ShippingCard/ShippingCard';
import { convertBase64toBlob } from '../../../utils/helper';
import { postUserTrackId } from '../../../store/actions/appActions';

import rateImg from '../../../assets/icons/rate_card.png';
import imgServices from '../../../assets/icons/services.png';

import './SummaryPage.css';

function SummaryPage(props) {
  const dispatch = useDispatch();

  const { cardDetails, paymentProfile } = useSelector((state: any) => state.payment);
  const { submitPostData, optionFlow } = useSelector((state: any) => state.DAOReducer);
  const { serviceDocs, shippingRatestServiceDocs } = useSelector((state: any) => state.shipping);
  const { translations } = useSelector((state: any) => state.account);
  const { userTrackId } = useSelector((state: any) => state.corporateAccount);

  const { summary: content, review: reviewContent, accessibility, payment } = translations;
  const { heading, common } = content;
  const { shipping } = translations;
  const urlDomain = process.env.REACT_APP_URL;

  useEffect(() => {
    // TODO: As discussed with team, needs a better solution for this
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (userTrackId && submitPostData?.accountId) {
      dispatch(
        postUserTrackId({
          trackingId: userTrackId,
          activityEndPage: pageMapper.summary,
          accReqId: submitPostData.accountId,
        })
      );
    }
  }, [submitPostData?.accountId]);

  const submitCreateAccount = () => {
    const { accountId } = submitPostData;
    dispatch(createAccount({ accountId }));
    props.setFlow(thankyouPageType.dao);
    dispatch(setThankYouPage(true));
  };

  const renderShippingRates = () => {
    if (optionFlow !== OPTION_FLOW_1 && serviceDocs) {
      const rateDocLists = shippingRatestServiceDocs?.docs?.filter((doc) => doc.docSubType === shippingCardDocType.rca);

      const serviceDocLists = shippingRatestServiceDocs?.docs?.filter(
        (doc) => doc.docSubType === shippingCardDocType.acc
      );

      return (
        <Row>
          <Col>
            <div className="panel-heading panel-padding">{heading.SHIPPING_TITLE}</div>
            <div className="summary-panel">
              <div className="row">
                {serviceDocs.rsa && (
                  <div className={`mt-4 ${serviceDocs.off || serviceDocs.acc ? 'col-md-6' : 'col-md-12'}`}>
                    <ShippingCard
                      borderless
                      img={rateImg}
                      alt={shipping.RATE_IMG_ALT}
                      label={shipping.RATE_LABEL}
                      langLists={rateDocLists.map((rate) => {
                        const rateBlob = convertBase64toBlob(rate?.docContent);
                        const ratePdf = URL.createObjectURL(rateBlob);

                        return {
                          link: ratePdf,
                          linkLabel:
                            shipping?.VIEW_IN_LANG !== undefined
                              ? shipping.VIEW_IN_LANG[rate.docLangDesc.toUpperCase()] !== undefined
                                ? shipping.VIEW_IN_LANG[rate.docLangDesc.toUpperCase()]
                                : rate.docLangDesc
                              : rate.docLangDesc,
                        };
                      })}
                    />
                  </div>
                )}
                {(serviceDocs.off || serviceDocs.acc) && (
                  <div className={`mt-4 ${serviceDocs.rsa ? 'col-md-6' : 'col-md-12'}`}>
                    <ShippingCard
                      borderless
                      img={imgServices}
                      alt={shipping.SERVICE_IMG_ALT}
                      label={shipping.SERVICE_LABEL}
                      langLists={serviceDocLists.map((service) => {
                        const serviceblob = convertBase64toBlob(service?.docContent);
                        const servicePdf = URL.createObjectURL(serviceblob);

                        return {
                          link: servicePdf,
                          linkLabel:
                            shipping?.VIEW_IN_LANG !== undefined
                              ? shipping.VIEW_IN_LANG[service.docLangDesc.toUpperCase()] !== undefined
                                ? shipping.VIEW_IN_LANG[service.docLangDesc.toUpperCase()]
                                : service.docLangDesc
                              : service.docLangDesc,
                        };
                      })}
                    />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      );
    }

    return null;
  };

  const renderPaymentPanel = () => {
    if (cardDetails.length !== 0) {
      return (
        <Row>
          <Col>
            <div className="panel-heading panel-padding">{heading.PAYMENT_TITLE}</div>
            <div className="summary-payment-panel">
              <PaymentPanel
                cardImg={`${urlDomain}/assets/ixopay/${
                  cardDetails[0]?.paymentData?.brand ?? paymentProfile?.preferredMethod
                }.png`}
                cardType={
                  cardDetails[0]?.paymentData?.brand === null
                    ? paymentProfile?.preferredMethod
                    : payment.brand[cardDetails[0]?.paymentData?.brand] !== undefined
                    ? payment.brand[cardDetails[0]?.paymentData?.brand]
                    : [cardDetails[0]?.paymentData?.brand]
                }
                lastFourDigits={cardDetails[0]?.paymentData?.lastFourDigits}
                paymentMethod={payment[cardDetails[0]?.method]}
              />
            </div>
          </Col>
        </Row>
      );
    }

    return null;
  };

  return (
    <>
      <Row>
        <Col className="p-0">
          <h1 className="m-0">
            <p className="content-title mb-4">{heading.TITLE}</p>
          </h1>
        </Col>
      </Row>
      <Row>
        <Col className="p-0">
          <div className="panel-frame">
            <Row>
              <Col>
                <div className="panel-heading panel-padding">{heading.CONTACT_TITLE}</div>
                <div className="panel-padding">
                  <ContactPanel
                    isEditable={false}
                    content={reviewContent}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="panel-heading panel-padding">{heading.COMPANY_TITLE}</div>
                <div className="panel-padding">
                  <CompanyPanel
                    isEditable={false}
                    content={reviewContent}
                  />
                </div>
              </Col>
            </Row>

            {renderShippingRates()}

            {renderPaymentPanel()}
          </div>
        </Col>
      </Row>

      <Row className="py-4">
        <Col className="text-end p-0 dao-mobile-next-btn">
          <button
            aria-label={accessibility?.label?.NEXT_BUTTON_ENABLED}
            className="btn btn-primary px-4 py-2"
            disabled={false}
            onClick={submitCreateAccount}
            title={common.TOOLTIP_BTN_SUBMIT}
          >
            {common.LABEL_BTN_SUBMIT}
          </button>
        </Col>
      </Row>
    </>
  );
}
export default SummaryPage;
