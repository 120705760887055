import {
  GET_MAINTENANCE_STATUS,
  SET_APP_PROP,
  SET_ETL_LIST,
  SET_ETL_STATUS,
  SET_MAINTENANCE_STATUS,
} from '../actions/actionTypes';

const initialState: any = {
  lang: 'en',
  isEtl: false,
  etlList: [],
  clientId: '',
  flow: {
    conventional: false,
  },
  appTimers: {},
  contactCompanyEtlList: [],
  blackListUrls: [],
  steps: [],
  redirectToMaintenance: -1,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ETL_STATUS:
      return { ...state, isEtl: action.payload };

    case SET_ETL_LIST:
      return { ...state, etlList: action.payload };

    case SET_APP_PROP:
      return { ...state, [action.payload.key]: action.payload.value };

    case GET_MAINTENANCE_STATUS: {
      return { ...state };
    }

    case SET_MAINTENANCE_STATUS: {
      return { ...state, redirectToMaintenance: action.payload };
    }

    default:
      return state;
  }
};

export default appReducer;
