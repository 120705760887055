import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Alert from '../Alert/Alert';

import AlertIcon from '../../../assets/icons/alert.svg';
import './Announcement.css';

function Announcement({ content, onClose }) {
  const { BTN_CLOSE_ALT, ICON_ALERT_ALT, TEXT_ANNOUNCEMENT } = content;

  return (
    <div className="announcement-box">
      <Row>
        <Col xs={{ span: 12 }}>
          <Alert
            className="announcement mb-0 ps-0 border-0 rounded-0"
            closeLabel={BTN_CLOSE_ALT}
            closeVariant="white"
            onClose={() => onClose(false)}
            dismissible
          >
            <img
              alt={ICON_ALERT_ALT}
              src={AlertIcon}
            />
            <span className="text-white ms-2">{TEXT_ANNOUNCEMENT}</span>
          </Alert>
        </Col>
      </Row>
    </div>
  );
}

export default Announcement;
