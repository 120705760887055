export const BE_QL_AXIOS = 'BE_QL_AXIOS';
export const BE_AS_AXIOS = 'BE_AS_AXIOS';
export const BE_AC_AXIOS = 'BE_AC_AXIOS';
export const AKAMAI_AXIOS = 'AKAMAI_AXIOS';

export const X_DPDHL_INSTANCE_ID = 'X-DPDHL-INSTANCE-ID';
export const X_DPDHL_Sender = 'X-DPDHL-Sender';
export const X_DPDHL_Originator = 'X-DPDHL-Originator';
export const CONTENT_TYPE = 'Content-Type';
export const CACHE_CONTROL = 'Cache-Control';
