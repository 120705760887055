import {
  GET_EN_GB_LANGUAGE_COUNTRIES,
  GET_TRANSLATIONS_AKAMAI,
  SET_ACCOUNT_PROP,
  SET_EN_GB_LANGUAGE_COUNTRIES,
  SET_LEGAL_ID_TRANSLATIONS,
  SET_TRANSLATIONS_AKAMAI,
} from '../actions/actionTypes';
import { translationsObject } from '../../utils/constants';

const initialState: any = {
  loadingTranslationsSuccess: false,
  translations: translationsObject,
  selectedLanguage: '',
  altLanguageCode: '',
  languages: [],
  enGBLanguageCountries: {},
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANSLATIONS_AKAMAI: {
      return { ...state, loadingTranslationsSuccess: true };
    }

    case SET_TRANSLATIONS_AKAMAI: {
      return { ...state, translations: action.payload, loadingTranslationsSuccess: false };
    }

    case SET_ACCOUNT_PROP:
      return { ...state, [action.payload.key]: action.payload.value };

    case SET_LEGAL_ID_TRANSLATIONS: {
      return {
        ...state,
        translations: {
          ...state.translations,
          company: {
            ...state.translations.company,
            legal: action.payload.legal,
            error: action.payload.error,
          },
        },
      };
    }

    case GET_EN_GB_LANGUAGE_COUNTRIES: {
      return { ...state };
    }

    case SET_EN_GB_LANGUAGE_COUNTRIES: {
      return { ...state, enGBLanguageCountries: action.payload };
    }

    default:
      return state;
  }
};

export default accountReducer;
