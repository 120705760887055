import React from 'react';
import Form from 'react-bootstrap/Form';
import { acceptedExt } from '../../../utils/helper';

type TFileInput = {
  inputRef: any;
  onInputFiles: any;
  types?: string[];
};

const FileInput = ({ inputRef, onInputFiles, types }: TFileInput) => {
  const handleClick = (event) => {
    const { target = {} } = event || {};
    target.value = '';
  };

  return (
    <Form.Control
      ref={inputRef}
      type="file"
      accept={acceptedExt(types)}
      onDrop={async (e) => {
        onInputFiles(e);
      }}
      onChange={async (e) => {
        onInputFiles(e);
      }}
      onClick={handleClick}
      className="d-none"
      data-testid="file-input"
    />
  );
};

export default FileInput;
