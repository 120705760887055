import { combineReducers } from 'redux';

import accountReducer from './accountReducer';
import DAOReducer from './daoReducer';
import appReducer from './appReducer';
import corporateAccountReducer from './corporateAccountReducer';
import contactReducer from './contactReducer';
import companyReducer from './companyReducer';
import paymentReducer from './paymentReducer';
import reviewReducer from './reviewReducer';
import shippingReducer from './shippingReducer';

const rootReducer = combineReducers({
  DAOReducer,
  app: appReducer,
  account: accountReducer,
  corporateAccount: corporateAccountReducer,
  contact: contactReducer,
  company: companyReducer,
  payment: paymentReducer,
  review: reviewReducer,
  shipping: shippingReducer,
});

export default rootReducer;
