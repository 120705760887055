import React from 'react';

import './Banner.css';

const Banner = ({ message }) => {
  return (
    <div
      className="banner-msg"
      dangerouslySetInnerHTML={{
        __html: message,
      }}
    ></div>
  );
};

export default Banner;
