import { takeEvery, put, call } from 'redux-saga/effects';
import { validateMyBill, postUserTrackById, validatePhone } from '../../api/apiWrapper';
import { VALIDATE_MY_BILL, SET_TRACK_USER_BY_ACTIVITY, VALIDATE_PHONE_NUMBER } from '../actions/actionTypes';
import { setValidateMyBillError, setValidatePhone, validateMyBillSuccess } from '../actions/contactActions';

export function* contactSaga() {
  yield takeEvery(VALIDATE_MY_BILL, onValidateMyBill);
  yield takeEvery(VALIDATE_PHONE_NUMBER, onValidatePhoneNumberSaga);
  yield takeEvery(SET_TRACK_USER_BY_ACTIVITY, trackUserByActivity);
}

export function* trackUserByActivity(action) {
  yield call(postUserTrackById, action.payload);
}

export function* onValidateMyBill(action) {
  try {
    const response = yield call(validateMyBill, action.payload);
    yield put(validateMyBillSuccess(response));
  } catch (error) {
    yield put(setValidateMyBillError(error));
  }
}

export function* onValidatePhoneNumberSaga(action) {
  try {
    yield put(setValidatePhone('phoneNumberValidating', true));
    const response = yield call(validatePhone, action.payload);
    yield put(setValidatePhone('validPhone', response.validPhone));
    yield put(setValidatePhone('e164NoFmt', response.e164NoFmt));
  } catch (error: any) {
    if (error?.response?.status >= 500) {
      yield put(setValidatePhone('validPhone', true));
    } else {
      yield put(setValidatePhone('validPhone', false));
    }
  } finally {
    yield put(setValidatePhone('phoneNumberValidating', false));
  }
}

export default contactSaga;
