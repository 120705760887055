import React, { useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import QuestionMarkRed from '../../../assets/icons/question_mark_red.svg';
import { BasicModal2, FileUploader, FormikControl } from '../../common';
import AdditionalInfo from './AdditionalInfo';
import {
  DEFAULT_ADDRESS,
  COMPANY_NAME_PLACEHOLDER,
  TRADING_NAME_PLACEHOLDER,
  BRV_EXTERNAL_WEBSITE_ERROR,
  COUNTRY_PLACEHOLDER,
  DEFAULT_COMPANY_INFORMATION,
  directionLayout,
  addressFields,
} from '../../../utils/constants';
import Address from './Address';
import {
  getAddressDetails,
  getAddressSuggestions,
  setAddressDetails,
  setDaoProp,
} from '../../../store/actions/daoActions';
import Banner from '../../common/Banner/Banner';
import {
  getVisitingAddressMandatoryDisabledFields,
  getBillingAddressMandatoryDisabledFields,
  setCompanyKey,
  setCompanyProp,
  getDocUpload,
  getDocDelete,
  getRegexBillingCountry,
} from '../../../store/actions/companyActions';
import ShowDocumentExampleModal from './ShowDocumentExampleModal';
import { objectsEqual, storage } from '../../../utils/helper';
import { setCorporateAccountProp } from '../../../store/actions/corporateAccountActions';

const CompanyInfo = ({ formik, addressLine1Change, onInputVAddressCountryChange, onInputBAddressCountryChange }) => {
  const { values, setFieldValue, setFieldTouched, resetForm } = formik;
  const dispatch = useDispatch();
  const { isEtl, clientId } = useSelector((state: any) => state.app);
  const { brvHttpStatus, brvError, companyInformation, addressDetail, billingAddressInfo } = useSelector(
    (state: any) => state.DAOReducer
  );
  const { isoCountriesListLocaleTranslation } = useSelector((state: any) => state.corporateAccount);
  const { contact } = useSelector((state: any) => state.contact);
  const {
    company,
    countryConfigData,
    mandatoryVisitingAddressFields,
    visitingAddressDisabledFields,
    vAddressDisabledFields,
    mandatoryVisitingAddress,
    loadingFile,
    fileUploadStatusCode,
    fileUploadConfig,
    companyConfig,
  } = useSelector((state: any) => state.company);
  const { companyCtryCd, showTradingField } = companyConfig;
  const { fileUploadSize, fileUploadTypes, fileUpload } = fileUploadConfig;
  const fileTypes = fileUploadTypes?.split(',');
  const companyDaoCountry =
    countryConfigData?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DAO_COUNTRY')?.value === 'Y';
  const [showEditFieldsAlert, setShowEditFieldsAlert] = useState(false);
  const [showVddressCountryAlert, setShowVddressCountryAlert] = useState(false);
  const [showBddressCountryAlert, setShowBddressCountryAlert] = useState(false);
  const [showDocumentExampleModal, setShowDocumentExampleModal] = useState(false);
  const [showSameBillingAddressModal, setShowSameBillingAddressModal] = useState(false);
  const [percent, setPercent] = useState(0);
  const fileUploadIntervalRef = useRef<any>();
  const [currentField, setCurrentField] = useState('');
  const [currentAddressType, setCurrentAddressType] = useState('');
  const companyCountryCode = company.registeredCountry[0]?.code;
  const { companyAddressCountry, billingAddressCountry } = company;
  const { translations } = useSelector((state: any) => state.account);
  const {
    common: { dropDown },
    company: { heading, label, tooltip, modal, accessibility },
  } = translations;
  const countryCd = values.registeredCountry[0]?.code;
  const brvExternalWebsiteError =
    brvError && Object.values(brvError).length > 0
      ? Object.values(brvError).some((err: any) => err.error === BRV_EXTERNAL_WEBSITE_ERROR)
      : false;
  const [companyNameDirty, setCompanyNameDirty] = useState({
    companyName: false,
  });

  const renderBanner = () => {
    let bannerMsg = '';
    if (!companyDaoCountry) {
      bannerMsg = heading.CONVENTIONAL_FLOW_MSG;
    }
    if (brvHttpStatus >= 500 || brvExternalWebsiteError) {
      bannerMsg = heading.BRV_DOWN_MSG;
    }
    if (values.registrationNotPresent) {
      bannerMsg = heading.NO_REG_ID;
    }
    if (bannerMsg) {
      return (
        <Row className="field-row">
          <Col>
            <Banner message={bannerMsg} />
          </Col>
        </Row>
      );
    }

    return null;
  };

  const handleADMASearch = (search, addressType, field) => {
    const addrCheckApplied = companyInformation?.addrCheckApplicable === 'Y';
    const brvVAddress = companyInformation?.companyAddresses?.find((address) => address.addressType === 'COM');
    const valueVAddress = values.vAddress;
    const query = search.substring(0, 45);
    const request = { query, countryCd };
    dispatch(getAddressSuggestions(request, addressType));
    setCurrentField(field);
    if (
      addrCheckApplied &&
      brvVAddress.addressLine2 === valueVAddress.addressLine2 &&
      brvVAddress.city === valueVAddress.city &&
      brvVAddress.zipCode === valueVAddress.zipCode &&
      brvVAddress.state === valueVAddress.state &&
      brvVAddress.province === valueVAddress.province
    ) {
      setShowEditFieldsAlert(true);
    }
  };

  const onAddressLine1Change = (addresses, addressType, field) => {
    if (addresses.length) {
      if (!addresses[0].addressId) {
        setFieldValue(addressType, { ...DEFAULT_ADDRESS, addressLine1: [addresses[0]?.addrDesc] });
        setCurrentField(field);
        dispatch(setAddressDetails(addressType, {}));
      } else {
        const request = {
          countryCd: values.registeredCountry[0].code,
          endClientId: '2345',
          providerId: addresses[0].providerId,
          addressId: addresses[0].addressId,
        };
        dispatch(getAddressDetails(request, addressType));
        addressLine1Change(true);
        setCurrentField(field);
      }
    }
  };

  const tradingCompanyNameSameChange = (e) => {
    const sameTrading = e.target.checked;
    if (sameTrading) {
      setFieldValue('tradingName', values.companyName);
    } else {
      setFieldValue('tradingName', '');
      setTimeout(() => setFieldTouched('tradingName', true));
    }
  };

  const billingAddressSameChange = (e) => {
    let bAddress;
    const sameBilling = e.target.checked;
    const vAddressApi = companyInformation?.companyAddresses?.find((address) => address.addressType === 'COM');
    const bAddressApi = billingAddressInfo?.companyAddresses?.find((address) => address.addressType === 'BIL');

    if (sameBilling) {
      if (
        bAddressApi &&
        !objectsEqual(vAddressApi, values['bAddress']) &&
        objectsEqual(bAddressApi, values['bAddress'])
      ) {
        setShowSameBillingAddressModal(true);
      } else {
        setFieldValue('bAddress', values['vAddress']);
      }
      setTimeout(() => setFieldTouched('bAddress', false));
      setFieldValue('billingAddressCountry', values.companyAddressCountry);
      dispatch(setCorporateAccountProp('billingBreRegex', {}));
      dispatch(setCorporateAccountProp('billingDaoRegex', {}));
    } else {
      bAddress = { ...bAddressApi };
      if (bAddress?.addressLine1) {
        bAddress.addressLine1 = [bAddress.addressLine1];
      }
      if (bAddressApi) {
        setFieldValue('bAddress', bAddress);
        addressFields.forEach((field) => {
          setTimeout(() => setFieldTouched(`bAddress.${field}`, true));
        });
      } else {
        setFieldValue('bAddress', DEFAULT_ADDRESS);
      }
      setTimeout(() => setFieldTouched('companyName', true));
      setFieldValue('billingAddressCountry', values.companyAddressCountry);
      dispatch(setCompanyKey('billingAddressDisabledFields', visitingAddressDisabledFields));
      dispatch(setCompanyKey('mandatoryBillingAddressFields', mandatoryVisitingAddressFields));
      dispatch(setCompanyKey('bAddressDisabledFields', vAddressDisabledFields));
      dispatch(setCompanyKey('mandatoryBillingAddress', mandatoryVisitingAddress));
      dispatch(getRegexBillingCountry({ ctryCd: values.companyAddressCountry[0].code, srcApp: clientId }));
    }
  };

  const handleEditFields = (value) => {
    setShowEditFieldsAlert(false);
    let vAddress;
    let bAddress;
    let visitingAddressOverride = false;
    let billingAddressOverride = false;
    if (value) {
      // vAddress fields change
      if (currentAddressType === 'vAddress') {
        const visitingAddress = companyInformation?.companyAddresses?.find((address) => address.addressType === 'COM');
        if (visitingAddress) {
          visitingAddressOverride = !objectsEqual(visitingAddress, values['vAddress']);
        }
        if (visitingAddressOverride) {
          setFieldValue('vAddress', values['vAddress']);
        }
      }

      // bAddress fields change
      if (currentAddressType === 'bAddress') {
        const billingAddress =
          billingAddressInfo?.companyAddresses?.find((address) => address.addressType === 'BIL') || null;
        if (billingAddress) {
          billingAddressOverride = !objectsEqual(billingAddress, values['bAddress']);
        }
        if (billingAddressOverride) {
          setFieldValue('bAddress', values['bAddress']);
        }
      }

      // companyName fields change
      const currentCompanyNameDirtyFields = { ...companyNameDirty };
      currentCompanyNameDirtyFields[currentField] = true;
      setCompanyNameDirty(currentCompanyNameDirtyFields);
    } else {
      setFieldValue('bAddress', DEFAULT_ADDRESS);
      const compNameCheckApplied = companyInformation?.compNameCheckApplicable === 'Y';
      if (compNameCheckApplied && !companyNameDirty.companyName) {
        setFieldValue('companyName', companyInformation?.companyName);
      }
      if (companyInformation?.companyAddresses.length) {
        const vAddressApi = companyInformation?.companyAddresses?.find((address) => address.addressType === 'COM');
        vAddress = { ...vAddressApi };

        if (vAddress?.addressLine1) {
          vAddress.addressLine1 = [vAddress.addressLine1];
        }
        const currentVAddress = values.vAddress;
        currentVAddress[currentField] = vAddress[currentField];
        setFieldValue('vAddress', currentVAddress);
      }

      if (billingAddressInfo?.companyAddresses.length) {
        const bAddressApi =
          billingAddressInfo?.companyAddresses?.find((address) => address.addressType === 'BIL') || null;
        bAddress = { ...bAddressApi };
        if (bAddress?.addressLine1) {
          bAddress.addressLine1 = [bAddress.addressLine1];
        }

        const currentBAddress = values.bAddress;
        currentBAddress[currentField] = bAddress[currentField];
        setFieldValue('bAddress', currentBAddress);
      }

      // companyName field not change
      const currentCompanyNameDirtyFields = { ...companyNameDirty };
      currentCompanyNameDirtyFields[currentField] = false;
      setCompanyNameDirty(currentCompanyNameDirtyFields);
    }
  };

  // handle vAddress change
  const handleFieldChange = (field, addressType) => {
    const addrCheckApplied = companyInformation?.addrCheckApplicable === 'Y';
    const admaFieldValue = addressDetail && addressDetail[addressType] && addressDetail[addressType][field];
    setCurrentField(field);
    setCurrentAddressType(addressType);

    const brvAddressType = addressType === 'vAddress' ? 'COM' : 'BIL';
    const brvAddress = companyInformation?.companyAddresses?.find((address) => address.addressType === brvAddressType);
    if (addrCheckApplied && admaFieldValue !== brvAddress[field] && !isEtl) {
      setShowEditFieldsAlert(true);
    }
  };

  // handle input change
  const handleInputChange = (text, field, addressType) => {
    const addrCheckApplied = companyInformation?.addrCheckApplicable === 'Y';

    const brvAddressType = addressType === 'vAddress' ? 'COM' : 'BIL';
    const brvAddress = companyInformation?.companyAddresses?.find((address) => address.addressType === brvAddressType);
    setCurrentField(field);
    setCurrentAddressType(addressType);

    if (addrCheckApplied && text !== brvAddress[field] && !isEtl) {
      setShowEditFieldsAlert(true);
    }
  };

  // handle bAddress change
  const handleBAddressFieldChange = (field, addressType) => {
    const addrCheckApplied = billingAddressInfo?.addrCheckApplicable === 'Y';
    const addrCheckAppliedVAddress = companyInformation?.addrCheckApplicable === 'Y';
    const admaFieldValue = addressDetail && addressDetail[addressType] && addressDetail[addressType][field];
    setCurrentField(field);
    setCurrentAddressType(addressType);

    const brvBAddressType = addressType === 'bAddress' ? 'BIL' : 'COM';
    const brvBAddress =
      billingAddressInfo?.companyAddresses?.find((address) => address.addressType === brvBAddressType) || [];
    if ((addrCheckApplied && admaFieldValue !== brvBAddress[field] && !isEtl) || (addrCheckAppliedVAddress && !isEtl)) {
      setShowEditFieldsAlert(true);
    }
  };

  // handle bAddress input change
  const handleBAddressInputChange = (text, field, addressType) => {
    const addrCheckApplied = billingAddressInfo?.addrCheckApplicable === 'Y';
    const addrCheckAppliedVAddress = companyInformation?.addrCheckApplicable === 'Y';

    const brvBAddressType = addressType === 'bAddress' ? 'BIL' : 'COM';
    const brvBAddress =
      billingAddressInfo?.companyAddresses?.find((address) => address.addressType === brvBAddressType) || null;
    setCurrentField(field);
    setCurrentAddressType(addressType);

    if ((addrCheckApplied && text !== brvBAddress[field] && !isEtl) || (addrCheckAppliedVAddress && !isEtl)) {
      setShowEditFieldsAlert(true);
    }
  };

  // handle companyName change
  const handleFieldCompanyChange = (field) => {
    setCurrentField(field);

    const compNameCheckApplied = companyInformation?.compNameCheckApplicable === 'Y';
    if (compNameCheckApplied && !companyNameDirty[field]) {
      setShowEditFieldsAlert(true);
    }
  };

  const modalBtnHandler = (name) => {
    switch (name) {
      case modal.EDIT_BUTTON:
        handleEditFields(true);
        break;
      case modal.RETAIN_BUTTON:
        handleEditFields(false);
        break;
    }
  };

  const handleVAddressCountryAlert = (value) => {
    setShowVddressCountryAlert(false);
    if (value) {
      let updatedBAddress = DEFAULT_ADDRESS;
      if (!values.billingAddressSame) {
        updatedBAddress = values.bAddress;
      }
      resetForm({ values: { ...values, vAddress: DEFAULT_ADDRESS, bAddress: updatedBAddress } });
      dispatch(setCompanyProp('vAddress', DEFAULT_ADDRESS));
      dispatch(setCompanyProp('bAddress', updatedBAddress));
      dispatch(setDaoProp('companyInformation', DEFAULT_COMPANY_INFORMATION));
      setFieldValue('companyAddressCountry', values.companyAddressCountry);
      dispatch(setCompanyProp('companyAddressCountry', values.companyAddressCountry));
      dispatch(getVisitingAddressMandatoryDisabledFields(values.companyAddressCountry));
      setTimeout(() => setFieldTouched('companyAddressCountry', true));
    } else {
      setFieldValue('companyAddressCountry', companyAddressCountry);
    }
  };

  const modalBtnVAddressCountryHandler = (name) => {
    switch (name) {
      case modal.CHANGE_COMPANY_ADDRESS_COUNTRY:
        handleVAddressCountryAlert(true);
        break;
      case modal.RETAIN_COMPANY_ADDRESS_COUNTRY:
        handleVAddressCountryAlert(false);
        break;
    }
  };

  const handleVAddressCountryChange = (changedCountry) => {
    const vAddressCountry = changedCountry;
    const companyAddressCountryCode = changedCountry[0]?.code;
    if (companyAddressCountryCode) {
      setShowVddressCountryAlert(true);
    }
    if (values.billingAddressSame) {
      setFieldValue('billingAddressCountry', vAddressCountry);
    }
  };

  const handleBAddressCountryAlert = (value) => {
    setShowBddressCountryAlert(false);
    if (value) {
      resetForm({ values: { ...values, bAddress: DEFAULT_ADDRESS } });
      dispatch(setCompanyProp('bAddress', DEFAULT_ADDRESS));
      dispatch(setDaoProp('billingAddressInfo', DEFAULT_COMPANY_INFORMATION));
      setFieldValue('billingAddressCountry', values.billingAddressCountry);
      dispatch(setCompanyProp('billingAddressCountry', values.billingAddressCountry));
      dispatch(getBillingAddressMandatoryDisabledFields(values.billingAddressCountry));
      setTimeout(() => setFieldTouched('billingAddressCountry', true));
    } else {
      setFieldValue('billingAddressCountry', billingAddressCountry);
    }
  };

  const handleSameBillingAddressAlert = (value) => {
    setShowSameBillingAddressModal(false);
    if (value) {
      setFieldValue('billingAddressSame', true);
      setFieldValue('bAddress', values['vAddress']);
    } else {
      setFieldValue('billingAddressSame', false);
    }
  };

  const modalBtnBAddressCountryHandler = (name) => {
    switch (name) {
      case modal.CHANGE_BILLING_ADDRESS_COUNTRY:
        handleBAddressCountryAlert(true);
        break;
      case modal.RETAIN_BILLING_ADDRESS_COUNTRY:
        handleBAddressCountryAlert(false);
        break;
    }
  };

  const handleBAddressCountryChange = (changedCountry) => {
    const bAddressCountryCode = changedCountry[0]?.code;
    if (bAddressCountryCode) {
      setShowBddressCountryAlert(true);
    }
  };

  const handleChange = (files) => {
    storage.set('percentage', 0);
    const filesData = new FormData();
    for (let i = 0; i < files.length; i++) {
      filesData.append('files', files[i]);
    }
    dispatch(getDocUpload(filesData));
    fileUploadIntervalRef.current = setInterval(() => {
      setPercent(Number(storage.get('percentage')));
    }, 200);

    return () => clearInterval(fileUploadIntervalRef.current);
  };

  const handleDelete = (file) => {
    const deleteData = {
      fileName: file.name,
      docId: file.docId,
    };
    dispatch(getDocDelete(deleteData));
  };

  const handleDocumentExampleModal = () => {
    setShowDocumentExampleModal(true);
  };

  const handleCloseDocumenentExample = () => {
    setShowDocumentExampleModal(false);
  };

  const modalBtnSameBAddressHandler = (name) => {
    switch (name) {
      case modal.EDIT_BUTTON:
        handleSameBillingAddressAlert(true);
        break;
      case modal.RETAIN_BUTTON:
        handleSameBillingAddressAlert(false);
        break;
    }
  };

  return (
    <Row
      className={`shadow rounded-bottom bg-white form-content ${companyCountryCode !== 'US' ? 'company-details' : ''}`}
    >
      <Col>
        {renderBanner()}
        <Row className="field-row">
          <Col>
            <p className="information-title">{heading.COMPANY_DETAILS}</p>
          </Col>
        </Row>
        <Row className="field-row">
          <Col>
            <FormikControl
              control="input"
              name="companyName"
              ariaLabel={accessibility.label.COMPANY_NAME}
              type="text"
              label={label.COMPANY_NAME}
              mandatoryLabel="*"
              placeholder={COMPANY_NAME_PLACEHOLDER}
              onChange={(e) => handleFieldCompanyChange('companyName')}
              errorMsgClassName={
                companyCtryCd !== contact.country[0].code && document.documentElement.dir === directionLayout.rtl
                  ? 'direction-ltr'
                  : ''
              }
              {...(tooltip?.COMPANY_NAME && { fieldInfo: { info: tooltip.COMPANY_NAME } })}
            />
          </Col>
        </Row>
        {showTradingField && (
          <>
            <div className="row mt-4">
              <div className="d-flex">
                <FormikControl
                  control="checkbox"
                  name="tradingCompanyNameSame"
                  ariaLabel={accessibility.label.USE_COMPANY_NAME_AS_TRADING_NAME}
                  label={label.TRADING_NAME_CHECKBOX_MSG}
                  onChange={tradingCompanyNameSameChange}
                />
              </div>
            </div>
            {!values.tradingCompanyNameSame ? (
              <>
                <Row className="field-row">
                  <Col>
                    <FormikControl
                      control="input"
                      name="tradingName"
                      ariaLabel={accessibility.label.TRADING_NAME}
                      type="text"
                      label={label.TRADING_NAME}
                      mandatoryLabel="*"
                      placeholder={TRADING_NAME_PLACEHOLDER}
                      errorMsgClassName={
                        companyCtryCd !== contact.country[0].code &&
                        document.documentElement.dir === directionLayout.rtl
                          ? 'direction-ltr'
                          : ''
                      }
                      {...(tooltip?.TRADING_NAME && { fieldInfo: { info: tooltip.TRADING_NAME } })}
                    />
                  </Col>
                </Row>
              </>
            ) : null}
          </>
        )}
        <Address
          addressType="vAddress"
          onSearch={(search) => handleADMASearch(search, 'vAddress', 'addressLine1')}
          onChange={(addresses) => onAddressLine1Change(addresses, 'vAddress', 'addressLine1')}
          onFieldChange={(field) => handleFieldChange(field, 'vAddress')}
          onInputChange={(text) => handleInputChange(text, 'addressLine1', 'vAddress')}
        />
        <Row className="field-row">
          <Col>
            <FormikControl
              control="typeahead"
              name="companyAddressCountry"
              ariaLabel={accessibility.label.COMPANY_ADDRESS_COUNTRY}
              className="flag-label"
              label={label.COMPANY_ADDRESS_COUNTRY}
              mandatoryLabel="*"
              emptyLabel={dropDown.EMPTY_LABEL}
              options={isoCountriesListLocaleTranslation}
              placeholder={COUNTRY_PLACEHOLDER}
              onChange={handleVAddressCountryChange}
              onInputChange={onInputVAddressCountryChange}
              filterBy={(option, props) => {
                if (props.selected.length) {
                  return true;
                }

                return ['name', 'code'].some(
                  (key) => option[key].toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                );
              }}
              menuItemChildren={(option: any) => {
                return (
                  <>
                    <div className="typeahead-menu-item">
                      <span className={`fi fi-${option.code.toLowerCase()} typeahead-menu-flag`}></span>
                      <span>{option.name}</span>
                    </div>
                  </>
                );
              }}
              {...(tooltip?.COMPANY_ADDRESS_COUNTRY && { fieldInfo: { info: tooltip.COMPANY_ADDRESS_COUNTRY } })}
            />
          </Col>
        </Row>
        <div className="row mt-4">
          <div className="d-flex">
            <FormikControl
              control="checkbox"
              name="billingAddressSame"
              ariaLabel={accessibility.label.USE_COMPANY_ADDR_AS_BILLING_ADDR}
              label={label.BILLING_ADDRESS_CHECKBOX_MSG}
              onChange={billingAddressSameChange}
            />
          </div>
        </div>
        {!values.billingAddressSame ? (
          <>
            <Address
              addressType="bAddress"
              onSearch={(search) => handleADMASearch(search, 'bAddress', 'addressLine1')}
              onChange={(addresses) => onAddressLine1Change(addresses, 'bAddress', 'addressLine1')}
              onFieldChange={(field) => handleBAddressFieldChange(field, 'bAddress')}
              onInputChange={(text) => handleBAddressInputChange(text, 'addressLine1', 'bAddress')}
            />
            <Row className="field-row">
              <Col>
                <FormikControl
                  control="typeahead"
                  name="billingAddressCountry"
                  ariaLabel={accessibility.label.BILLING_ADDRESS_COUNTRY}
                  className="flag-label"
                  label={label.BILLING_ADDRESS_COUNTRY}
                  mandatoryLabel="*"
                  emptyLabel={dropDown.EMPTY_LABEL}
                  options={isoCountriesListLocaleTranslation}
                  placeholder={COUNTRY_PLACEHOLDER}
                  onChange={handleBAddressCountryChange}
                  onInputChange={onInputBAddressCountryChange}
                  filterBy={(option, props) => {
                    if (props.selected.length) {
                      return true;
                    }

                    return ['name', 'code'].some(
                      (key) => option[key].toLowerCase().indexOf(props.text.toLowerCase()) !== -1
                    );
                  }}
                  menuItemChildren={(option: any) => {
                    return (
                      <>
                        <div className="typeahead-menu-item">
                          <span className={`fi fi-${option.code.toLowerCase()} typeahead-menu-flag`}></span>
                          <span>{option.name}</span>
                        </div>
                      </>
                    );
                  }}
                  {...(tooltip?.BILLING_ADDRESS_COUNTRY && { fieldInfo: { info: tooltip.BILLING_ADDRESS_COUNTRY } })}
                />
              </Col>
            </Row>
          </>
        ) : null}
        {fileUpload && !isEtl && (
          <>
            <div className="row mt-4">
              <div className="col-md-7 order-1">
                <p className="fw-bold">{heading.UPLOAD_DOCUMENT_TITLE}</p>
              </div>
              <div className="col-md-5 order-3 order-md-2 d-md-flex justify-content-md-end">
                <p
                  role="button"
                  title={heading.SHOW_DOCS_EXAMPLES}
                  onClick={handleDocumentExampleModal}
                  className="show-doc-example"
                >
                  {heading.SHOW_DOCS_EXAMPLES}
                  <img
                    className="ps-2"
                    src={QuestionMarkRed}
                    alt={heading.SHOW_DOCS_EXAMPLES}
                  />
                </p>
              </div>
              <div className="col-md-12 order-2 order-md-3">
                <p
                  dangerouslySetInnerHTML={{
                    __html: heading.UPLOAD_DOCUMENT_MSG,
                  }}
                ></p>
                {heading.UPLOAD_DOCUMENT_MSG_2 && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: heading.UPLOAD_DOCUMENT_MSG_2,
                    }}
                  ></p>
                )}
              </div>
            </div>
            {Object.values(modal.showDocuments.sampleList).map((document: any, index: number) => {
              return (
                <FileUploader
                  onUpload={handleChange}
                  onDelete={handleDelete}
                  types={fileTypes}
                  maxSize={fileUploadSize}
                  openDialogOnClick
                  loading={loadingFile}
                  uploadError={fileUploadStatusCode}
                  percentage={percent}
                  document={document}
                  key={index}
                />
              );
            })}
            <ShowDocumentExampleModal
              show={showDocumentExampleModal}
              handleClose={handleCloseDocumenentExample}
            />
          </>
        )}
        <AdditionalInfo />
      </Col>
      <BasicModal2
        show={showEditFieldsAlert}
        title={modal.EDIT_TITLE}
        content={modal.EDIT_BODY}
        actions={[
          { type: 'link', name: modal.EDIT_BUTTON },
          { type: 'secondary', name: modal.RETAIN_BUTTON },
        ]}
        actionHandler={modalBtnHandler}
      />
      <BasicModal2
        show={showVddressCountryAlert}
        title={modal.TITLE_COMPANY_ADDRESS_COUNTRY}
        content={modal.BODY_COMPANY_ADDRESS_COUNTRY}
        actions={[
          { type: 'link', name: modal.CHANGE_COMPANY_ADDRESS_COUNTRY },
          { type: 'secondary', name: modal.RETAIN_COMPANY_ADDRESS_COUNTRY },
        ]}
        actionHandler={modalBtnVAddressCountryHandler}
      />
      <BasicModal2
        show={showBddressCountryAlert}
        title={modal.TITLE_BILLING_ADDRESS_COUNTRY}
        content={modal.BODY_BILLING_ADDRESS_COUNTRY}
        actions={[
          { type: 'link', name: modal.CHANGE_BILLING_ADDRESS_COUNTRY },
          { type: 'secondary', name: modal.RETAIN_BILLING_ADDRESS_COUNTRY },
        ]}
        actionHandler={modalBtnBAddressCountryHandler}
      />
      <BasicModal2
        show={showSameBillingAddressModal}
        title={modal.EDIT_TITLE}
        content={modal.EDIT_BODY}
        actions={[
          { type: 'link', name: modal.EDIT_BUTTON },
          { type: 'secondary', name: modal.RETAIN_BUTTON },
        ]}
        actionHandler={modalBtnSameBAddressHandler}
      />
    </Row>
  );
};

export default CompanyInfo;
