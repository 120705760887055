export const zipCodeRegex = /^[a-zA-Z0-9-\s]*$/;
export const zipCodeForIeCountryRegex = /^$|^([a-zA-Z0-9]{3})( )([a-zA-Z0-9]{4})/;
export const emailRegex = /^[\w.%\-&!#$*']+@[-.\w]+\.[A-Za-z]{1,25}\b/;
export const numberRegex = /^[0-9]*$/;
export const addressRegex = /^[ A-Za-z0-9@./,#%'&+-]*$/;
export const allowedCharRegex = /^(?=.*\D)(?=.*[a-zA-Z]).{1,}/;
// eslint-disable-next-line no-control-regex
export const notEnglishLatinRegex = /[^\u0000-\u017e]+/;
export const notAllowWhiteSpaceOnFirstRegex = /^(?=[^\s])(?=.*[a-zA-Z0-9]).{1,}/;
// eslint-disable-next-line no-control-regex
export const notEnglishRegex = /[^\u0000-\u007F]+/;
export const websitePattern1 = /^(?:http(s)?:\/\/)?([wW]){4,}\./;
export const websitePattern2 = /^(?:http(s)?:\/\/)?([wW]){1,2}\./;
export const websiteUSPattern1 = /^((N\/A|n\/a|N\/a|n\/A)$)/;
export const websitePattern3 = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
export const websiteUSPattern2 =
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$|((N\/A|n\/a|N\/a|n\/A)$)/;
export const fileNamePattern = /[^\s]+(.*?).(jpg|jpeg|png|JPG|JPEG|PNG|pdf|docx|xlsx|PDF)$/;
