import React from 'react';
import FormikTextField from '../TextField/FormikTextField';
import FormikSelect from '../Select/FormikSelect';
import FormikCheckbox from '../Checkbox/FormikCheckbox';
import FormikTypeahead from '../Typeahead/FormikTypeahead';
import FormikAsyncTypeahead from '../Typeahead/FormikAsyncTypeahead';
import FormikArrayTextField from '../TextField/FormikArrayTextField';

const FormikControl = (props) => {
  const { control, ...rest } = props;

  switch (control) {
    case 'input':
      return <FormikTextField {...rest} />;
    case 'inputarray':
      return <FormikArrayTextField {...rest} />;
    case 'select':
      return <FormikSelect {...rest} />;
    case 'checkbox':
      return <FormikCheckbox {...rest} />;
    case 'typeahead':
      return <FormikTypeahead {...rest} />;
    case 'asynctypeahead':
      return <FormikAsyncTypeahead {...rest} />;
    default:
      return null;
  }
};

export default FormikControl;
