import React from 'react';

import './Checkbox.css';

type TCheckbox = {
  id: string;
  inputClass?: any;
  labelClass?: any;
  checked?: boolean;
  onChange?: (e) => any;
  label: string;
  mandatoryLabel?: string;
  ariaLabel?: string;
};

const Checkbox = ({ id, inputClass, labelClass, checked, onChange, label, mandatoryLabel }: TCheckbox) => {
  return (
    <div className="form-check">
      <input
        className={inputClass}
        type="checkbox"
        id={id}
        checked={checked}
        onChange={onChange}
      />
      <label
        className={labelClass}
        htmlFor={id}
      >
        <span className="legal-id-mandatory">{mandatoryLabel}</span>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
