import React from 'react';

import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import './style-rtl.css';

type TStyle = {
  children?: any;
};

const RtlLayout = ({ children }: TStyle) => {
  return <>{children}</>;
};

export default RtlLayout;
