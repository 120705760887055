import {
  GET_PAYMENT_PROFILE,
  SET_PAYMENT_PROFILE,
  GET_PAYMENT_STATUS,
  SET_PAYMENT_STATUS,
  SET_PAYMENT_ERROR,
  GET_DEBIT_SUCCESS,
  SET_DEBIT_STATUS,
  SET_DEBIT_ERROR,
  SET_CARD_DETAILS,
  SET_IXOPAY_RIDIRECT_URL,
  SET_RETRY_PAYMENT,
  SET_PAYMENT_ETL_LIST,
  SET_PAYMENT_PROP,
  SET_PAYMENT_PROFILE_ERROR,
  SET_PAYMENT_LOADOUT,
  GET_PAYMENT_METHOD_TYPE,
} from './actionTypes';

export const getPaymentProfile = (request) => {
  return {
    type: GET_PAYMENT_PROFILE,
    payload: request,
  };
};

export const setPaymentProfile = (response) => {
  return {
    type: SET_PAYMENT_PROFILE,
    payload: response,
  };
};

export const getPaymentStatus = (request) => {
  return {
    type: GET_PAYMENT_STATUS,
    payload: request,
  };
};

export const setPaymentStatus = (response) => {
  return {
    type: SET_PAYMENT_STATUS,
    payload: response,
  };
};

export const setPaymentError = (paymentError) => {
  return {
    type: SET_PAYMENT_ERROR,
    payload: paymentError,
  };
};

export const getDebit = (request) => {
  return {
    type: GET_DEBIT_SUCCESS,
    payload: request,
  };
};

export const setDebitDetails = (response) => {
  return {
    type: SET_DEBIT_STATUS,
    payload: response,
  };
};

export const setIxopayRidirect = (response) => {
  return {
    type: SET_IXOPAY_RIDIRECT_URL,
    payload: response,
  };
};

export const setDebitError = (error) => {
  return {
    type: SET_DEBIT_ERROR,
    payload: error,
  };
};

export const setCardDetails = (card) => {
  let cardDetails: any = [];
  const filtered = card?.paymentInstruments?.filter((user) => user.isPreferred);
  if (card.success) {
    cardDetails = filtered || [];
  }

  return {
    type: SET_CARD_DETAILS,
    payload: cardDetails,
  };
};

export const setRetryPayment = (paymentRetry) => {
  return {
    type: SET_RETRY_PAYMENT,
    payload: paymentRetry,
  };
};

export const setPaymentProfileError = (error) => {
  const paymentProfileCode = error?.response?.status;

  return {
    type: SET_PAYMENT_PROFILE_ERROR,
    payload: paymentProfileCode,
  };
};

export const setPaymentEtlList = () => {
  return {
    type: SET_PAYMENT_ETL_LIST,
  };
};

export const setPaymentProp = (key, value) => {
  return {
    type: SET_PAYMENT_PROP,
    payload: { key, value },
  };
};

export const setPaymentLoadout = (response) => {
  return {
    type: SET_PAYMENT_LOADOUT,
    payload: response,
  };
};

export const getPaymentMethodType = (request) => {
  return {
    type: GET_PAYMENT_METHOD_TYPE,
    payload: request,
  };
};
