import React from 'react';
import { replace } from 'lodash';

import DhlFooterLogo from '../../../assets/images/dhl-express-logo.png';
import Youtube from '../../../assets/icons/youtube.svg';
import Facebook from '../../../assets/icons/facebook.svg';
import Linkedin from '../../../assets/icons/linkedin.svg';
import Instagram from '../../../assets/icons/instagram.svg';
import './Footer.css';

function Footer({ content, footerRef }) {
  const {
    IMG_LOGO_ALT,
    LINK_FRAUD_AWARENESS,
    TEXT_FRAUD_AWARENESS,
    LINK_LEGAL_NOTICE,
    TEXT_LEGAL_NOTICE,
    LINK_TERMS_OF_USE,
    TEXT_TERMS_OF_USE,
    LINK_PRIVACY_NOTICE,
    TEXT_PRIVACY_NOTICE,
    LINK_DISPUTE_RESOLUTION,
    TEXT_DISPUTE_RESOLUTION,
    LINK_ACCESSIBILITY,
    TEXT_ACCESSIBILITY,
    LINK_ADDITIONAL_INFORMATION,
    TEXT_ADDITIONAL_INFORMATION,
    TEXT_FOLLOW_US,
    LINK_YOUTUBE,
    IMG_YOUTUBE_ALT,
    LINK_FACEBOOK,
    IMG_FACEBOOK_ALT,
    LINK_LINKEDIN,
    IMG_LINKEDIN_ALT,
    LINK_INSTAGRAM,
    IMG_INSTAGRAM_ALT,
    TEXT_COPYRIGHT,
  } = content;

  const TEXT_COPYRIGHT_REPLACE = replace(TEXT_COPYRIGHT, '{{copyright_symbol}}', '©');

  return (
    <footer
      className="footer-box shadow outline-0"
      ref={footerRef}
      tabIndex={-1}
    >
      <div className="py-3 footer-links d-flex">
        <div className="footer-width">
          <div className="py-3">
            <img
              alt={IMG_LOGO_ALT}
              src={DhlFooterLogo}
            />
          </div>
          <div className="d-flex flex-wrap">
            <div className={TEXT_FRAUD_AWARENESS !== '' ? 'footer-col-6' : ''}>
              {TEXT_FRAUD_AWARENESS && (
                <a
                  className="footer-row-text"
                  target="_blank"
                  href={LINK_FRAUD_AWARENESS}
                  rel="noreferrer"
                >
                  {TEXT_FRAUD_AWARENESS}
                </a>
              )}
            </div>
            <div className={TEXT_LEGAL_NOTICE !== '' ? 'footer-col-6' : ''}>
              {TEXT_LEGAL_NOTICE && (
                <a
                  className="footer-row-text"
                  target="_blank"
                  href={LINK_LEGAL_NOTICE}
                  rel="noreferrer"
                >
                  {TEXT_LEGAL_NOTICE}
                </a>
              )}
            </div>
            <div className={TEXT_TERMS_OF_USE !== '' ? 'footer-col-6' : ''}>
              {TEXT_TERMS_OF_USE && (
                <a
                  className="footer-row-text"
                  target="_blank"
                  href={LINK_TERMS_OF_USE}
                  rel="noreferrer"
                >
                  {TEXT_TERMS_OF_USE}
                </a>
              )}
            </div>
            <div className={TEXT_PRIVACY_NOTICE !== '' ? 'footer-col-6' : ''}>
              {TEXT_PRIVACY_NOTICE && (
                <a
                  className="footer-row-text"
                  target="_blank"
                  href={LINK_PRIVACY_NOTICE}
                  rel="noreferrer"
                >
                  {TEXT_PRIVACY_NOTICE}
                </a>
              )}
            </div>
            <div className={TEXT_DISPUTE_RESOLUTION !== '' ? 'footer-col-6' : ''}>
              {TEXT_DISPUTE_RESOLUTION && (
                <a
                  className="footer-row-text"
                  target="_blank"
                  href={LINK_DISPUTE_RESOLUTION}
                  rel="noreferrer"
                >
                  {TEXT_DISPUTE_RESOLUTION}
                </a>
              )}
            </div>
            <div className={TEXT_ACCESSIBILITY !== '' ? 'footer-col-6' : ''}>
              {TEXT_ACCESSIBILITY && (
                <a
                  className="footer-row-text"
                  target="_blank"
                  href={LINK_ACCESSIBILITY}
                  rel="noreferrer"
                >
                  {TEXT_ACCESSIBILITY}
                </a>
              )}
            </div>
            <div className={TEXT_ADDITIONAL_INFORMATION !== '' ? 'footer-col-6' : ''}>
              {TEXT_ADDITIONAL_INFORMATION && (
                <a
                  className="footer-row-text"
                  target="_blank"
                  href={LINK_ADDITIONAL_INFORMATION}
                  rel="noreferrer"
                >
                  {TEXT_ADDITIONAL_INFORMATION}
                </a>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="follow-us">{TEXT_FOLLOW_US}</div>
          <div className="media-wrap">
            <a
              target="_blank"
              href={LINK_YOUTUBE}
              rel="noreferrer"
            >
              <img
                className="social-media-img"
                alt={IMG_YOUTUBE_ALT}
                src={Youtube}
              />
            </a>
            <a
              target="_blank"
              href={LINK_FACEBOOK}
              rel="noreferrer"
            >
              <img
                className="social-media-img"
                alt={IMG_FACEBOOK_ALT}
                src={Facebook}
              />
            </a>
            <a
              target="_blank"
              href={LINK_LINKEDIN}
              rel="noreferrer"
            >
              <img
                className="social-media-img"
                alt={IMG_LINKEDIN_ALT}
                src={Linkedin}
              />
            </a>
            <a
              target="_blank"
              href={LINK_INSTAGRAM}
              rel="noreferrer"
            >
              <img
                className="social-media-img me-0"
                alt={IMG_INSTAGRAM_ALT}
                src={Instagram}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="row py-3">
        <div className="col-md-12">
          <p className="footer-row-text m-0">{TEXT_COPYRIGHT_REPLACE}</p>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
