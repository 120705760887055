import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams, Link } from 'react-router-dom';

import BrandLogo from '../../../assets/images/dhl-logo.png';
import QuestionMarkBlack from '../../../assets/icons/question_mark_black.svg';
import QuestionMarkRed from '../../../assets/icons/question_mark_red.svg';
import './Header.css';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

function Header({ content, languages, languageHandler, className, hideLanguageBar }) {
  const { IMG_LOGO_ALT, TEXT_LOGO, TEXT_FAQ, EN, accessibility } = content;
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const countryCode = searchParams.get('country')?.toUpperCase();
  const clientId = searchParams.get('clientid');
  const { selectedLanguage } = useSelector((state: any) => state.account);
  const [faqHover, setFaqHover] = useState(false);
  const isMobileDevice = useMediaQuery('only screen and (min-width : 320px) and (max-width : 767px)');

  return (
    <Navbar
      expand="md"
      className="header-navbar"
    >
      <div className="header-container">
        <div className="header-content">
          <Navbar.Brand href={'/' + location.search}>
            <img
              alt={IMG_LOGO_ALT}
              src={BrandLogo}
              className="d-inline-block align-middle me-3"
            />
            <span className={`account-title ${isMobileDevice ? 'd-none' : ''}`}>{TEXT_LOGO}</span>
          </Navbar.Brand>
          <Nav>
            <Link
              aria-label={accessibility.label.FAQ}
              className="me-4 nav-link"
              to={
                countryCode && selectedLanguage
                  ? `/faq/?country=${countryCode}&clientid=${clientId}&lang=${selectedLanguage}`
                  : '/faq/'
              }
              target="_blank"
              onMouseEnter={() => setFaqHover(true)}
              onMouseLeave={() => setFaqHover(false)}
            >
              <div className="d-flex align-items-center">
                <img
                  src={faqHover ? QuestionMarkRed : QuestionMarkBlack}
                  alt=""
                />
                <span className="ms-2">{TEXT_FAQ}</span>
              </div>
            </Link>
            {!hideLanguageBar &&
              languages.length > 1 &&
              languages.map((language: any, index: number) => {
                return (
                  <Nav.Link
                    aria-label={EN}
                    key={index}
                    onClick={() => languageHandler(language)}
                    className={language === className ? 'selected' : ''}
                  >
                    {language.split('-')[0]}
                  </Nav.Link>
                );
              })}
          </Nav>
        </div>
      </div>
    </Navbar>
  );
}

export default Header;
