import React, { useEffect, useState } from 'react';

const DynamicImage = ({ src, defaultSrc, isDaoCountry = false, alt, ...props }) => {
  const [imgSrc, setImgSrc] = useState(isDaoCountry ? src : defaultSrc);

  useEffect(() => {
    setImgSrc(isDaoCountry ? src : defaultSrc);
  }, [isDaoCountry, src, defaultSrc]);

  const handleError = () => {
    setImgSrc(defaultSrc);
  };

  return (
    <img
      src={imgSrc}
      alt={alt}
      onError={handleError}
      {...props}
    />
  );
};

export default DynamicImage;
