import {
  SET_ACCOUNT_PROP,
  SET_ALT_LANG_CODE,
  GET_TRANSLATIONS_AKAMAI,
  SET_TRANSLATIONS_AKAMAI,
  SET_TRANSLATIONS_AKAMAI_ERROR,
  GET_EN_GB_LANGUAGE_COUNTRIES,
  SET_EN_GB_LANGUAGE_COUNTRIES,
} from './actionTypes';

export const getTranslationsAkamai = (request) => {
  return {
    type: GET_TRANSLATIONS_AKAMAI,
    payload: request,
  };
};

export const setTranslationsAkamai = (response) => {
  return {
    type: SET_TRANSLATIONS_AKAMAI,
    payload: response,
  };
};

export const setTranslationsAkamaiError = (response) => {
  return {
    type: SET_TRANSLATIONS_AKAMAI_ERROR,
    payload: response,
  };
};

export const setAccountProp = (key, value) => {
  return {
    type: SET_ACCOUNT_PROP,
    payload: { key, value },
  };
};

export const setAltLangCode = (selectedLanguage) => {
  return {
    type: SET_ALT_LANG_CODE,
    payload: selectedLanguage,
  };
};

export const getEnGBLanguageCountries = (request) => {
  return {
    type: GET_EN_GB_LANGUAGE_COUNTRIES,
    payload: request,
  };
};

export const setEnGBLanguageCountries = (response) => {
  return {
    type: SET_EN_GB_LANGUAGE_COUNTRIES,
    payload: response,
  };
};
