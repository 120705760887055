import React from 'react';
import { Field } from 'formik';
import { Form } from 'react-bootstrap';

import './TextField.css';
import Tooltip from '../Tooltip/Tooltip';
import { directionLayout } from '../../../utils/constants';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { useSearchDebounce } from '../../../hooks/useSearchDebounce';

type TFieldInfo = {
  info: string;
  placement: any;
};

type TTextField = {
  label: string;
  mandatoryLabel?: string;
  name: string;
  type: string;
  placeholder: string;
  mandatoryMsg?: string;
  checkMark?: boolean;
  delayErrorMsg?: boolean;
  loading?: boolean;
  disabled?: boolean;
  fieldInfo?: TFieldInfo;
  ariaLabel?: string;
  onChange?: (e) => {};
  validate?: () => {};
  errorMsgClassName?: string;
};

const FormikTextField = (props: TTextField) => {
  const {
    label,
    mandatoryLabel,
    name,
    type,
    placeholder,
    checkMark,
    delayErrorMsg = false,
    mandatoryMsg,
    loading,
    disabled,
    fieldInfo,
    ariaLabel,
    onChange,
    validate,
    errorMsgClassName,
    ...rest
  } = props;

  const isMediumDevice = useMediaQuery('only screen and (min-width : 320px) and (max-width : 767px)');

  let className = 'custom-text-field';
  className = checkMark ? className.concat(' has-checkmark') : className;
  className = loading ? className.concat(' has-loader') : className;
  const [delaySearchError, setSearch, delayError] = useSearchDebounce();

  return (
    <Field
      name={name}
      validate={validate}
    >
      {({ field, form }) => {
        const { errors, touched, setFieldValue, setFieldTouched } = form;
        const { value } = field;

        const error = name.split('.').reduce((acc, current) => acc && acc[current], errors);
        const touch = name.split('.').reduce((acc, current) => acc && acc[current], touched);

        const renderFieldInfo = () => {
          if (!fieldInfo || !fieldInfo?.info) {
            return null;
          }

          return (
            <Tooltip
              info={fieldInfo.info}
              placement={
                isMediumDevice
                  ? 'bottom'
                  : document.documentElement.dir === directionLayout.ltr
                  ? 'right'
                  : document.documentElement.dir === directionLayout.rtl
                  ? 'left'
                  : 'right'
              }
            />
          );
        };

        return (
          <>
            <Form.Floating>
              <Form.Control
                className={className}
                type={type}
                placeholder={placeholder}
                {...rest}
                {...field}
                isValid={checkMark && !!value && !error}
                isInvalid={!!error && !!touch && delayError}
                disabled={disabled}
                value={value || ''}
                aria-label={ariaLabel}
                title={label + (mandatoryLabel ?? '')}
                onChange={(e) => {
                  setFieldTouched(name, true);
                  setFieldValue(name, e.target.value);
                  if (onChange) {
                    onChange(e);
                  }
                  if (delayErrorMsg) {
                    setSearch(e.target.value);
                  }
                }}
              />
              {delayErrorMsg && delaySearchError !== '' && (
                <Form.Control.Feedback
                  className={errorMsgClassName}
                  type="invalid"
                >
                  {error}
                </Form.Control.Feedback>
              )}
              {delayErrorMsg && delaySearchError === '' && (
                <Form.Control.Feedback
                  className={errorMsgClassName}
                  type="invalid"
                >
                  {error === mandatoryMsg && mandatoryMsg}
                </Form.Control.Feedback>
              )}
              {!delayErrorMsg && (
                <Form.Control.Feedback
                  className={errorMsgClassName}
                  type="invalid"
                >
                  {error}
                </Form.Control.Feedback>
              )}
              <label
                htmlFor={name}
                className="field-label"
              >
                <span className="legal-id-code">{label}</span>
                <span className="legal-id-mandatory">{mandatoryLabel}</span>
                {renderFieldInfo()}
              </label>
            </Form.Floating>
          </>
        );
      }}
    </Field>
  );
};

export default FormikTextField;
