// app level action types
export const UPDATE_ETL_LIST = 'UPDATE_ETL_LIST';
export const SET_ETL_STATUS = 'SET_ETL_STATUS';
export const SET_UI_PROP = 'SET_UI_PROP';
export const SET_APP_PROP = 'SET_APP_PROP';
export const SET_ETL_LIST = 'SET_ETL_LIST';
export const GET_TRACK_USER_BY_ACTIVITY = 'GET_TRACK_USER_BY_ACTIVITY';
export const SET_TRACK_USER_BY_ACTIVITY = 'SET_TRACK_USER_BY_ACTIVITY';
export const GENERATE_TRACK_ID = 'GENERATE_TRACK_ID';
export const GENERATE_TRACK_ID_SUCCESS = 'GENERATE_TRACK_ID_SUCCESS';
export const SET_PREFFERED_LANGUAGE = 'SET_PREFFERED_LANGUAGE';
export const GET_MAINTENANCE_STATUS = 'GET_MAINTENANCE_STATUS';
export const SET_MAINTENANCE_STATUS = 'SET_MAINTENANCE_STATUS';

// account level action types
export const GET_TRANSLATIONS_AKAMAI = 'GET_TRANSLATIONS_AKAMAI';
export const SET_TRANSLATIONS_AKAMAI = 'SET_TRANSLATIONS_AKAMAI';
export const SET_TRANSLATIONS_AKAMAI_ERROR = 'SET_TRANSLATIONS_AKAMAI_ERROR';
export const SET_ACCOUNT_PROP = 'SET_ACCOUNT_PROP';
export const SET_ALT_LANG_CODE = 'SET_ALT_LANG_CODE';
export const GET_EN_GB_LANGUAGE_COUNTRIES = 'GET_EN_GB_LANGUAGE_COUNTRIES';
export const SET_EN_GB_LANGUAGE_COUNTRIES = 'SET_EN_GB_LANGUAGE_COUNTRIES';

// contact level action types
export const LOAD_CONTACT_CONTENT = 'LOAD_CONTACT_CONTENT';
export const SET_CONTACT_CONTENT = 'SET_CONTACT_CONTENT';
export const SET_CONTACT_PROP = 'SET_CONTACT_PROP';
export const SET_CONTACT_KEY = 'SET_CONTACT_KEY';
export const SET_VALIDATE_PHONE = 'SET_VALIDATE_PHONE';
export const SET_CONTACT = 'SET_CONTACT';
export const VALIDATE_MY_BILL = 'VALIDATE_MY_BILL';
export const VALIDATE_PHONE_NUMBER = 'VALIDATE_PHONE_NUMBER';
export const VALIDATE_MY_BILL_SUCCESS = 'VALIDATE_MY_BILL_SUCCESS';
export const SET_VALIDATE_MY_BILL_ERROR = 'SET_VALIDATE_MY_BILL_ERROR';

// company level action types
export const GET_REGISTERED_COUNTRY_DETAILS = 'GET_REGISTERED_COUNTRY_DETAILS';
export const GET_REGISTERED_COUNTRY_CONFIG = 'GET_REGISTERED_COUNTRY_CONFIG';
export const SET_REGISTERED_COUNTRY_CONFIG = 'SET_REGISTERED_COUNTRY_CONFIG';
export const SET_COMPANY_RELATED_DETAILS = 'SET_COMPANY_RELATED_DETAILS';
export const SET_REGISTRATION_DETAILS = 'SET_REGISTRATION_DETAILS';
export const SET_REGISTRATION_RELATED_DETAILS = 'SET_REGISTRATION_RELATED_DETAILS';
export const GET_COMPANY_INFO = 'GET_COMPANY_INFO';
export const GET_BRE_INFO = 'GET_BRE_INFO';
export const SET_COMPANY_AND_ADDRESSES = 'SET_COMPANY_AND_ADDRESSES';
export const UPDATE_ADDRESS_DETAIL = 'UPDATE_ADDRESS_DETAIL';
export const SET_COMPANY_PROP = 'SET_COMPANY_PROP';
export const SET_COMPANY = 'SET_COMPANY';
export const RESET_COMPANY_INFO = 'RESET_COMPANY_INFO';
export const GET_VALIDATE_ADDRESS_DETAILS = 'GET_VALIDATE_ADDRESS_DETAILS';
export const GET_VALIDATE_ADDRESS_DETAILS_SUCCESS = 'GET_VALIDATE_ADDRESS_DETAILS_SUCCESS';
export const SET_BILLING_ADDRESS_DETAILS = 'SET_BILLING_ADDRESS_DETAILS';
export const GET_BRE_DETAILS = 'GET_BRE_DETAILS';
export const SET_BRE_DETAILS = 'SET_BRE_DETAILS';
export const GET_LEGAL_ID_TRANSLATIONS = 'GET_LEGAL_ID_TRANSLATIONS';
export const SET_LEGAL_ID_TRANSLATIONS = 'SET_LEGAL_ID_TRANSLATIONS';
export const GET_VISITING_ADDRESS = 'GET_VISITING_ADDRESS';
export const SET_VISITING_ADDRESS = 'SET_VISITING_ADDRESS';
export const GET_MANDATORY_DISABLED_VISITING_ADDRESS_FIELDS = 'GET_MANDATORY_DISABLED_VISITING_ADDRESS_FIELDS';
export const GET_BILLING_ADDRESS = 'GET_BILLING_ADDRESS';
export const SET_BILLING_ADDRESS = 'SET_BILLING_ADDRESS';
export const GET_MANDATORY_DISABLED_BILLING_ADDRESS_FIELDS = 'GET_MANDATORY_DISABLED_BILLING_ADDRESS_FIELDS';
export const GET_REGEX_VISITING_COUNTRY = 'GET_REGEX_VISITING_COUNTRY';
export const GET_REGEX_BILLING_COUNTRY = 'GET_REGEX_BILLING_COUNTRY';
export const SET_COMPANY_KEY = 'SET_COMPANY_KEY';
export const GET_DOC_UPLOAD = 'GET_DOC_UPLOAD';
export const GET_DOC_UPLOAD_SUCCESS = 'GET_DOC_UPLOAD_SUCCESS';
export const GET_DOC_UPLOAD_STATUS_CODE = 'GET_DOC_UPLOAD_STATUS_CODE';
export const GET_DOC_DELETE = 'GET_DOC_DELETE';
export const GET_SELECTED_FILES = 'GET_SELECTED_FILES';
export const GET_DELETED_FILES = 'GET_DELETED_FILES';
export const SET_COMPANY_COUNTRY_MANDATORY_DISABLED_FIELDS = 'SET_COMPANY_COUNTRY_MANDATORY_DISABLED_FIELDS';

// shipping level action types
export const GET_SUMMARY_STATUS = 'GET_SUMMARY_STATUS';
export const SET_SUMMARY_STATUS = 'SET_SUMMARY_STATUS';
export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS';
export const SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS';
export const GET_CUSTOMER_DETAILS_FLOW_TWO = 'GET_CUSTOMER_DETAILS_FLOW_TWO';
export const SET_CUSTOMER_DETAILS_FLOW_TWO = 'SET_CUSTOMER_DETAILS_FLOW_TWO';
export const GET_RATES_SERVICE_DOCS = 'GET_RATES_SERVICE_DOCS';
export const SET_RATES_SERVICE_DOCS = 'SET_RATES_SERVICE_DOCS';
export const SET_SERVICE_DOCS = 'SET_SERVICE_DOCS';
export const GET_SAVE_CONSENT = 'GET_SAVE_CONSENT';
export const SET_SAVE_CONSENT = 'SET_SAVE_CONSENT';
export const SET_OSS_TIMEOUT = 'SET_OSS_TIMEOUT';
export const PATCH_ACC_ACTIVITY = 'PATCH_ACC_ACTIVITY';
export const GET_DOCS_REGENERATE = 'GET_DOCS_REGENERATE';
export const SET_SHIPPING_PROP = 'SET_SHIPPING_PROP';
export const SET_DOCUMENTS_GENERATE_ERROR_CODE = 'SET_DOCUMENTS_GENERATE_ERROR_CODE';

// corporate account level action types
export const GET_COUNTRY_REF_DATA = 'GET_COUNTRY_REF_DATA';
export const GET_COUNTRY_REF_DATA_SUCCESS = 'GET_COUNTRY_REF_DATA_SUCCESS';
export const SET_COUNTRY_REF_DATA_ERROR = 'GET_COUNTRY_REF_DATA_ERROR';
export const SET_THANK_YOU_PAGE = 'SET_THANK_YOU_PAGE';
export const GET_USER_TRACK_ID = 'GET_USER_TRACK_ID';
export const SET_USER_TRACK_ID = 'SET_USER_TRACK_ID';
export const SET_CORP_ACC_PROP = 'SET_CORP_ACC_PROP';
export const GET_LANDING_PAGE = 'GET_LANDING_PAGE';
export const SET_LANDING_PAGE = 'SET_LANDING_PAGE';

// payment level action types
export const GET_DEBIT_SUCCESS = 'GET_DEBIT_SUCCESS';
export const SET_DEBIT_STATUS = 'SET_DEBIT_STATUS';
export const SET_DEBIT_ERROR = 'SET_DEBIT_ERROR';
export const GET_PAYMENT_PROFILE = 'GET_PAYMENT_PROFILE';
export const SET_PAYMENT_PROFILE = 'SET_PAYMENT_PROFILE';
export const GET_PAYMENT_STATUS = 'GET_PAYMENT_STATUS';
export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';
export const SET_CARD_DETAILS = 'SET_CARD_DETAILS';
export const SET_IXOPAY_RIDIRECT_URL = 'SET_IXOPAY_RIDIRECT_URL';
export const SET_RETRY_PAYMENT = 'SET_RETRY_PAYMENT';
export const SET_PAYMENT_ERROR = 'SET_PAYMENT_ERROR';
export const SET_PAYMENT_PROFILE_ERROR = 'SET_PAYMENT_PROFILE_ERROR';
export const SET_PAYMENT_ETL_LIST = 'SET_PAYMENT_ETL_LIST';
export const SET_PAYMENT_PROP = 'SET_PAYMENT_PROP';
export const SET_PAYMENT_LOADOUT = 'SET_PAYMENT_LOADOUT';
export const GET_PAYMENT_METHOD_TYPE = 'GET_PAYMENT_METHOD_TYPE';

// dao level action types
export const GET_ADDRESS_SUGGESTIONS = 'GET_ADDRESS_SUGGESTIONS';
export const GET_ADDRESS_SUGGESTIONS_SUCCESS = 'GET_ADDRESS_SUGGESTIONS_SUCCESS';
export const GET_ADDRESS_SUGGESTIONS_ERROR = 'GET_ADDRESS_SUGGESTIONS_ERROR';
export const GET_ADDRESS_DETAILS = 'GET_ADDRESS_DETAILS';
export const GET_ADDRESS_DETAILS_SUCCESS = 'GET_ADDRESS_DETAILS_SUCCESS';
export const SET_ADDRESS_DETAILS = 'SET_ADDRESS_DETAILS';
export const SUBMIT_CUSTOMER = 'SUBMIT_CUSTOMER';
export const SUBMIT_CUSTOMER_SUCCESS = 'SUBMIT_CUSTOMER_SUCCESS';
export const GET_SIGNED_DOC = 'GET_SIGNED_DOC';
export const GET_SIGNED_DOC_SUCCESS = 'GET_SIGNED_DOC_SUCCESS';
export const GET_COUNTRY_REG_TYPE_DATA = 'GET_COUNTRY_REG_TYPE_DATA';
export const GET_COUNTRY_CONFIG = 'GET_COUNTRY_CONFIG';
export const GET_COUNTRY_CONFIG_SUCCESS = 'GET_COUNTRY_CONFIG_SUCCESS';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const SET_OPTION_FLOW = 'SET_OPTION_FLOW';
export const SET_DAO_PROP = 'SET_DAO_PROP';
export const SET_BRE_ERROR_CODE = 'SET_BRE_ERROR_CODE';
export const SET_BRV_ERROR = 'SET_BRV_ERROR';

// review level action types
export const UPDATE_CUSTOMER_CONSENT = 'UPDATE_CUSTOMER_CONSENT';
export const SET_CUSTOMER_CONSENT = 'SET_CUSTOMER_CONSENT';
export const SET_REVIEW_ETL_LIST = 'SET_REVIEW_ETL_LIST';

// summary level action types

// Thankyou level action types
