import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { FormikControl } from '../../common';
import {
  ADDRESS_LINE_1_PLACEHOLDER,
  ADDRESS_LINE_2_PLACEHOLDER,
  CITY_PLACEHOLDER,
  STATE_PLACEHOLDER,
  POSTAL_CODE_PLACEHOLDER,
  PROVINCE_PLACEHOLDER,
  directionLayout,
} from '../../../utils/constants';
import Banner from '../../common/Banner/Banner';

const Address = ({ addressType, onSearch, onChange, onFieldChange, onInputChange }) => {
  const { contact } = useSelector((state: any) => state.contact);
  const {
    mandatoryVisitingAddress,
    mandatoryBillingAddress,
    vAddressDisabledFields,
    bAddressDisabledFields,
    countryConfigData,
    mandatoryVisitingAddressFields,
    mandatoryBillingAddressFields,
    visitingAddressDisabledFields,
    billingAddressDisabledFields,
    companyConfig,
  } = useSelector((state: any) => state.company);
  const { companyCtryCd } = companyConfig;
  const { addressSuggestion, loadingAddress } = useSelector((state: any) => state.DAOReducer);

  const { translations } = useSelector((state: any) => state.account);
  const {
    company: { heading, label, tooltip, accessibility, error },
  } = translations;
  const { VISITING_ADDRESS, BILLING_ADDRESS } = heading;

  const disabledFields = addressType === 'vAddress' ? vAddressDisabledFields : bAddressDisabledFields;
  const disabledAddressFields =
    addressType === 'vAddress' ? visitingAddressDisabledFields : billingAddressDisabledFields;

  const admaAddressSuggestion =
    countryConfigData?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'USE_ADMA_SUGGESTION')?.value === 'Y';

  const renderAddressLine1 = () => {
    return (
      <Row className="field-row">
        <Col>
          {admaAddressSuggestion ? (
            <FormikControl
              control="asynctypeahead"
              name={`${addressType}.addressLine1`}
              ariaLabel={
                addressType === 'vAddress'
                  ? accessibility.label.VISITING_ADDRESS_LINE_1
                  : accessibility.label.BILLING_ADDRESS_LINE_1
              }
              label={label.ADDRESS_1}
              mandatoryLabel={checkFieldMandatory(addressType, 'addressLine1') ? '*' : ''}
              labelKey="addrDesc"
              filterBy={['addrDesc']}
              options={addressSuggestion[addressType]?.addrProviderLst}
              placeholder={ADDRESS_LINE_1_PLACEHOLDER}
              isLoading={loadingAddress[addressType]}
              onSearch={onSearch}
              menuItemChildren={(option: any) => {
                return (
                  <>
                    <span>{option.addrDesc}</span>
                  </>
                );
              }}
              onChange={onChange}
              onInputChange={onInputChange}
              {...(tooltip?.ADDRESS_1 && { fieldInfo: { info: tooltip.ADDRESS_1 } })}
            />
          ) : (
            <FormikControl
              control="inputarray"
              name={`${addressType}.addressLine1`}
              ariaLabel={
                addressType === 'vAddress'
                  ? accessibility.label.VISITING_ADDRESS_LINE_1
                  : accessibility.label.BILLING_ADDRESS_LINE_1
              }
              type="text"
              label={label.ADDRESS_1}
              mandatoryLabel={checkFieldMandatory(addressType, 'addressLine1') ? '*' : ''}
              placeholder={ADDRESS_LINE_1_PLACEHOLDER}
              onChange={(e) => onInputChange(e.target.value)}
              delayErrorMsg={true}
              mandatoryMsg={error.address_line_1.EMPTY}
              errorMsgClassName={
                companyCtryCd !== contact.country[0].code && document.documentElement.dir === directionLayout.rtl
                  ? 'direction-ltr'
                  : ''
              }
              {...(tooltip?.ADDRESS_1 && { fieldInfo: { info: tooltip.ADDRESS_1 } })}
            />
          )}
        </Col>
      </Row>
    );
  };

  const checkFieldMandatory = (addressType, field) => {
    if (addressType === 'vAddress') {
      return mandatoryVisitingAddress.includes(field) || mandatoryVisitingAddressFields.includes(field);
    } else {
      return mandatoryBillingAddress.includes(field) || mandatoryBillingAddressFields.includes(field);
    }
  };

  const renderVisitingAddressDisclaimerBanner = () => {
    if (label.COMPANY_ADDRESS_DISCLAIMER) {
      return (
        <Row className="field-row">
          <Col>
            <Banner message={label.COMPANY_ADDRESS_DISCLAIMER} />
          </Col>
        </Row>
      );
    }

    return null;
  };

  const renderBillingAddressDisclaimerBanner = () => {
    if (label.BILLING_ADDRESS_DISCLAIMER) {
      return (
        <Row className="field-row">
          <Col>
            <Banner message={label.BILLING_ADDRESS_DISCLAIMER} />
          </Col>
        </Row>
      );
    }

    return null;
  };

  return (
    <>
      <Row className="field-row">
        <Col>
          <p className="address">{addressType === 'bAddress' ? BILLING_ADDRESS : VISITING_ADDRESS}</p>
        </Col>
      </Row>
      {addressType === 'vAddress' && renderVisitingAddressDisclaimerBanner()}
      {addressType === 'bAddress' && renderBillingAddressDisclaimerBanner()}
      {renderAddressLine1()}
      <Row className="field-row">
        <Col>
          <FormikControl
            control="input"
            name={`${addressType}.addressLine2`}
            ariaLabel={
              addressType === 'vAddress'
                ? accessibility.label.VISITING_ADDRESS_LINE_2
                : accessibility.label.BILLING_ADDRESS_LINE_2
            }
            type="text"
            label={label.ADDRESS_2}
            mandatoryLabel={checkFieldMandatory(addressType, 'addressLine2') ? '*' : ''}
            placeholder={ADDRESS_LINE_2_PLACEHOLDER}
            onChange={() => onFieldChange('addressLine2')}
            delayErrorMsg={true}
            errorMsgClassName={
              companyCtryCd !== contact.country[0].code && document.documentElement.dir === directionLayout.rtl
                ? 'direction-ltr'
                : ''
            }
            {...(tooltip?.ADDRESS_2 && { fieldInfo: { info: tooltip.ADDRESS_2 } })}
          />
        </Col>
      </Row>
      <div className="row">
        <div className="col-md-6 mt-4">
          <FormikControl
            control="input"
            name={`${addressType}.city`}
            ariaLabel={
              addressType === 'vAddress' ? accessibility.label.VISITING_CITY : accessibility.label.BILLING_CITY
            }
            type="text"
            label={label.CITY}
            mandatoryLabel={checkFieldMandatory(addressType, 'city') ? '*' : ''}
            placeholder={CITY_PLACEHOLDER}
            onChange={() => onFieldChange('city')}
            disabled={disabledFields.includes('city') || disabledAddressFields.includes('city')}
            errorMsgClassName={
              companyCtryCd !== contact.country[0].code && document.documentElement.dir === directionLayout.rtl
                ? 'direction-ltr'
                : ''
            }
            {...(tooltip?.CITY && { fieldInfo: { info: tooltip.CITY } })}
          />
        </div>
        <div className="col-md-6 mt-4">
          <FormikControl
            control="input"
            name={`${addressType}.state`}
            ariaLabel={
              addressType === 'vAddress' ? accessibility.label.VISITING_STATE : accessibility.label.BILLING_STATE
            }
            type="text"
            label={label.STATE}
            mandatoryLabel={checkFieldMandatory(addressType, 'state') ? '*' : ''}
            placeholder={STATE_PLACEHOLDER}
            onChange={() => onFieldChange('state')}
            disabled={disabledFields.includes('state') || disabledAddressFields.includes('state')}
            errorMsgClassName={
              companyCtryCd !== contact.country[0].code && document.documentElement.dir === directionLayout.rtl
                ? 'direction-ltr'
                : ''
            }
            {...(tooltip?.STATE && { fieldInfo: { info: tooltip.STATE } })}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mt-4">
          <FormikControl
            control="input"
            name={`${addressType}.zipCode`}
            ariaLabel={
              addressType === 'vAddress'
                ? accessibility.label.VISITING_POSTAL_CODE
                : accessibility.label.BILLING_POSTAL_CODE
            }
            type="text"
            label={label.POSTAL_CODE}
            mandatoryLabel={checkFieldMandatory(addressType, 'zipCode') ? '*' : ''}
            placeholder={POSTAL_CODE_PLACEHOLDER}
            onChange={() => onFieldChange('zipCode')}
            disabled={disabledFields.includes('zipCode') || disabledAddressFields.includes('zipCode')}
            errorMsgClassName={
              companyCtryCd !== contact.country[0].code && document.documentElement.dir === directionLayout.rtl
                ? 'direction-ltr'
                : ''
            }
            {...(tooltip?.POSTAL_CODE && { fieldInfo: { info: tooltip.POSTAL_CODE } })}
          />
        </div>
        <div className="col-md-6 mt-4">
          <FormikControl
            control="input"
            name={`${addressType}.province`}
            ariaLabel={
              addressType === 'vAddress' ? accessibility.label.VISITING_PROVINCE : accessibility.label.BILLING_PROVINCE
            }
            type="text"
            label={label.PROVINCE}
            mandatoryLabel={checkFieldMandatory(addressType, 'province') ? '*' : ''}
            placeholder={PROVINCE_PLACEHOLDER}
            onChange={() => onFieldChange('province')}
            disabled={disabledFields.includes('province') || disabledAddressFields.includes('province')}
            errorMsgClassName={
              companyCtryCd !== contact.country[0].code && document.documentElement.dir === directionLayout.rtl
                ? 'direction-ltr'
                : ''
            }
            {...(tooltip?.PROVINCE && { fieldInfo: { info: tooltip.PROVINCE } })}
          />
        </div>
      </div>
    </>
  );
};

export default Address;
