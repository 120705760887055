import {
  GET_ADDRESS_SUGGESTIONS,
  GET_ADDRESS_SUGGESTIONS_SUCCESS,
  GET_ADDRESS_DETAILS,
  GET_ADDRESS_DETAILS_SUCCESS,
  SET_ADDRESS_DETAILS,
  GET_ADDRESS_SUGGESTIONS_ERROR,
  GET_VALIDATE_ADDRESS_DETAILS,
  GET_VALIDATE_ADDRESS_DETAILS_SUCCESS,
  SUBMIT_CUSTOMER,
  SUBMIT_CUSTOMER_SUCCESS,
  GET_SIGNED_DOC,
  GET_SIGNED_DOC_SUCCESS,
  CREATE_ACCOUNT,
  CREATE_ACCOUNT_SUCCESS,
  SET_OPTION_FLOW,
  SET_DAO_PROP,
  GET_BRE_DETAILS,
  SET_BRE_DETAILS,
  SET_BRE_ERROR_CODE,
  SET_BRV_ERROR,
  SET_BILLING_ADDRESS_DETAILS,
} from './actionTypes';

export const getAddressSuggestions = (request, addressType) => {
  return {
    type: GET_ADDRESS_SUGGESTIONS,
    payload: {
      request,
      addressType,
    },
  };
};

export const getAddressSuggestionsSuccess = (addressSuggestion, addressType) => {
  return {
    type: GET_ADDRESS_SUGGESTIONS_SUCCESS,
    payload: {
      addressSuggestion,
      addressType,
    },
  };
};

export const getAddressDetails = (request, addressType) => {
  return {
    type: GET_ADDRESS_DETAILS,
    payload: {
      request,
      addressType,
    },
  };
};

export const getAddressDetailsSuccess = (addressDetail, addressType) => {
  return {
    type: GET_ADDRESS_DETAILS_SUCCESS,
    payload: {
      addressDetail,
      addressType,
    },
  };
};

export const setAddressDetails = (addressType, addressDetail) => {
  return {
    type: SET_ADDRESS_DETAILS,
    payload: {
      addressDetail,
      addressType,
    },
  };
};

export const getAddressSuggestionsError = (error, addressType) => {
  return {
    type: GET_ADDRESS_SUGGESTIONS_ERROR,
    payload: {
      error,
      addressType,
    },
  };
};

export const getValidateAddressDetails = (request) => {
  return {
    type: GET_VALIDATE_ADDRESS_DETAILS,
    payload: request,
  };
};

export const getValidateAddressDetailsSuccess = (response) => {
  return {
    type: GET_VALIDATE_ADDRESS_DETAILS_SUCCESS,
    payload: response,
  };
};

export const setBillingAddressDetails = (response) => {
  return {
    type: SET_BILLING_ADDRESS_DETAILS,
    payload: response,
  };
};

export const getBreDetails = (request) => {
  return {
    type: GET_BRE_DETAILS,
    payload: request,
  };
};

export const setBreDetails = (response) => {
  return {
    type: SET_BRE_DETAILS,
    payload: response,
  };
};

export const setBreErrorCode = (breErrorCode) => {
  return {
    type: SET_BRE_ERROR_CODE,
    payload: breErrorCode,
  };
};

export const setBrvError = (error) => {
  const { response = {} } = error;
  let brvHttpStatus;
  if (response?.status === 400) {
    brvHttpStatus = (Object.values(error)[0] as any)?.brvErrorStatus;
  } else {
    brvHttpStatus = response.status;
  }
  const brvError = error;

  return {
    type: SET_BRV_ERROR,
    payload: {
      brvError,
      brvHttpStatus,
    },
  };
};

export const submitCustomer = () => {
  return {
    type: SUBMIT_CUSTOMER,
  };
};

export const submitCustomerSuccess = (response) => {
  return {
    type: SUBMIT_CUSTOMER_SUCCESS,
    payload: response,
  };
};

export const getSignedDoc = (request) => {
  return {
    type: GET_SIGNED_DOC,
    payload: request,
  };
};

export const getSignedDocSuccess = (response) => {
  return {
    type: GET_SIGNED_DOC_SUCCESS,
    payload: response,
  };
};

export const createAccount = (request) => {
  return {
    type: CREATE_ACCOUNT,
    payload: request,
  };
};

export const createAccountSuccess = (response) => {
  return {
    type: CREATE_ACCOUNT_SUCCESS,
    payload: response,
  };
};

export const setOptionFlow = (configurations) => {
  let optionFlow;

  configurations.ctryCfgs.forEach((ctryCfg) => {
    if (ctryCfg.typeCd === 'OPTION_FLOW') {
      optionFlow = ctryCfg.value;
    }
  });

  return {
    type: SET_OPTION_FLOW,
    payload: optionFlow,
  };
};

export const setDaoProp = (key, value) => {
  return {
    type: SET_DAO_PROP,
    payload: { key, value },
  };
};
