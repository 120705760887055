// checkFranceLegalId.ts is an interim solution and should be removed eventually
// Below are the following requirements
// 1. SIREN legal ID type needs to be disabled for France
// 2. SIREN will instead take the first 9 characters of SIRET legal ID
// 3. This change was made in ddao-3489

// Removing checkFranceLegalId
// 1. Delete this file
// 2. Go to components/containers/Company/CompanyPage.tsx
// 3. Delete code below

// useEffect(() => {
//   checkFranceSiret(setFieldValue, companyCountryCode, values.legalCodes['SIRET']);
// }, [values.legalCodes['SIRET']]);

// 4. Delete code below
// checkFranceSiren(companyCountryCode, refData.legalIdCode)
// above code can be located under below code
// disabled={values.registrationNotPresent || checkFranceSiren(companyCountryCode, refData.legalIdCode)}

// 5. Delete import code
// import { checkFranceSiren, checkFranceSiret } from '../../../utils/checkFranceLegalId';

export const checkFranceSiren = (companyCountryCode, legalIdCode) => {
  return companyCountryCode === 'FR' && legalIdCode === 'SIREN';
};

export const checkFranceSiret = (setFieldValue, companyCountryCode, siretValue) => {
  if (companyCountryCode === 'FR' && siretValue) {
    setFieldValue('legalCodes.SIREN', siretValue.substring(0, 9));
  }
};
