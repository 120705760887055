import React from 'react';
import { Field } from 'formik';
import { Form } from 'react-bootstrap';

import './Select.css';
import Tooltip from '../Tooltip/Tooltip';
import { directionLayout } from '../../../utils/constants';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

type TFieldInfo = {
  info: any;
  placement: any;
};

type TOption = {
  value: string;
  label?: string;
};

type TSelect = {
  name: string;
  label: string;
  mandatoryLabel?: string;
  placeholder: string;
  options: TOption[];
  onChange?: (e) => {};
  disableList: string[];
  checkMark?: boolean;
  ariaLabel?: string;
  fieldInfo?: TFieldInfo;
};

const FormikSelect = (props: TSelect) => {
  const {
    name,
    label,
    mandatoryLabel,
    options,
    placeholder,
    disableList,
    checkMark = false,
    ariaLabel,
    fieldInfo,
    onChange,
    ...rest
  } = props;

  const isMediumDevice = useMediaQuery('only screen and (min-width : 320px) and (max-width : 767px)');

  return (
    <Field name={name}>
      {({ field, form }) => {
        const { errors, touched, setFieldValue, setFieldTouched } = form;
        const { value } = field;

        const error = name.split('.').reduce((acc, current) => acc && acc[current], errors);
        const touch = name.split('.').reduce((acc, current) => acc && acc[current], touched);

        const renderFieldInfo = () => {
          if (!fieldInfo || !fieldInfo?.info) {
            return null;
          }

          return (
            <Tooltip
              info={fieldInfo.info}
              placement={
                isMediumDevice
                  ? 'bottom'
                  : document.documentElement.dir === directionLayout.ltr
                  ? 'right'
                  : document.documentElement.dir === directionLayout.rtl
                  ? 'left'
                  : 'right'
              }
            />
          );
        };

        return (
          <>
            <Form.Floating>
              <Form.Select
                className="custom-select-field text-truncate"
                {...field}
                {...rest}
                isValid={checkMark && !!value && !error}
                isInvalid={!!error && !!touch}
                aria-label={ariaLabel}
                title={label + (mandatoryLabel ?? '')}
                onChange={(e) => {
                  setFieldTouched(name, true);
                  setFieldValue(name, e.target.value);
                  if (onChange) {
                    onChange(e);
                  }
                }}
                id={name}
              >
                {options.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    disabled={disableList?.includes(option.value)}
                  >
                    {option.label || option.value || placeholder}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
              <label
                htmlFor={name}
                className="field-label"
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: label,
                  }}
                ></span>
                <span className="legal-id-mandatory">{mandatoryLabel}</span>
                {renderFieldInfo()}
              </label>
            </Form.Floating>
          </>
        );
      }}
    </Field>
  );
};

export default FormikSelect;
