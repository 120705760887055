import React from 'react';
import { Modal, Button } from '../../common';

function BasicModal2({ show, title, content, actions, actionHandler }) {
  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered={true}
        restoreFocus={false}
        dialogClassName="custom-modal"
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          {actions.map((action: any, index: number) => {
            return (
              <Button
                key={index}
                variant={action.type}
                onClick={() => actionHandler(action.name)}
                className={action.type === 'link' ? 'custom-modal-link' : ''}
              >
                {action.name}
              </Button>
            );
          })}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BasicModal2;
