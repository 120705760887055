import React from 'react';
import { Modal } from '../../common';
import { useSelector } from 'react-redux';

function ShowDocumentExampleModal({ show, handleClose }) {
  const { translations } = useSelector((state: any) => state.account);
  const {
    company: { modal },
  } = translations;
  const { countryConfigData } = useSelector((state: any) => state.corporateAccount);
  const urlDomain = process.env.REACT_APP_URL;
  const countryCode = countryConfigData?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DAO_COUNTRY')?.ctryCd;

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered={true}
        className="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modal.TITLE_DOCUMENT_EXAMPLE}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0 text-center">
          <div className="row">
            {Object.keys(modal.showDocuments.sampleList).map((item) => {
              return (
                <div
                  className={`mb-3 ${
                    Object.keys(modal.showDocuments.sampleList).length > 1 ? 'col-md-6' : 'col-md-12'
                  }`}
                  key={item}
                >
                  <div className="fw-bold mb-3">{modal.showDocuments.sampleList[item].TITLE}</div>
                  <img
                    alt="document Sample"
                    className="doc-sample-img"
                    src={`${urlDomain}/assets/${countryCode}/images/sample-doc/document-sample-img-${item}.png`}
                  />
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ShowDocumentExampleModal;
