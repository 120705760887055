import { SET_CUSTOMER_CONSENT } from '../actions/actionTypes';

const initialState: any = {
  acceptTermsAndConditions: false,
  serviceAgreementAndRateCard: false,
  receiveNewsAndUpdates: false,
};

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER_CONSENT: {
      return { ...state, [action.payload.key]: action.payload.value };
    }

    default:
      return state;
  }
};

export default reviewReducer;
