import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { replace } from 'lodash';

import { setContact, validateMyBill } from '../../../store/actions/contactActions';
import ContactPage from './ContactPage';
import { numberRegex } from '../../../utils/regEx';
import { getPaymentProfile } from '../../../store/actions/paymentActions';
import { setAppProp } from '../../../store/actions/appActions';
import { useTranslationSteps } from '../../../hooks/useTranslationSteps';
import {
  OPTION_FLOW_1,
  contactFieldsNames,
  daoSteps,
  flowOneSteps,
  nonDaoSteps,
  paymentExitSteps,
  paymentExitTypes,
} from '../../../utils/constants';
import { submitCustomer } from '../../../store/actions/daoActions';

const ContactContainer = ({ nextPage }) => {
  const dispatch = useDispatch<any>();
  const { appTimers } = useSelector((state: any) => state.app);
  const { paymentMethodType } = appTimers;
  const { optionFlow } = useSelector((state: any) => state.DAOReducer);
  const { contact, validatePhone, contactMandatoryFields, contactFields } = useSelector((state: any) => state.contact);
  const { ixopayCurrency, showConsent } = contactFields;
  const { validPhone } = validatePhone;
  const contactCountryCode = contact.country[0]?.code;

  const { translations } = useSelector((state: any) => state.account);
  const {
    contact: { heading, error },
    payment,
  } = translations;
  const { countryConfigData, contactDaoRegex, contactBreRegex } = useSelector((state: any) => state.corporateAccount);
  const { firstNameBreRegexValue, lastNameBreRegexValue, emailBreRegexValue, telExtBreRegexValue, jobBreRegexValue } =
    contactBreRegex;
  const { firstNameDaoRegexValue, lastNameDaoRegexValue, emailDaoRegexValue, jobDaoRegexValue } = contactDaoRegex;
  const daoCountry = countryConfigData?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DAO_COUNTRY')?.value === 'Y';

  const firstNameDaoRegex = new RegExp(firstNameDaoRegexValue);
  const lastNameDaoRegex = new RegExp(lastNameDaoRegexValue);
  const emailDaoRegex = new RegExp(emailDaoRegexValue);
  const jobDaoRegex = new RegExp(jobDaoRegexValue);

  const firstNameBreRegex = new RegExp(firstNameBreRegexValue);
  const lastNameBreRegex = new RegExp(lastNameBreRegexValue);
  const emailBreRegex = new RegExp(emailBreRegexValue);
  const telExtBreRegex = new RegExp(telExtBreRegexValue);
  const jobBreRegex = new RegExp(jobBreRegexValue);

  const [translatedDaoSteps, translatedNonDaoSteps, translatedFlowOneSteps, translatedPaymentExitSteps] =
    useTranslationSteps(daoSteps, nonDaoSteps, flowOneSteps, paymentExitSteps);

  const contactValidationSchema = Yup.object().shape({
    title: Yup.string().test('titleRequired', error.title.EMPTY, (value) =>
      contactMandatoryFields.includes('title') ? !!value && value.length > 0 : true
    ),
    firstName: Yup.string()
      .test('firstNameRequired', error.first_name.EMPTY, (value) =>
        contactMandatoryFields.includes('firstName') ? !!value && value.length > 0 : true
      )
      .max(22, error.first_name.LENGTH)
      .test('firstNameRegex', (value = '', { createError, path }) => {
        if (value && firstNameDaoRegexValue?.length > 0 && firstNameDaoRegex.test(value)) {
          return createError({
            path,
            message: error.first_name.CHARACTER,
          });
        }
        if (value && firstNameBreRegexValue !== null && !firstNameBreRegex.test(value)) {
          return createError({
            path,
            message: error.first_name.FORMAT,
          });
        } else return true;
      }),
    lastName: Yup.string()
      .test('lastNameRequired', error.last_name.EMPTY, (value) =>
        contactMandatoryFields.includes('lastName') ? !!value && value.length > 0 : true
      )
      .max(22, error.last_name.LENGTH)
      .test('lastNameRegex', (value = '', { createError, path }) => {
        if (value && lastNameDaoRegexValue?.length > 0 && lastNameDaoRegex.test(value)) {
          return createError({
            path,
            message: error.last_name.CHARACTER,
          });
        }
        if (value && lastNameBreRegexValue !== null && !lastNameBreRegex.test(value)) {
          return createError({
            path,
            message: error.last_name.FORMAT,
          });
        } else return true;
      }),
    email: Yup.string()
      .test('emailRequired', error.email.EMPTY, (value) =>
        contactMandatoryFields.includes('email') ? !!value && value.length > 0 : true
      )
      .max(100, error.email.LENGTH)
      .test('emailRegex', (value = '', { createError, path }) => {
        if (value && emailDaoRegexValue?.length > 0 && emailDaoRegex.test(value)) {
          return createError({
            path,
            message: error.email.CHARACTER,
          });
        }
        if (value && emailBreRegexValue !== null && !emailBreRegex.test(value)) {
          return createError({
            path,
            message: error.email.FORMAT,
          });
        } else return true;
      })
      .email(error.email.FORMAT),
    ext: Yup.string()
      .test('extRequired', error.phone_extension.EMPTY, (value) =>
        contactMandatoryFields.includes('ext') ? !!value && value.length > 0 : true
      )
      .test('extNoRegex', (value = '', { createError, path }) => {
        if (value && telExtBreRegexValue !== null && !telExtBreRegex.test(value)) {
          return createError({
            path,
            message: error.phone_extension.FORMAT,
          });
        } else return true;
      }),
    job: Yup.string()
      .test('jobRequired', error.job.EMPTY, (value) =>
        contactMandatoryFields.includes('job') ? !!value && value.length > 0 : true
      )
      .max(35, error.job.LENGTH)
      .test('jobRegex', (value = '', { createError, path }) => {
        if (value && jobDaoRegexValue?.length > 0 && jobDaoRegex.test(value)) {
          return createError({
            path,
            message: error.job.CHARACTER,
          });
        }
        if (value && jobBreRegexValue !== null && !jobBreRegex.test(value)) {
          return createError({
            path,
            message: error.job.FORMAT,
          });
        } else return true;
      }),
    department: Yup.string().test('departmentRequired', error.department.EMPTY, (value) =>
      contactMandatoryFields.includes('department') ? !!value && value.length > 0 : true
    ),
    preferredCommunicationMethod: Yup.string().test(
      'preferredCommunicationMethodRequired',
      error.preferred_comm_method.EMPTY,
      (value) => (contactMandatoryFields.includes('prefCommMthd') ? !!value && value.length > 0 : true)
    ),
  });

  const onSubmit = (values) => {
    dispatch(setContact(values));
    if (daoCountry) {
      fetchPaymentProfile(values.email);
      dispatch(validateMyBill({ email: values.email }));
      if (showConsent) {
        if (contactFieldsNames.some((field) => contact[field] !== values[field])) {
          dispatch(submitCustomer());
        }
      }
    }
    nextPage();
  };

  const fetchPaymentProfile = (email) => {
    const profileData = {
      customerIdentification: email,
      billingCountryCode: contactCountryCode,
      accountRequestId: null,
      makeDebitCall: false,
      amount: payment.TRANSACTION_AMOUNT,
      currency: ixopayCurrency,
      description: payment.PAYMENT_GATEWAY_TRANSACTION_DESCRIPTION,
    };
    dispatch(getPaymentProfile(profileData));
  };

  const validate = (values) => {
    const errors = {};
    const { countryCode } = values;
    const checkMobile = values.phoneType === 'Mobile' && contactMandatoryFields.includes('mobile');
    const checkOffice = values.phoneType === 'Office' && contactMandatoryFields.includes('office');

    if ((checkMobile || checkOffice) && values.countryCode.length < 1) {
      errors['countryCode'] = error.phone_code.EMPTY;
    }

    if ((checkMobile || checkOffice) && !values.number) {
      errors['number'] = error.phone_number.EMPTY;
    } else if (!numberRegex.test(values.number)) {
      errors['number'] = error.phone_number.CHARACTER;
    } else if (values.number.length > 15) {
      errors['number'] = error.phone_number.LENGTH;
    } else if (countryCode.length && !validPhone && values.number) {
      errors['number'] = error.phone_number.FORMAT;
    }

    return errors;
  };

  useEffect(() => {
    if (!daoCountry) {
      dispatch(setAppProp('steps', translatedNonDaoSteps));
    } else if (optionFlow === OPTION_FLOW_1) {
      dispatch(setAppProp('steps', translatedFlowOneSteps));
    } else if (paymentExitTypes.includes(paymentMethodType)) {
      dispatch(setAppProp('steps', translatedPaymentExitSteps));
    } else {
      dispatch(setAppProp('steps', translatedDaoSteps));
    }
  }, [daoCountry]);

  const mandatoryAsteriskHtml = '<span class="mandatory-asterisk">*</span>';

  const subTitleTranslation = replace(heading.MANDATORY_FIELDS_MSG, '{{ASTERISK}}', mandatoryAsteriskHtml);

  return (
    <>
      <Row>
        <Col className="p-0">
          <h1 className="m-0">
            <p className="content-title">{heading?.TELL_US_MSG}</p>
          </h1>
          <p
            className="content-subtitle"
            dangerouslySetInnerHTML={{
              __html: subTitleTranslation,
            }}
          ></p>
        </Col>
      </Row>
      <Formik
        initialValues={contact}
        validationSchema={contactValidationSchema}
        validateOnMount
        validateOnChange
        onSubmit={onSubmit}
        validate={validate}
        enableReinitialize
      >
        {(formik) => {
          return <ContactPage formik={formik} />;
        }}
      </Formik>
    </>
  );
};

export default ContactContainer;
