import React from 'react';
import { Field } from 'formik';
import { Form } from 'react-bootstrap';

import './Checkbox.css';

type TCheckbox = {
  name: string;
  type?: any;
  label: string;
  ariaLabel?: string;
  mandatoryLabel?: string;
  onChange?: (e) => {};
};

const FormikCheckbox = (props: TCheckbox) => {
  const { name, type = 'checkbox', label, mandatoryLabel, ariaLabel, onChange, ...rest } = props;

  return (
    <Field name={name}>
      {({ field, form }) => {
        const { errors, setFieldValue } = form;
        const { value } = field;
        const error = name.split('.').reduce((acc, current) => acc && acc[current], errors);

        return (
          <>
            <input
              id={name}
              className="form-check-input custom-checkbox"
              type={type}
              checked={value}
              {...field}
              {...rest}
              aria-label={ariaLabel}
              onChange={(e) => {
                setFieldValue(name, e.target.checked);
                if (onChange) {
                  onChange(e);
                }
              }}
            />
            <label
              htmlFor={name}
              className="form-check-label"
            >
              <span className="legal-id-mandatory">{mandatoryLabel}</span>
              <span
                className="consent-text"
                dangerouslySetInnerHTML={{
                  __html: label,
                }}
              ></span>
            </label>
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          </>
        );
      }}
    </Field>
  );
};

export default FormikCheckbox;
