import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, useLocation, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-absolute-path
import '../node_modules/flag-icons/css/flag-icons.min.css';

import Button from './components/common/Button/Button';
import Header from './components/common/Header/Header';
import Announcement from './components/common/Announcement/Announcement';
import Footer from './components/common/Footer/Footer';
import CorporateAccount from './components/containers/Account/CorporateAccount';
import FaqPage from './components/containers/Faq/FaqPage';
import { getTranslationsAkamai } from './store/actions/accountActions';
import { getMaintenanceStatus } from './store/actions/appActions';
import { ALT_TAB_TITLE, directionLayout } from './utils/constants';
import RtlLayout from './RtlLayout';
import LtrLayout from './LtrLayout';
import { setCorporateAccountProp } from './store/actions/corporateAccountActions';

function App() {
  const dispatch = useDispatch();
  const [showAnnouncement, setShowAnnouncement] = useState(true);
  const [showLandingBtn, setShowLandingBtn] = useState(true);
  const { translations, languages, selectedLanguage } = useSelector((state: any) => state.account);
  const { currentPage, isoCountryLists } = useSelector((state: any) => state.corporateAccount);
  const { redirectToMaintenance } = useSelector((state: any) => state.app);
  const { common, direction } = translations;
  const { header, footer, announcement, corpFlow } = common;
  const { TAB_TITLE } = header;
  const landingRef = useRef(null);
  const mainRef = useRef(null);
  const skipRef = useRef(null);
  const footerRef = useRef(null);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const countryCode = searchParams.get('country')?.toUpperCase();
  const clientId = searchParams.get('clientid');

  useEffect(() => {
    dispatch(getMaintenanceStatus({ countryCode, clientId }));
  }, []);

  useEffect(() => {
    if (redirectToMaintenance === 1) {
      window.location.href =
        process.env.REACT_APP_URL +
        '/assets.css?country=' +
        countryCode +
        '&clientid=' +
        clientId +
        '&breakconnection=true';
    }
  }, [redirectToMaintenance]);

  useEffect(() => {
    document.title = TAB_TITLE || ALT_TAB_TITLE;
    document.documentElement.lang = selectedLanguage;
    document.documentElement.dir = direction;
  }, [TAB_TITLE, direction]);

  const renderAnnouncement = () => {
    if (showAnnouncement && announcement.TEXT_ANNOUNCEMENT) {
      return (
        <Announcement
          content={announcement}
          onClose={setShowAnnouncement}
        />
      );
    }

    return null;
  };

  const handleSelectedLang = (language) => {
    const translationQuery = `${countryCode}/${countryCode}_${language}.json?lngCd=${language}`;
    dispatch(getTranslationsAkamai(translationQuery));
  };

  const skipToSection = (ref) => (event) => {
    if (event) event.preventDefault();
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
      ref.current.focus();
    }
  };

  useEffect(() => {
    if (isoCountryLists?.length > 0 && selectedLanguage) {
      const countryLocaleNames = new Intl.DisplayNames([`${selectedLanguage}`], { type: 'region' });
      const updatedIsoCountriesListTranslation = isoCountryLists.map((isoCountries) => {
        return { ...isoCountries, name: countryLocaleNames.of(isoCountries.code) };
      });
      dispatch(setCorporateAccountProp('isoCountriesListLocaleTranslation', updatedIsoCountriesListTranslation));
    }
  }, [isoCountryLists, selectedLanguage]);

  return (
    <>
      {redirectToMaintenance === 0 && (
        <div className="dao-container">
          {document.documentElement.dir === directionLayout.ltr && <LtrLayout />}
          {document.documentElement.dir === directionLayout.rtl && <RtlLayout />}
          <span
            ref={skipRef}
            tabIndex={-1}
            className="outline-0"
          >
            {showLandingBtn ? (
              <Button
                className="btn-skip"
                onClick={skipToSection(landingRef)}
              >
                {corpFlow.SKIP_TO_MAIN}
              </Button>
            ) : (
              <Button
                className="btn-skip"
                onClick={skipToSection(mainRef)}
              >
                {corpFlow.SKIP_TO_MAIN}
              </Button>
            )}
            <Button
              className="btn-skip"
              onClick={skipToSection(footerRef)}
            >
              {corpFlow.SKIP_TO_FOOTER}
            </Button>
          </span>
          <Header
            content={header}
            languages={languages}
            languageHandler={handleSelectedLang}
            className={selectedLanguage}
            hideLanguageBar={currentPage !== -1 && !location.pathname.includes('faq')}
          />
          <main>
            {renderAnnouncement()}
            <Routes>
              <Route
                path="/"
                element={
                  <CorporateAccount
                    setShowLandingBtn={setShowLandingBtn}
                    skipToSection={skipToSection}
                    landingRef={landingRef}
                    mainRef={mainRef}
                    skipRef={skipRef}
                  />
                }
              />
              <Route
                path="/faq/"
                element={<FaqPage />}
              />
            </Routes>
          </main>
          <Footer
            content={footer}
            footerRef={footerRef}
          />
        </div>
      )}
    </>
  );
}

export default App;
