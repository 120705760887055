import React, { useEffect, useState } from 'react';

const DynamicBackgroundImage = ({ src, defaultSrc, direction, isDaoCountry = false, children, ...props }) => {
  const [bgImgSrc, setBgImgSrc] = useState(isDaoCountry ? src : defaultSrc);

  useEffect(() => {
    setBgImgSrc(isDaoCountry ? src : defaultSrc);
  }, [isDaoCountry, direction]);

  const handleError = () => {
    setBgImgSrc(defaultSrc);
  };

  const divStyle = {
    backgroundImage: `url(${bgImgSrc})`,
  };

  return (
    <>
      <img
        src={bgImgSrc}
        alt=""
        onError={handleError}
        style={{ display: 'none' }}
      />
      <div
        style={divStyle}
        {...props}
      >
        {children}
      </div>
    </>
  );
};

export default DynamicBackgroundImage;
