import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { replace } from 'lodash';

import {
  getDebit,
  getPaymentProfile,
  getPaymentStatus,
  setIxopayRidirect,
  setPaymentEtlList,
  setPaymentProp,
  setPaymentLoadout,
  getPaymentMethodType,
} from '../../../store/actions/paymentActions';
import { setThankYouPage } from '../../../store/actions/corporateAccountActions';
import { Alert, Button, BasicModal, BasicModal2, Spinner } from '../../common';
import {
  conventionalThankyouSubType,
  MAX_RETRY_COUNT,
  pageMapper,
  paymentExitTypes,
  paymentTransactionStatus,
} from '../../../utils/constants';
import AddLaterModal from './AddLaterModal';
import PaymentPanel from '../../common/PaymentPanel/PaymentPanel';
import checkMarkImg from '../../../assets/icons/checkmark_circle_green.svg';
import './PaymentPage.css';
import { postUserTrackId, setEtlList } from '../../../store/actions/appActions';
import { Etl } from '../../../utils/types';
import { patchAccActivity } from '../../../store/actions/shippingActions';
import { storage } from '../../../utils/helper';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { getBreInfo } from '../../../store/actions/companyActions';

function PaymentPage(props) {
  const { etlList, appTimers, clientId } = useSelector((state: any) => state.app);
  const { userTrackId } = useSelector((state: any) => state.corporateAccount);
  const { loadingSubmitData, submitPostData, breDetails } = useSelector((state: any) => state.DAOReducer);
  const { paymentIxopayIframeTimeout, paymentExitSubType } = appTimers;
  const { contact, contactFields } = useSelector((state: any) => state.contact);
  const { ixopayCurrency } = contactFields;
  const { country, email, firstName, lastName } = contact;
  const { company } = useSelector((state: any) => state.company);
  const { companyName, legalCodes } = company;
  const { selectedLanguage } = useSelector((state: any) => state.account);
  const dispatch = useDispatch<any>();
  const {
    debit,
    loadingDebit,
    loadingProfileSucess,
    paymentProfile,
    paymentStatus,
    cardDetails,
    ixopayIframe,
    loadingIframe,
    paymentCard,
    paymentError,
    debitError,
    paymentProfileCode,
    iframeRetryCount,
    transactionRetryCount,
    timeoutRetryCount,
    iframeTimeoutCount,
  } = useSelector((state: any) => state.payment);
  const { paymentInstruments } = paymentProfile;
  const { redirectUrl } = debit;
  const { translations } = useSelector((state: any) => state.account);
  const { payment, accessibility, IXOPAY_IFRAME_HEIGHT, IXOPAY_MOBILE_IFRAME_HEIGHT } = translations;
  const { errorCode } = paymentStatus;

  const [showAlert, setShowAlert] = useState(false);
  const [showAddLaterModal, setShowAddLaterModal] = useState(false);
  const [showIXOIframeRetryModal, setShowIXOIframeRetryModal] = useState(false);
  const [showTransactionRetryModal, setShowTransactionRetryModal] = useState(false);
  const [showTimeoutRetryModal, setShowTimeoutRetryModal] = useState(false);
  const [addLaterClick, setAddLaterClick] = useState(false);
  const [showExitModal, setShowExitModal] = useState(false);
  const [showExitTransactionErrorModal, setShowExitTransactionErrorModal] = useState(false);
  const [showIframeTimeoutModal, setShowIframeTimeoutModal] = useState(false);
  const closeAddLaterModal = () => setShowAddLaterModal(false);
  const clickToShowAddLaterModal = () => {
    setAddLaterClick(true);
    if (paymentExitTypes.includes(paymentExitSubType)) {
      const savePaymentMethodType = {
        accReqId: submitPostData.accountId,
        paymtMth: paymentExitSubType,
      };
      dispatch(getPaymentMethodType(savePaymentMethodType));
      dispatch(getBreInfo(legalCodes));
    } else {
      setShowAddLaterModal(true);
    }
  };
  const paymentStatusIntervalRef = useRef<any>();
  const isMobileDevice = useMediaQuery('only screen and (min-width : 320px) and (max-width : 767px)');

  useEffect(() => {
    if (paymentExitTypes.includes(paymentExitSubType) && breDetails && addLaterClick) {
      const fldErrs = breDetails?.breRes?.fldErrs;
      const noBillingTermsFromBre = !!fldErrs?.find((error) => error?.ErrMsgDtl?.Sev === 40);

      if (noBillingTermsFromBre) {
        props.setFlow(conventionalThankyouSubType.sorry);
        dispatch(setThankYouPage(true));
        const saveAccActivityData = {
          accRqId: submitPostData.accountId,
          data: {
            rejectionReasonCode: '2006',
            requestId: submitPostData.accountId,
            conventionalIndicator: 'Y',
            conventionalStatus: 'PENDING',
          },
        };
        dispatch(patchAccActivity(saveAccActivityData));
      } else if (fldErrs === null) {
        props.handlePage('summary');
      }
    }
  }, [breDetails]);

  useEffect(() => {
    if (paymentCard && paymentProfile.success) {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 5000);
    }
  }, [paymentCard, paymentProfile.success]);

  useEffect(() => {
    if (debit.success) {
      dispatch(setPaymentProp('iframeRetryCount', 0));
      dispatch(setIxopayRidirect(redirectUrl));
      paymentStatusIntervalRef.current = setInterval(() => {
        const paymentPollingStatus = storage.get('paymentStatus');
        if (paymentPollingStatus === paymentTransactionStatus.SUCCESS) {
          storage.remove('paymentStatus');
          callPaymentProfile();
        } else if (paymentPollingStatus === paymentTransactionStatus.ERROR) {
          storage.remove('paymentStatus');
          callPaymentStatus();
        }
      }, 2000);

      return () => clearInterval(paymentStatusIntervalRef.current);
    }
  }, [debit, paymentProfile]);

  useEffect(() => {
    if (paymentProfile.success || paymentStatus) {
      clearInterval(paymentStatusIntervalRef.current);
    }
  }, [paymentProfile, paymentStatus]);

  useEffect(() => {
    if (paymentProfile.success && cardDetails?.length > 0) {
      callPaymentStatus();
    }
  }, [paymentProfile, cardDetails]);

  useEffect(() => {
    if (redirectUrl && paymentIxopayIframeTimeout && !loadingIframe && iframeTimeoutCount <= MAX_RETRY_COUNT) {
      const paymentIxopayIframeTimer = setTimeout(() => {
        if (iframeTimeoutCount < MAX_RETRY_COUNT) {
          setShowIframeTimeoutModal(true);
        }
      }, paymentIxopayIframeTimeout);

      return () => {
        clearTimeout(paymentIxopayIframeTimer);
      };
    }

    if (redirectUrl && !showIframeTimeoutModal && !iframeTimeoutCount) {
      callIframeLoadedStatus();
    }

    if (iframeTimeoutCount >= MAX_RETRY_COUNT) {
      callIframeNotLoadedStatus();
      setShowExitModal(true);
    }
  }, [paymentIxopayIframeTimeout, loadingIframe, redirectUrl]);

  useEffect(() => {
    dispatch(setPaymentEtlList());
    if (debitError && iframeRetryCount < MAX_RETRY_COUNT) {
      setShowIXOIframeRetryModal(true);
    } else if (debitError && iframeRetryCount >= MAX_RETRY_COUNT) {
      setShowIXOIframeRetryModal(false);
      setShowExitModal(true);
      const saveAccActivityData = {
        accRqId: submitPostData.accountId,
        data: {
          rejectionReasonCode: '5003',
          requestId: submitPostData.accountId,
          conventionalIndicator: 'Y',
          conventionalStatus: 'PENDING',
        },
      };
      dispatch(patchAccActivity(saveAccActivityData));
    }
    if (paymentError && timeoutRetryCount < MAX_RETRY_COUNT) {
      setShowTimeoutRetryModal(true);
    } else if (timeoutRetryCount >= MAX_RETRY_COUNT && paymentError) {
      setShowTimeoutRetryModal(false);
      setShowExitModal(true);
      const saveAccActivityData = {
        accRqId: submitPostData.accountId,
        data: {
          rejectionReasonCode: '5005',
          requestId: submitPostData.accountId,
          conventionalIndicator: 'Y',
          conventionalStatus: 'PENDING',
        },
      };
      dispatch(patchAccActivity(saveAccActivityData));
    }
  }, [paymentError, debitError]);

  useEffect(() => {
    dispatch(setPaymentEtlList());
    if (paymentProfileCode >= 400) {
      const saveAccActivityData = {
        accRqId: submitPostData.accountId,
        data: {
          rejectionReasonCode: '9007',
          requestId: submitPostData.accountId,
          conventionalIndicator: 'Y',
          conventionalStatus: 'PENDING',
        },
      };
      dispatch(patchAccActivity(saveAccActivityData));
      props.setFlow(conventionalThankyouSubType.sorry);
      dispatch(setThankYouPage(true));
    }
  }, [paymentProfileCode]);

  useEffect(() => {
    dispatch(setPaymentEtlList());
    if (paymentStatus.success === false) {
      if (transactionRetryCount < MAX_RETRY_COUNT) {
        setShowTransactionRetryModal(true);
      } else if (transactionRetryCount >= MAX_RETRY_COUNT) {
        setShowTransactionRetryModal(false);
        setShowExitTransactionErrorModal(true);
        patchActivityTransactionFailed();
      }
    }
  }, [paymentStatus]);

  const redirectToConventionalThankYou = () => {
    const etLocalList: Etl[] = etlList;
    etLocalList.push({ code: '5001', source: 'payment' });
    dispatch(setEtlList(etLocalList));
    const saveAccActivityData = {
      accRqId: submitPostData.accountId,
      data: {
        rejectionReasonCode: '5001',
        requestId: submitPostData.accountId,
        conventionalIndicator: 'Y',
        conventionalStatus: 'PENDING',
      },
    };
    dispatch(patchAccActivity(saveAccActivityData));
    props.setFlow(conventionalThankyouSubType.request);
    dispatch(setThankYouPage(true));
  };

  useEffect(() => {
    if (submitPostData.accountId) {
      callDebitApi();
      if (userTrackId) {
        dispatch(
          postUserTrackId({
            trackingId: userTrackId,
            activityEndPage: pageMapper.payment,
            accReqId: submitPostData.accountId,
          })
        );
      }
    }
  }, [submitPostData.accountId]);

  const callDebitApi = () => {
    const transactionData = {
      currency: ixopayCurrency,
      merchantTransactionId: submitPostData.accountId,
      description: payment.PAYMENT_GATEWAY_TRANSACTION_DESCRIPTION, // description is required for worldpay.
      withRegister: true,
      customer: {
        firstName,
        lastName,
        billingCountry: country[0].code,
        ...(country[0].code === 'US' && {
          billingAddress1: company.bAddress.addressLine1[0],
          billingAddress2: company.bAddress.addressLine2,
          billingCity: company.bAddress.city,
          billingState: company.bAddress.state,
          billingPostcode: company.bAddress.zipCode,
        }),
        ...(country[0].code === 'ZA' && {
          billingPhone: `${contact.number}${contact.ext ? `-${contact.ext}` : ''}`,
        }),
        company: companyName,
        email,
        emailVerified: false,
      },
      customerProfileData: {
        customerIdentification: email,
        markAsPreferred: true,
      },
      paymentRetry: Math.floor(Math.random() * 1000).toString(),
      language: selectedLanguage,
    };
    dispatch(getDebit(transactionData));
  };

  const callPaymentProfile = () => {
    const profileData = {
      customerIdentification: email,
      billingCountryCode: country[0].code,
      accountRequestId: submitPostData.accountId,
      makeDebitCall: true,
      amount: payment.TRANSACTION_AMOUNT,
      currency: ixopayCurrency,
      description: payment.PAYMENT_GATEWAY_TRANSACTION_DESCRIPTION,
    };
    dispatch(getPaymentProfile(profileData));
  };

  const callPaymentStatus = () => {
    const profileData = {
      uuid: debit.uuid,
      billingCountryCode: country[0].code,
      accountRequestId: submitPostData.accountId,
    };
    dispatch(getPaymentStatus(profileData));
  };

  const retryIXOIframe = () => {
    setShowIXOIframeRetryModal(false);
    dispatch(setPaymentProp('iframeRetryCount', iframeRetryCount + 1));
    callDebitApi();
  };

  const retryTransaction = () => {
    setShowTransactionRetryModal(false);
    dispatch(setPaymentProp('transactionRetryCount', transactionRetryCount + 1));
    callDebitApi();
  };

  const retryTimeout = () => {
    setShowTimeoutRetryModal(false);
    dispatch(setPaymentProp('timeoutRetryCount', timeoutRetryCount + 1));
    callDebitApi();
  };

  const exitRegistration = () => {
    setShowExitModal(false);
    setShowExitTransactionErrorModal(false);
    props.setFlow(conventionalThankyouSubType.sorry);
    dispatch(setThankYouPage(true));
  };

  const retryIframeTimeoutModal = () => {
    setShowIframeTimeoutModal(false);
    dispatch(setPaymentProp('iframeTimeoutCount', iframeTimeoutCount + 1));
    callDebitApi();
  };

  const patchActivityTransactionFailed = () => {
    const saveAccActivityData = {
      accRqId: submitPostData.accountId,
      data: {
        rejectionReasonCode: '5006',
        requestId: submitPostData.accountId,
        conventionalIndicator: 'Y',
        conventionalStatus: 'PENDING',
      },
    };
    dispatch(patchAccActivity(saveAccActivityData));
  };

  const callIframeNotLoadedStatus = () => {
    const iframeData = {
      merchantTransactionId: submitPostData.accountId,
      iframeLoaded: false,
    };
    dispatch(setPaymentLoadout(iframeData));
  };

  const callIframeLoadedStatus = () => {
    const iframeData = {
      merchantTransactionId: submitPostData.accountId,
      iframeLoaded: true,
    };
    dispatch(setPaymentLoadout(iframeData));
  };

  const getIxopayErrorMessage = (code) => {
    const errorMessage = code in payment.error.ixopay ? payment.error.ixopay[code] : payment.error.ixopay.default;

    return replace(payment.modal.ERROR_BODY, '{{ErrorMessage}}', errorMessage);
  };

  const urlDomain = process.env.REACT_APP_URL;

  return (
    <Row>
      <Col className="py-5 px-0">
        <Row>
          <Col xs={{ span: 12 }}>
            <div className="m-0">
              <h2 className="payment-header">{payment.PAYMENT_TITLE}</h2>
            </div>
            <p className="payment-details">
              {payment[clientId?.toLowerCase()]?.PAYMENT_SUBTITLE ?? payment.PAYMENT_SUBTITLE}
            </p>
          </Col>
        </Row>
        <Row>
          <div className="iframe-container">
            {(loadingSubmitData || loadingDebit || loadingProfileSucess || !loadingIframe) && (
              <div className="iframe-spinner">
                <Spinner
                  animation="border"
                  variant="warning"
                />
              </div>
            )}
            {ixopayIframe && (
              <Col>
                <iframe
                  id="iframe_id"
                  title="IXOPAY_IFRAME"
                  width="100%"
                  height={isMobileDevice ? IXOPAY_MOBILE_IFRAME_HEIGHT ?? '600px' : IXOPAY_IFRAME_HEIGHT ?? '400px'}
                  scrolling="no"
                  src={redirectUrl}
                  onLoad={() => dispatch(setPaymentProp('loadingIframe', true))}
                ></iframe>
              </Col>
            )}
          </div>
        </Row>
        {paymentCard && paymentProfile.success && (
          <>
            <Alert
              show={showAlert}
              variant="popout-success"
              onClose={() => setShowAlert(false)}
              dismissible
            >
              <img
                alt=""
                src={checkMarkImg}
              />
              <div className="ms-2">{payment.PAYMENT_CARD_SUCCESS}</div>
            </Alert>
            {cardDetails && cardDetails[0]?.paymentData && (
              <div className="pay-card">
                <PaymentPanel
                  cardImg={`${urlDomain}/assets/ixopay/${
                    cardDetails[0]?.paymentData?.brand ?? paymentProfile?.preferredMethod
                  }.png`}
                  cardType={
                    cardDetails[0]?.paymentData?.brand === null
                      ? paymentProfile?.preferredMethod
                      : payment.brand[cardDetails[0]?.paymentData?.brand] !== undefined
                      ? payment.brand[cardDetails[0]?.paymentData?.brand]
                      : [cardDetails[0]?.paymentData?.brand]
                  }
                  lastFourDigits={cardDetails[0]?.paymentData?.lastFourDigits}
                  paymentMethod={payment[cardDetails[0]?.method]}
                />
              </div>
            )}
          </>
        )}
        <BasicModal
          show={showIXOIframeRetryModal}
          title={payment.modal.RETRY_TITLE}
          content={payment.modal.RETRY_MESSAGE}
          action1Title={payment.modal.RETRY_BUTTON}
          action1Handler={retryIXOIframe}
        />
        <BasicModal
          show={showTransactionRetryModal}
          title={payment.modal.ERROR_TITLE}
          content={getIxopayErrorMessage(errorCode)}
          action1Title={payment.modal.ERROR_BUTTON}
          action1Handler={retryTransaction}
        />
        <BasicModal
          show={showTimeoutRetryModal}
          title={payment.modal.TIMOUT_TITLE}
          content={payment.modal.TIMOUT_BODY}
          action1Title={payment.modal.TIMOUT_BUTTON}
          action1Handler={retryTimeout}
        />
        <BasicModal2
          show={showExitModal}
          title={payment.modal.EXIT_TITLE}
          content={payment.modal.EXIT_BODY}
          actions={[{ type: 'secondary', name: payment.modal.EXIT_BUTTON }]}
          actionHandler={exitRegistration}
        />
        <BasicModal2
          show={showExitTransactionErrorModal}
          title={payment?.modal?.ERROR_EXIT_TITLE}
          content={payment?.modal?.ERROR_EXIT_BODY}
          actions={[{ type: 'secondary', name: payment?.modal?.ERROR_EXIT_BUTTON }]}
          actionHandler={exitRegistration}
        />
        <div className="row">
          <div className="col-md-6 mt-4 prevBtn order-sm-1 order-md-0">
            {!paymentInstruments?.length && (
              <Button
                variant="link"
                aria-label={accessibility?.label?.ADD_PAYMENT_LATER}
                className="payment-later-link"
                onClick={clickToShowAddLaterModal}
              >
                {payment.BUTTON_PAYMENT_ADD_LATER}
              </Button>
            )}
          </div>
          <div className="col-md-6 mt-4 nextBtn">
            <Button
              className="px-4 py-2"
              aria-label={
                !paymentInstruments?.length
                  ? accessibility?.label?.NEXT_BUTTON_DISABLED
                  : accessibility?.label?.NEXT_BUTTON_ENABLED
              }
              disabled={!paymentInstruments?.length}
              onClick={props.nextPage}
            >
              {payment.BUTTON_PAYMENT_NEXT}
            </Button>
            <AddLaterModal
              showModal={showAddLaterModal}
              handleClose={closeAddLaterModal}
              addLater={redirectToConventionalThankYou}
            />
          </div>
        </div>
        <BasicModal2
          show={showIframeTimeoutModal}
          title={payment?.modal?.IFRAME_TIMEOUT_TITLE}
          content={payment?.modal?.IFRAME_TIMEOUT_MESSAGE}
          actions={[{ type: 'secondary', name: payment?.modal?.IFRAME_TIMEOUT_BUTTON }]}
          actionHandler={retryIframeTimeoutModal}
        />
      </Col>
    </Row>
  );
}
export default PaymentPage;
