import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import _ from 'lodash';

import preferredSuccessImg from '../../../assets/icons/success-tick.svg';
import infoIcon from '../../../assets/icons/icon_info_solid.svg';
import { convertBase64toBlob } from '../../../utils/helper';
import {
  OPTION_FLOW_3,
  pageMapper,
  conventionalThankyouSubType,
  shippingCardDocType,
  paymentExitTypes,
} from '../../../utils/constants';
import ShippingCard from './ShippingCard/ShippingCard';
import { Alert, Button, Checkbox, BasicModal2, FormikControl } from '../../common';
import {
  getSummaryStatus,
  getRatesServiceDocs,
  getSaveConsent,
  getCustomerDetailsFlowTwo,
  patchAccActivity,
  setOssTimeout,
  getDocsRegenerate,
  setShippingProp,
} from '../../../store/actions/shippingActions';
import { setThankYouPage } from '../../../store/actions/corporateAccountActions';
import spinnerImg from '../../../assets/icons/spinner.gif';
import rateImg from '../../../assets/icons/rate_card.png';
import imgServices from '../../../assets/icons/services.png';
import documents from '../../../assets/icons/documents.svg';
import checkMark from '../../../assets/icons/checkmark_circle_green.svg';
import './ShippingPage.css';
import { postUserTrackId, setEtlList } from '../../../store/actions/appActions';
import { Etl } from '../../../utils/types';
import { setOptionFlow } from '../../../store/actions/daoActions';

function ShippingPage(props) {
  const dispatch = useDispatch<any>();
  const { etlList, appTimers } = useSelector((state: any) => state.app);
  const { submitPostData } = useSelector((state: any) => state.DAOReducer);
  const {
    summaryStatus,
    customerDetails,
    shippingRatestServiceDocs,
    loadingShippingRatesServiceDocs,
    serviceDocs,
    docsRegenerate,
    documentLanguage,
    documentLanguages,
    serviceRateDocuments,
    documentsGenerateErrorCode,
  } = useSelector((state: any) => state.shipping);
  const { translations } = useSelector((state: any) => state.account);
  const {
    shipping,
    accessibility,
    common: { select },
  } = translations;
  const { spinner, tooltip } = shipping;
  const { shippingTimeout, shippingOssTimeout, paymentMethodType } = appTimers;
  const { conventionalIndicator } = customerDetails;
  const { completionPercentage, esignUrl } = summaryStatus;
  const { optionFlow } = useSelector((state: any) => state.DAOReducer);
  const { countryConfigData } = useSelector((state: any) => state.company);
  const showRepresentativeBtn =
    countryConfigData.ctryCfgs.find((e) => e.typeCd === 'SHIPPING_SKIP_SHOW')?.value === 'Y';

  const [showAlert, setShowAlert] = useState(false);
  const [percentage, setPercentage] = useState('0');
  const [consent, setConsent] = useState(false);
  const [shippingModal, setShippingModal] = useState(false);
  const [timeoutModal, setTimeoutModal] = useState(false);
  const [ossTimeoutModal, setOssTimeoutModal] = useState(false);
  const { userTrackId } = useSelector((state: any) => state.corporateAccount);
  const [ossRedirect, setOssRedirect] = useState(false);
  const summaryStatusIntervalRef = useRef<any>();
  const generalTimeoutRef = useRef<any>();
  const [searchParams] = useSearchParams();
  const packageStatus = searchParams.get('status');
  const [documentOption, setDocumentOption] = useState('');

  useEffect(() => {
    if (customerDetails.flowType) {
      const daoFlow = {
        ctryCfgs: [
          {
            typeCd: 'OPTION_FLOW',
            value: customerDetails.flowType,
          },
        ],
      };
      dispatch(setOptionFlow(daoFlow));
      setOssRedirect(true);
    } else {
      generalTimeoutRef.current = setTimeout(() => {
        setTimeoutModal(true);
      }, shippingTimeout);

      return () => clearTimeout(generalTimeoutRef.current);
    }
  }, []);

  useEffect(() => {
    if (userTrackId && submitPostData.accountId) {
      dispatch(
        postUserTrackId({
          trackingId: userTrackId,
          activityEndPage: pageMapper.shipping,
          accReqId: submitPostData.accountId,
        })
      );
    }
  }, [submitPostData.accountId]);

  useEffect(() => {
    if (serviceDocs && optionFlow === OPTION_FLOW_3) {
      setShowAlert(true);
      setTimeout(() => setShowAlert(false), 5000);
    }
  }, [serviceDocs]);

  useEffect(() => {
    submitPostData?.conventional && redirectConvFlow();
    if (submitPostData.accountId) {
      summaryStatusIntervalRef.current = setInterval(() => {
        if (percentage !== '100') {
          dispatch(getSummaryStatus(submitPostData.accountId));
        }
      }, 2000);

      return () => clearInterval(summaryStatusIntervalRef.current);
    }
  }, [submitPostData, percentage]);

  useEffect(() => {
    if (summaryStatus) {
      setPercentage(completionPercentage ?? '0');
      if (completionPercentage === '100') {
        clearInterval(summaryStatusIntervalRef.current);
        clearTimeout(generalTimeoutRef.current);
        if (esignUrl) {
          window.addEventListener('message', receiveMessage, false);
        } else {
          getCustomerDetails();
        }
      }
    }
  }, [summaryStatus]);

  useEffect(() => {
    if (packageStatus === 'PACKAGE_DECLINE') {
      declineOffer('4001');
      props.setFlow(conventionalThankyouSubType.request);
      dispatch(setThankYouPage(true));
    } else {
      const conventional = conventionalIndicator && conventionalIndicator === 'Y';
      const nonConventional = conventionalIndicator === 'N' || conventionalIndicator === null;
      if (conventional) {
        props.setFlow(conventionalThankyouSubType.sorry);
        dispatch(setThankYouPage(true));
      } else if (nonConventional) {
        dispatch(getRatesServiceDocs(customerDetails.requestId));
      }
    }
  }, [conventionalIndicator, customerDetails]);

  const getCustomerDetails = () => {
    dispatch(getCustomerDetailsFlowTwo(submitPostData.accountId));
  };

  const receiveMessage = (event) => {
    const origin = event.origin || event.originalEvent.origin;
    const data = event.data;
    switch (data) {
      case 'ESL:MESSAGE:REGISTER':
        event.source.postMessage('ESL:MESSAGE:ACTIVATE_EVENTS', origin);
        setTimeout(() => {
          dispatch(setOssTimeout(true));
          setOssTimeoutModal(true);
        }, shippingOssTimeout);
        break;
      case 'ESL:MESSAGE:SUCCESS:SIGNER_COMPLETE':
        event.source.postMessage(data, origin);
        window.removeEventListener('message', receiveMessage, false);
        getCustomerDetails();
        break;
      case 'ESL:MESSAGE:SUCCESS:PACKAGE_DECLINE':
        event.source.postMessage(data, origin);
        window.removeEventListener('message', receiveMessage, false);
        declineOffer('4001');
        props.setFlow(conventionalThankyouSubType.request);
        dispatch(setThankYouPage(true));
        break;
      default:
        event.source.postMessage(data, origin);
        break;
    }
  };

  const callSaveConsent = () => {
    const saveConsentData = {
      accRqId: customerDetails.requestId,
      dhlCountryCd: customerDetails.customer.dhlCountryCd,
      email: customerDetails.customer.email,
      // TODO: As discussed with team, keep now static data
      consentCategory: 'SC',
      clientId: customerDetails.sourceApp,
    };
    dispatch(getSaveConsent(saveConsentData));
  };

  const renderShippingCards = () => {
    if (shippingRatestServiceDocs) {
      const rateDocLists = shippingRatestServiceDocs?.docs?.filter((doc) => doc.docSubType === shippingCardDocType.rca);

      const serviceDocLists = shippingRatestServiceDocs?.docs?.filter(
        (doc) => doc.docSubType === shippingCardDocType.acc
      );

      return (
        <>
          <div className="row space-between field-row">
            {serviceDocs.rsa && (
              <div className="col-md-6 mb-4">
                <ShippingCard
                  borderless={true}
                  img={rateImg}
                  alt={shipping.RATE_IMG_ALT}
                  label={shipping.RATE_LABEL}
                  langLists={rateDocLists.map((rate) => {
                    const rateBlob = convertBase64toBlob(rate?.docContent);
                    const ratePdf = URL.createObjectURL(rateBlob);

                    return {
                      link: ratePdf,
                      linkLabel:
                        shipping?.VIEW_IN_LANG !== undefined
                          ? shipping.VIEW_IN_LANG[rate.docLangDesc.toUpperCase()] !== undefined
                            ? shipping.VIEW_IN_LANG[rate.docLangDesc.toUpperCase()]
                            : rate.docLangDesc
                          : rate.docLangDesc,
                    };
                  })}
                />
              </div>
            )}
            {(serviceDocs.off || serviceDocs.acc) && (
              <div className="col-md-6 mb-4">
                <ShippingCard
                  borderless={true}
                  img={imgServices}
                  alt={shipping.SERVICE_IMG_ALT}
                  label={shipping.SERVICE_LABEL}
                  langLists={serviceDocLists.map((service) => {
                    const serviceblob = convertBase64toBlob(service?.docContent);
                    const servicePdf = URL.createObjectURL(serviceblob);

                    return {
                      link: servicePdf,
                      linkLabel:
                        shipping?.VIEW_IN_LANG !== undefined
                          ? shipping.VIEW_IN_LANG[service.docLangDesc.toUpperCase()] !== undefined
                            ? shipping.VIEW_IN_LANG[service.docLangDesc.toUpperCase()]
                            : service.docLangDesc
                          : service.docLangDesc,
                    };
                  })}
                />
              </div>
            )}
          </div>
        </>
      );
    }

    return null;
  };

  const redirectConvFlow = () => {
    props.setFlow(conventionalThankyouSubType.sorry);
    dispatch(setThankYouPage(true));
  };

  const nextPage = () => {
    callSaveConsent();
    props.nextPage();
    if (paymentExitTypes.includes(paymentMethodType)) {
      props.handlePage('summary');
    }
  };

  const declineOffer = (code) => {
    const etLocalList: Etl[] = etlList;
    etLocalList.push({ code, source: 'shippingrates' });
    dispatch(setEtlList(etLocalList));
    const saveAccActivityData = {
      accRqId: submitPostData.accountId ?? customerDetails.requestId,
      data: {
        rejectionReasonCode: code,
        requestId: submitPostData.accountId ?? customerDetails.requestId,
        conventionalIndicator: 'Y',
        conventionalStatus: 'PENDING',
      },
    };
    dispatch(patchAccActivity(saveAccActivityData));
  };

  const modalBtnHandler = (name) => {
    switch (name) {
      case shipping.modal.BTN_REVIEW:
        setShippingModal(false);
        break;
      case shipping.modal.BTN_REPRESENTATIVE:
        declineOffer('4003');
        props.setFlow(conventionalThankyouSubType.request);
        dispatch(setThankYouPage(true));
        break;
      case shipping.modal.TIMEOUT_BTN:
        declineOffer('9010');
        redirectConvFlow();
        break;
      case shipping.modal.OSS_TIMEOUT_BTN:
        setOssTimeoutModal(false);
        dispatch(setThankYouPage(true));
        break;
    }
  };

  const documentOptionsValues = documentLanguages?.filter(
    (docLang) => !serviceRateDocuments?.some((serviceRateDoc) => docLang.value === serviceRateDoc.value)
  );

  const docLangOptionList = documentOptionsValues.map((obj) => ({
    ...obj,
    label:
      shipping?.doclang !== undefined
        ? shipping.doclang[obj.label.toUpperCase()] !== undefined
          ? shipping.doclang[obj.label.toUpperCase()]
          : obj.label
        : obj.label,
  }));

  const languageCodeValue = docLangOptionList.find((code) => code.value === documentLanguage);

  const documentOptions = [{ value: '', label: '' }, ...docLangOptionList];

  const preferredLangChangeHandler = (e) => {
    setDocumentOption(e.target.value);
    const documentLanguage = e.target.value;
    dispatch(setShippingProp('documentLanguage', documentLanguage));
  };

  useEffect(() => {
    if (documentOptions?.length === 1) {
      dispatch(setShippingProp('documentLanguage', documentOptions[0]?.label));
    }
  }, [shippingRatestServiceDocs]);

  const rateDocsRegenerate = () => {
    const docsRegenerateData = {
      accountReqId: submitPostData.accountId,
      dhlTrackingId: userTrackId,
      docLang: documentLanguage,
      flowType: customerDetails.flowType,
      languageCd: languageCodeValue.languageCd,
    };
    dispatch(getDocsRegenerate(docsRegenerateData));
    setPercentage('0');
    dispatch(setShippingProp('serviceDocs', ''));
    setDocumentOption('');
  };

  useEffect(() => {
    if (documentsGenerateErrorCode >= 400) {
      const saveAccActivityData = {
        accRqId: submitPostData.accountId,
        data: {
          rejectionReasonCode: '9014',
          requestId: submitPostData.accountId,
          conventionalIndicator: 'Y',
          conventionalStatus: 'PENDING',
        },
      };
      dispatch(patchAccActivity(saveAccActivityData));
      redirectConvFlow();
    }
  }, [documentsGenerateErrorCode]);

  return (
    <Row className="shipping-rates">
      <Col xs={12}>
        <Alert
          show={showAlert}
          variant="popout-success"
          onClose={() => setShowAlert(false)}
          dismissible
        >
          <img
            alt=""
            src={checkMark}
          />
          <div className="ms-2">{shipping.alert.SUCCESS}</div>
        </Alert>
        <h1 className="m-0">
          <p className="content-title">{shipping.SHIPPING_TITLE}</p>
        </h1>
        <p className="content-subtitle">{shipping.SHIPPING_SUBTITLE}</p>

        {serviceDocs && !_.isEmpty(docsRegenerate) && (
          <Row>
            <Col>
              <div className="shipping-message mb-4">
                <div className="vertical-center">
                  <img
                    alt="Card Success"
                    src={preferredSuccessImg}
                    className="d-table-cell"
                  />
                </div>
                <div className="message-text success">{shipping.GENERATE_DOCUMENTS_SUCCESS_MSG}</div>
              </div>
            </Col>
          </Row>
        )}
        {((percentage !== '100' && !ossRedirect) ||
          (optionFlow !== OPTION_FLOW_3 && !esignUrl && !serviceDocs) ||
          loadingShippingRatesServiceDocs) && (
          <div className="spinner-card">
            <div className={`${optionFlow === OPTION_FLOW_3 && 'onespan-card'}`}>
              {optionFlow === OPTION_FLOW_3 && (
                <img
                  src={documents}
                  alt={spinner.IMG_DOC_ALT}
                />
              )}
              <h5 className="spinner-title">{spinner.MAIN}</h5>
              {optionFlow === OPTION_FLOW_3 && <p className="spinner-text">{spinner.SUB_OS}</p>}
              <img
                className="spinner-img"
                src={spinnerImg}
                alt={spinner.IMG_ALT}
              />
              <p className="shipping-spinner">
                <span className="shipping-spinner-text">{spinner.SUB}</span> <span>{percentage}%...</span>
              </p>
            </div>
          </div>
        )}
        {(percentage === '100' || ossRedirect) && (
          <>
            {esignUrl && !serviceDocs && !loadingShippingRatesServiceDocs && (
              <div className="spinner-card">
                <iframe
                  id="iframe_id"
                  title="IXOPAY_IFRAME"
                  width="100%"
                  height="700px"
                  scrolling="yes"
                  src={esignUrl}
                ></iframe>
              </div>
            )}
            {!loadingShippingRatesServiceDocs && serviceDocs && (
              <>
                <Container className="shipping-rates-container">
                  <div className="shipping-download-docs">
                    {renderShippingCards()}
                    <Row>
                      <Col xs={12}>
                        <div className="shipping-message">
                          <div>
                            <img
                              alt="Download your documents info"
                              src={infoIcon}
                              className="d-table-cell"
                            />
                          </div>
                          <div className="message-text text-grey">{shipping.DOWNLOAD_INFO}</div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {documentOptions.length !== 1 && (
                    <div className="shipping-preferred-section">
                      <Row>
                        <Col xs={12}>
                          <h6 className="fw-bold">{shipping.GENERATE_DOCUMENTS}</h6>
                          <p>{shipping.GENERATE_DOCUMENTS_MESSAGE}</p>
                          <Formik
                            initialValues={{ documentLanguage }}
                            onSubmit={rateDocsRegenerate}
                          >
                            <Form>
                              <FormikControl
                                control="select"
                                name="documentLanguage"
                                ariaLabel={shipping.PREFERRED_DOCUMENTS_LANGUAGE}
                                label={shipping.PREFERRED_DOCUMENTS_LANGUAGE}
                                placeholder={select.INITIAL_VALUE}
                                options={documentOptions}
                                onChange={preferredLangChangeHandler}
                                {...(tooltip?.PREFERRED_DOCUMENTS_LANGUAGE && {
                                  fieldInfo: { info: tooltip.PREFERRED_DOCUMENTS_LANGUAGE },
                                })}
                              />
                              <div className="shipping-preferred-button">
                                <Button
                                  type="submit"
                                  variant="secondary"
                                  className="px-4 py-2 mt-4"
                                  disabled={!documentOption}
                                >
                                  {shipping.PREFERRED_LANGUAGE_BUTTON}
                                </Button>
                              </div>
                            </Form>
                          </Formik>
                        </Col>
                      </Row>
                    </div>
                  )}
                </Container>
                <Row className="mt-4">
                  <Col>
                    <Checkbox
                      id="shippingConsent"
                      inputClass="form-check-input custom-checkbox"
                      labelClass="form-check-label"
                      checked={consent}
                      onChange={(e) => setConsent(e.target.checked)}
                      label={shipping.CHECKBOX_LABEL}
                      mandatoryLabel="*"
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
        <div className="row">
          <div className="col-md-6 mt-4 prevBtn order-sm-1 order-md-0">
            {showRepresentativeBtn && (
              <Button
                variant="link"
                className="shipping-rates-footer-link"
                onClick={() => setShippingModal(true)}
              >
                {shipping.BUTTON_REPRESENTATIVE}
              </Button>
            )}
          </div>
          <div className="col-md-6 mt-4 nextBtn">
            <Button
              variant="primary"
              aria-label={
                !consent ? accessibility?.label?.NEXT_BUTTON_DISABLED : accessibility?.label?.NEXT_BUTTON_ENABLED
              }
              className="px-4"
              disabled={!consent}
              onClick={nextPage}
            >
              {shipping.BUTTON_NEXT}
            </Button>
          </div>
        </div>
        <BasicModal2
          show={shippingModal}
          title={shipping.modal.TITLE}
          content={shipping.modal.BODY}
          actions={[
            { type: 'link', name: shipping.modal.BTN_REPRESENTATIVE },
            { type: 'secondary', name: shipping.modal.BTN_REVIEW },
          ]}
          actionHandler={modalBtnHandler}
        />
        <BasicModal2
          show={timeoutModal}
          title={shipping.modal.TIMEOUT_TITLE}
          content={shipping.modal.TIMEOUT_BODY}
          actions={[{ type: 'secondary', name: shipping.modal.TIMEOUT_BTN }]}
          actionHandler={modalBtnHandler}
        />
        <BasicModal2
          show={ossTimeoutModal}
          title={shipping.modal.OSS_TIMEOUT_TITLE}
          content={shipping.modal.OSS_TIMEOUT_BODY}
          actions={[{ type: 'secondary', name: shipping.modal.OSS_TIMEOUT_BTN }]}
          actionHandler={modalBtnHandler}
        />
      </Col>
    </Row>
  );
}

export default ShippingPage;
