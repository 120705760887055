import React from 'react';
import { Modal, Button } from '../../common';
import { useSelector } from 'react-redux';

function RetrieveCompanyInfoModal({ show, retrieveCompanyInfoModal }) {
  const { translations } = useSelector((state: any) => state.account);
  const {
    company: { modal },
  } = translations;

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered={true}
        className="custom-modal"
      >
        <Modal.Header>
          <Modal.Title>{modal?.RETRIEVE_TITLE}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal?.RETRIEVE_BODY}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            onClick={() => retrieveCompanyInfoModal(true)}
            className="custom-modal-link"
          >
            {modal?.RETAIN_BUTTON}
          </Button>
          <Button
            variant="secondary"
            onClick={() => retrieveCompanyInfoModal(false)}
          >
            {modal?.RETRIEVE_BUTTON}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RetrieveCompanyInfoModal;
