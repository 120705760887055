import React, { useRef } from 'react';
import { Field } from 'formik';
import { Form } from 'react-bootstrap';
import { Hint, AsyncTypeahead } from 'react-bootstrap-typeahead';
import './AsyncTypeahead.css';
import Tooltip from '../Tooltip/Tooltip';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import { directionLayout } from '../../../utils/constants';

type TFieldInfo = {
  info: string;
  placement: any;
};

type TAsyncTypeahead = {
  label: string;
  mandatoryLabel?: string;
  name: string;
  type: string;
  labelKey: string;
  placeholder: string;
  options: [];
  filterBy?: [];
  delay?: number;
  minLength?: number;
  isLoading?: boolean;
  searchText?: string;
  checkMark?: boolean;
  ariaLabel?: string;
  fieldInfo?: TFieldInfo;
  validate: (value) => {};
  onSearch: (q) => {};
  onChange?: (e) => {};
  onInputChange?: (e) => {};
  menuItemChildren?: () => {};
};

const FormikAsyncTypeahead = (props: TAsyncTypeahead) => {
  const {
    label,
    mandatoryLabel,
    name,
    type,
    placeholder,
    labelKey,
    filterBy = ['name', 'code'],
    delay = 200,
    minLength = 3,
    isLoading = false,
    searchText = 'Searching',
    checkMark = false,
    ariaLabel,
    fieldInfo,
    onSearch,
    onChange,
    onInputChange,
    menuItemChildren,
    options,
    validate,
    ...rest
  } = props;

  const fromRef = useRef('');
  const isMediumDevice = useMediaQuery('only screen and (min-width : 320px) and (max-width : 767px)');

  return (
    <Field
      name={name}
      validate={validate}
    >
      {({ field, form }) => {
        const { errors, touched, setFieldValue, setFieldTouched } = form;
        const { value } = field;

        const error = name.split('.').reduce((acc, current) => acc && acc[current], errors);
        const touch = name.split('.').reduce((acc, current) => acc && acc[current], touched);

        const renderFieldInfo = () => {
          if (!fieldInfo || !fieldInfo?.info) {
            return null;
          }

          return (
            <Tooltip
              info={fieldInfo.info}
              placement={
                isMediumDevice
                  ? 'bottom'
                  : document.documentElement.dir === directionLayout.ltr
                  ? 'right'
                  : document.documentElement.dir === directionLayout.rtl
                  ? 'left'
                  : 'right'
              }
            />
          );
        };

        return (
          <>
            <AsyncTypeahead
              id={name}
              multiple={false}
              options={options}
              labelKey={labelKey}
              filterBy={filterBy}
              delay={delay}
              minLength={minLength}
              isLoading={isLoading}
              searchText={searchText}
              onSearch={onSearch}
              align="left"
              placeholder={placeholder}
              {...rest}
              {...field}
              onChange={(value) => {
                setFieldValue(name, value);
                if (onChange) onChange(value);
              }}
              onInputChange={(text) => {
                fromRef.current = text;
                if (onInputChange) onInputChange(text);
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13 || (e.keyCode === 188 && value.length === 0 && fromRef.current)) {
                  setFieldValue(name, [fromRef.current]);
                }
              }}
              onBlur={() => {
                setFieldTouched(name, true);
                if (value.length === 0 && fromRef.current) {
                  setFieldValue(name, [fromRef.current]);
                }
              }}
              renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
                <Hint>
                  <Form.Floating>
                    <Form.Control
                      {...inputProps}
                      ref={(node) => {
                        inputRef(node);
                        referenceElementRef(node);
                      }}
                      className="async-typeahead"
                      type="text"
                      autoComplete="new-password"
                      aria-label={ariaLabel}
                      title={label + (mandatoryLabel ?? '')}
                      value={inputProps.value as string[]}
                      isValid={checkMark && !!value.length && !error}
                      isInvalid={!!error && !!touch}
                      id={name}
                    />
                    <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                    <label
                      htmlFor={name}
                      className="field-label"
                    >
                      {label} <span className="legal-id-mandatory">{mandatoryLabel}</span>
                      {renderFieldInfo()}
                    </label>
                  </Form.Floating>
                </Hint>
              )}
              renderMenuItemChildren={menuItemChildren}
              selected={value}
            />
          </>
        );
      }}
    </Field>
  );
};

export default FormikAsyncTypeahead;
