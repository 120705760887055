import { UPDATE_CUSTOMER_CONSENT, SET_CUSTOMER_CONSENT, SET_REVIEW_ETL_LIST } from './actionTypes';

export const updateCustomerConsent = (key, value) => {
  return {
    type: UPDATE_CUSTOMER_CONSENT,
    payload: { key, value },
  };
};

export const setCustomerConsent = (key, value) => {
  return {
    type: SET_CUSTOMER_CONSENT,
    payload: { key, value },
  };
};

export const setReviewEtlList = (breDetails) => {
  return {
    type: SET_REVIEW_ETL_LIST,
    payload: breDetails,
  };
};
