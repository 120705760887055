import {
  GET_SUMMARY_STATUS,
  SET_SUMMARY_STATUS,
  GET_RATES_SERVICE_DOCS,
  SET_RATES_SERVICE_DOCS,
  GET_SAVE_CONSENT,
  SET_SAVE_CONSENT,
  SET_SERVICE_DOCS,
  GET_CUSTOMER_DETAILS,
  SET_CUSTOMER_DETAILS,
  GET_CUSTOMER_DETAILS_FLOW_TWO,
  SET_CUSTOMER_DETAILS_FLOW_TWO,
  SET_OSS_TIMEOUT,
  PATCH_ACC_ACTIVITY,
  GET_DOCS_REGENERATE,
  SET_SHIPPING_PROP,
  SET_DOCUMENTS_GENERATE_ERROR_CODE,
} from './actionTypes';

export const getSummaryStatus = (request) => {
  return {
    type: GET_SUMMARY_STATUS,
    payload: request,
  };
};

export const setSummaryStatus = (response) => {
  return {
    type: SET_SUMMARY_STATUS,
    payload: response,
  };
};

export const getCustomerDetails = (request) => {
  return {
    type: GET_CUSTOMER_DETAILS,
    payload: request,
  };
};

export const setCustomerDetails = (response) => {
  return {
    type: SET_CUSTOMER_DETAILS,
    payload: response,
  };
};

export const getCustomerDetailsFlowTwo = (request) => {
  return {
    type: GET_CUSTOMER_DETAILS_FLOW_TWO,
    payload: request,
  };
};

export const setCustomerDetailsFlowTwo = (response) => {
  return {
    type: SET_CUSTOMER_DETAILS_FLOW_TWO,
    payload: response,
  };
};

export const getRatesServiceDocs = (request) => {
  return {
    type: GET_RATES_SERVICE_DOCS,
    payload: request,
  };
};

export const setRatesServiceDocs = (response) => {
  return {
    type: SET_RATES_SERVICE_DOCS,
    payload: response,
  };
};

export const setServiceDocs = (response) => {
  let rsa;
  let off;
  let acc;

  if (response.docs.length) {
    rsa = response.docs?.find((type) => type.docSubType === 'RCA');
    off = response.docs?.find((type) => type.docSubType === 'OFF');
    acc = response.docs?.find((type) => type.docSubType === 'ACC');
  }

  return {
    type: SET_SERVICE_DOCS,
    payload: {
      rsa,
      off,
      acc,
    },
  };
};

export const getSaveConsent = (request) => {
  return {
    type: GET_SAVE_CONSENT,
    payload: request,
  };
};

export const setSaveConsent = (response) => {
  return {
    type: SET_SAVE_CONSENT,
    payload: response,
  };
};

export const setOssTimeout = (response) => {
  return {
    type: SET_OSS_TIMEOUT,
    payload: response,
  };
};

export const patchAccActivity = (request) => {
  return {
    type: PATCH_ACC_ACTIVITY,
    payload: request,
  };
};

export const getDocsRegenerate = (request) => {
  return {
    type: GET_DOCS_REGENERATE,
    payload: request,
  };
};

export const setShippingProp = (key, value) => {
  return {
    type: SET_SHIPPING_PROP,
    payload: { key, value },
  };
};

export const setDocumentLanguages = (configurations) => {
  const documentLanguages: any = [];

  configurations.ctryCfgs.forEach((ctryCfg) => {
    if (ctryCfg.typeCd === 'DOC_LANGUAGE') {
      documentLanguages.push({
        value: ctryCfg.value,
        label: ctryCfg.langDescription,
        languageCd: ctryCfg.relationshipId,
      });
    }
  });

  return {
    type: SET_SHIPPING_PROP,
    payload: { key: 'documentLanguages', value: documentLanguages },
  };
};

export const setDocuments = (serviceRateDocs) => {
  const serviceRateDocuments: any = [];

  serviceRateDocs.docs.forEach((doc) => {
    if (doc.docSubType === 'RCA') {
      serviceRateDocuments.push({
        value: doc.docLang,
        label: doc.docLangDesc,
      });
    }
  });

  return {
    type: SET_SHIPPING_PROP,
    payload: { key: 'serviceRateDocuments', value: serviceRateDocuments },
  };
};

export const setDocumentsGenerateErrorCode = (error) => {
  const documentsGenerateErrorCode = error?.response?.status;

  return {
    type: SET_DOCUMENTS_GENERATE_ERROR_CODE,
    payload: documentsGenerateErrorCode,
  };
};
