import { all, fork } from 'redux-saga/effects';

import appSaga from './appSaga';
import corporateAccountSaga from './corporateAccountSaga';
import accountSaga from './accountSaga';
import contactSaga from './contactSaga';
import companySaga from './companySaga';
import DAOSaga from './daoSaga';
import paymentSaga from './paymentSaga';
import reviewSaga from './reviewSaga';
import shippingSaga from './shippingSaga';

export default function* rootSaga() {
  yield all([
    fork(DAOSaga),
    fork(appSaga),
    fork(accountSaga),
    fork(corporateAccountSaga),
    fork(contactSaga),
    fork(companySaga),
    fork(paymentSaga),
    fork(reviewSaga),
    fork(shippingSaga),
  ]);
}
