import React from 'react';

import './PaymentPanel.css';

type TPaymentPanel = {
  paymentMethod: string;
  cardImg: string;
  cardType: string;
  lastFourDigits: any;
};

const PaymentPanel = ({ cardImg, cardType, lastFourDigits, paymentMethod }: TPaymentPanel) => {
  return (
    <div className="row">
      <div className="col-md-6 my-auto">
        <div className="pay-card-type">
          {paymentMethod} {cardType}
        </div>
      </div>
      <div className="col-md-6 my-auto">
        <div className="pay-card-brand-no">
          <div className="pay-card-img">
            <img
              title={cardImg}
              alt="Brand"
              src={cardImg}
            />
          </div>
          {lastFourDigits && (
            <div className="pay-card-no">
              <div className="pay-card-no-mask"></div>
              <div className="pay-card-no-mask"></div>
              <div className="pay-card-no-mask"></div>
              <div className="card-vert-center">{lastFourDigits}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentPanel;
