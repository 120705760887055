import React from 'react';
import { Accordion as RAccordion } from 'react-bootstrap';

import './Accordion.css';

const Accordion = ({ list }) => {
  const renderAccordionChild = () => {
    const accordionChild = Object.keys(list).map((key, index) => (
      <div
        className="accordion-list"
        key={key}
      >
        {list[key].question && (
          <RAccordion.Item
            eventKey={index.toString()}
            key={key}
          >
            <RAccordion.Header>
              <span dangerouslySetInnerHTML={{ __html: list[key].question }} />
            </RAccordion.Header>
            <RAccordion.Body>
              {Object.keys(list[key].answer).map((answerKey) => (
                <div key={answerKey}>
                  {list[key].answer[answerKey].desc && (
                    <p
                      className="mb-0"
                      dangerouslySetInnerHTML={{ __html: list[key].answer[answerKey].desc }}
                    />
                  )}
                  {list[key].answer[answerKey].bullet && (
                    <ul className="mb-0">
                      {Object.keys(list[key].answer[answerKey].bullet).map((bulletKey) => (
                        <div key={bulletKey}>
                          {list[key].answer[answerKey].bullet[bulletKey] && (
                            <li
                              key={bulletKey}
                              dangerouslySetInnerHTML={{ __html: list[key].answer[answerKey].bullet[bulletKey] }}
                            />
                          )}
                        </div>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </RAccordion.Body>
          </RAccordion.Item>
        )}
      </div>
    ));

    return accordionChild;
  };

  return (
    <RAccordion
      alwaysOpen={true}
      flush={true}
      className="custom-accordion"
    >
      {renderAccordionChild()}
    </RAccordion>
  );
};

export default Accordion;
