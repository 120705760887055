import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import contactImg from '../../../assets/icons/contact_black.svg';
import editImg from '../../../assets/icons/edit_red.svg';
import emailImg from '../../../assets/icons/email.svg';
import phoneBookImg from '../../../assets/icons/phone_book.svg';

import './Panel.css';

type TContactPanel = {
  isEditable: boolean;
  onEdit?: () => void;
  content: any;
};

function ContactPanel(props: TContactPanel) {
  const { isEditable, onEdit, content } = props;
  const { translations } = useSelector((state: any) => state.account);
  const { contact, preferredLanguages } = useSelector((state: any) => state.contact);
  const {
    title,
    firstName,
    lastName,
    country,
    email,
    countryCode,
    phoneType,
    number,
    ext,
    job,
    department,
    preferredLanguage,
    preferredCommunicationMethod,
  } = contact;

  const { contact: contactContent } = content;
  const {
    contact: { comm },
    shipping: { doclang },
  } = translations;

  const renderMailLink = () => {
    return 'mailto:' + email;
  };

  const renderEditBtn = () => {
    if (isEditable) {
      return (
        <button
          id="contactEditBtn"
          type="button"
          className="btn edit-btn p-0"
          onClick={onEdit}
        >
          <img
            src={editImg}
            className="edit-icon"
            alt={contactContent?.EDIT_CONTACT_PAGE}
          />
        </button>
      );
    }

    return null;
  };

  return (
    <div className={isEditable ? 'shadow rounded bg-white form-content' : 'col d-flex flex-column flex-nowrap'}>
      <div
        id="contactCard"
        className="card panel-card flex-grow-1"
      >
        <div
          id="contactCardBody"
          className="card-body p-0"
        >
          {renderEditBtn()}
          <div className="d-flex panel-row contact-panel-row">
            <div className="d-flex card-icon-name align-items-center">
              <div className="card-icon">
                <img
                  src={contactImg}
                  alt={contactContent.ICON_ALT}
                />
              </div>
              <div className="name-text">
                {title && `${title} `}
                {firstName} {lastName}
              </div>
            </div>
            <div className="country-text">{country[0]?.name}</div>
          </div>
          <Row className="panel-row">
            <Col className="d-flex align-items-center">
              <img
                src={emailImg}
                alt={contactContent.EMAIL_ICON_ALT}
              />
              <span className="panel-main-text text-break">
                <a
                  className="web-link"
                  href={renderMailLink()}
                >
                  {email}
                </a>
              </span>
            </Col>
          </Row>
          <Row className="panel-row">
            <Col className="d-flex align-items-center">
              <img
                src={phoneBookImg}
                alt={contactContent.MOBILE_ICON_ALT}
              />
              <span className="panel-main-text dir-ltr">
                {countryCode[0]?.callCode}-{number} {ext && `-${ext}`} (
                {translations?.contact?.phoneTypes?.[phoneType] ?? phoneType})
              </span>
            </Col>
          </Row>
          <div className="mt-4 row">
            <div className="col-md-3">
              <div className="optional-header-text">{contactContent.LABEL_JOB_TITLE}</div>
              <div className="contact-field-label">{job || '-'}</div>
            </div>
            <div className="col-md-3">
              <div className="optional-header-text">{contactContent.LABEL_DEPARTMENT}</div>
              <div className="contact-field-label">{department || '-'}</div>
            </div>
            {preferredLanguages?.length > 1 && (
              <div className="col-md-6">
                <div className="optional-header-text">{contactContent.LABEL_PREFERRED_LANGUAGE}</div>
                <div className="contact-field-label">{doclang[preferredLanguage.toUpperCase()]}</div>
              </div>
            )}
            {preferredCommunicationMethod && (
              <div className={`col-md-6 ${preferredLanguages?.length > 1 ? 'mt-4' : ''}`}>
                <div className="optional-header-text">{contactContent.LABEL_PREFERRED_COMM_METHOD}</div>
                <div className="contact-field-label">{comm.method[preferredCommunicationMethod]}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContactPanel;
