import {
  GET_SUMMARY_STATUS,
  SET_SUMMARY_STATUS,
  GET_RATES_SERVICE_DOCS,
  SET_RATES_SERVICE_DOCS,
  GET_SAVE_CONSENT,
  SET_SAVE_CONSENT,
  SET_SERVICE_DOCS,
  GET_CUSTOMER_DETAILS,
  SET_CUSTOMER_DETAILS,
  GET_CUSTOMER_DETAILS_FLOW_TWO,
  SET_CUSTOMER_DETAILS_FLOW_TWO,
  SET_OSS_TIMEOUT,
  SET_SHIPPING_PROP,
  SET_DOCUMENTS_GENERATE_ERROR_CODE,
} from '../actions/actionTypes';

const initialState = {
  loadingSummaryStatus: false,
  summaryStatus: {},
  loadingCustomerDetails: false,
  customerDetails: {},
  loadingShippingRatesServiceDocs: false,
  shippingRatestServiceDocs: {
    docs: [
      {
        accReqId: '',
        docContent: '',
        docSubType: '',
      },
      {
        accReqId: '',
        docContent: '',
        docSubType: '',
      },
    ],
  },
  loadingSaveConsent: false,
  saveConsent: {
    cnsntCat: '',
  },
  serviceDocs: '',
  ossTimeout: false,
  docsRegenerate: {},
  documentLanguage: '',
  documentLanguages: [],
  serviceRateDocuments: [],
  documentsGenerateErrorCode: -1,
};

const shippingReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUMMARY_STATUS: {
      return { ...state, loadingSummaryStatus: true };
    }

    case SET_SUMMARY_STATUS: {
      return { ...state, summaryStatus: action.payload, loadingSummaryStatus: false };
    }

    case GET_CUSTOMER_DETAILS:
    case GET_CUSTOMER_DETAILS_FLOW_TWO: {
      return { ...state, loadingCustomerDetails: true };
    }

    case SET_CUSTOMER_DETAILS:
    case SET_CUSTOMER_DETAILS_FLOW_TWO: {
      return { ...state, customerDetails: action.payload, loadingCustomerDetails: false };
    }

    case GET_RATES_SERVICE_DOCS: {
      return { ...state, loadingShippingRatesServiceDocs: true };
    }

    case SET_RATES_SERVICE_DOCS: {
      return { ...state, shippingRatestServiceDocs: action.payload, loadingShippingRatesServiceDocs: false };
    }

    case SET_SERVICE_DOCS: {
      return { ...state, serviceDocs: action.payload };
    }

    case GET_SAVE_CONSENT: {
      return { ...state, loadingSaveConsent: true };
    }

    case SET_SAVE_CONSENT: {
      return { ...state, saveConsent: action.payload, loadingSaveConsent: false };
    }

    case SET_OSS_TIMEOUT: {
      return { ...state, ossTimeout: action.payload };
    }

    case SET_SHIPPING_PROP:
      return { ...state, [action.payload.key]: action.payload.value };

    case SET_DOCUMENTS_GENERATE_ERROR_CODE: {
      return { ...state, documentsGenerateErrorCode: action.payload };
    }

    default:
      return state;
  }
};

export default shippingReducer;
