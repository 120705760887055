import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { getTranslationsAkamai } from '../../../store/actions/accountActions';
import Accordion from '../../common/Accordion/Accordion';
import Button from '../../common/Button/Button';
import DynamicBackgroundImage from '../../common/DynamicBackgroundImage/DynamicBackgroundImage';
import DynamicImage from '../../common/DynamicImage/DynamicImage';
import { storage } from '../../../utils/helper';
import './FaqPage.css';
import { getCountryConfig } from '../../../store/actions/corporateAccountActions';
import { directionLayout } from '../../../utils/constants';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

function FaqPage() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const countryCode = searchParams.get('country')?.toUpperCase();
  const languageCode = searchParams.get('lang');
  const clientId = searchParams.get('clientid');
  const { countryConfigData } = useSelector((state: any) => state.corporateAccount);
  const { appTimers } = useSelector((state: any) => state.app);
  const { defaultDisplayLanguage } = appTimers;
  const translationQuery = `${
    defaultDisplayLanguage
      ? `${countryCode}/${countryCode}_${languageCode}.json?lngCd=${languageCode}`
      : `DEFAULT.json?lngCd=${languageCode}`
  }`;
  const { translations } = useSelector((state: any) => state.account);
  const { faqs, image, direction } = translations;
  const isDaoCountryString = storage.get('isDaoCountry');
  const isDaoCountry = isDaoCountryString ? JSON.parse(isDaoCountryString) : false;
  const urlDomain = process.env.REACT_APP_URL;
  const isMobileDevice = useMediaQuery('only screen and (min-width : 320px) and (max-width : 767px)');
  const bannerImgFaqSrc =
    isMobileDevice && direction === directionLayout.ltr
      ? 'mobile-'
      : !isMobileDevice && direction === directionLayout.rtl
      ? 'rtl-'
      : isMobileDevice && direction === directionLayout.rtl
      ? 'rtl-mobile-'
      : '';

  useEffect(() => {
    dispatch(getCountryConfig({ ctryCd: countryCode, srcApp: clientId }));
  }, []);

  useEffect(() => {
    if (countryConfigData?.ctryCfgs?.length > 0) {
      dispatch(getTranslationsAkamai(translationQuery));
    }
  }, [countryConfigData]);

  return (
    <>
      <DynamicBackgroundImage
        src={`${urlDomain}/assets/${countryCode}/images/${bannerImgFaqSrc}banner-faq.png?${image?.value}`}
        defaultSrc={`${urlDomain}/assets/DEFAULT/images/${bannerImgFaqSrc}banner-faq.png?${image?.value}`}
        isDaoCountry={isDaoCountry}
        direction={direction}
        className="faq-banner"
      >
        <h1 className="m-0">
          <p className="faq-banner-title">{faqs.banner.title}</p>
        </h1>
        <p className="faq-banner-subtitle">{faqs.banner.subtitle}</p>
      </DynamicBackgroundImage>
      <div className="faq-main">
        <div className="faq-main-content">
          <Accordion list={faqs.faq} />
        </div>
      </div>
      <div className="faq-contact">
        <div className={`faq-contact-content ${!isMobileDevice ? 'd-flex align-items-start' : ''}`}>
          <DynamicImage
            src={`${urlDomain}/assets/${countryCode}/images/${isMobileDevice ? 'mobile-' : ''}faq-contact.png?${
              image?.value
            }`}
            defaultSrc={`${urlDomain}/assets/DEFAULT/images/${isMobileDevice ? 'mobile-' : ''}faq-contact.png?${
              image?.value
            }`}
            isDaoCountry={isDaoCountry}
            alt=""
            class="faq-contact-img"
          />
          <div className={`${!isMobileDevice ? 'd-flex flex-column align-items-start' : ''}`}>
            <div className="faq-contact-title">{faqs.contact.title}</div>
            <div className="faq-contact-subtitle">{faqs.contact.subtitle}</div>
            <Button
              variant="primary"
              className={`faq-contact-btn ${isMobileDevice ? 'w-100' : ''}`}
              href={faqs.contact.btn.link}
              target="_blank"
            >
              {faqs.contact.btn.label}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqPage;
