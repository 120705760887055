import React from 'react';
import { OverlayTrigger, Tooltip as RTooltip } from 'react-bootstrap';

import infoImg from '../../../assets/icons/information.svg';
import './Tooltip.css';

type TTooltip = {
  info: any;
  placement?: any;
  children?: any;
};

const Tooltip = ({ info, placement, children }: TTooltip) => {
  const renderChildren = () => {
    if (!children) {
      return (
        <img
          width="15"
          height="15"
          src={infoImg}
          alt="What does this mean?"
        />
      );
    }

    return children;
  };

  // Handle both string and function types
  const content = typeof info === 'function' ? info() : info;

  return (
    <OverlayTrigger
      overlay={(props) => (
        <RTooltip
          {...props}
          className="custom-tooltip"
        >
          {typeof content === 'string' ? <span dangerouslySetInnerHTML={{ __html: info }} /> : <span>{info}</span>}
        </RTooltip>
      )}
      placement={placement}
    >
      {renderChildren()}
    </OverlayTrigger>
  );
};

export default Tooltip;
