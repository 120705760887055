import React from 'react';
import './PageTitle.css';

const PageTitle = ({ title, subtitle }) => {
  return (
    <>
      <h1 className="m-0">
        <p className="page-title">{title}</p>
      </h1>
      <p className="page-subtitle">{subtitle}</p>
    </>
  );
};

export default PageTitle;
