import React from 'react';

import downloadIcon from '../../../../assets/icons/download_red.svg';
import './ShippingCard.css';
import { useMediaQuery } from '../../../../hooks/useMediaQuery';

function ShippingCard({ img, alt, label, borderless, langLists }) {
  const isMobileDevice = useMediaQuery('only screen and (min-width : 320px) and (max-width : 767px)');

  return (
    <div className={`col d-flex align-items-center shipping-card ${borderless ? 'no-border' : 'shipping-border'}`}>
      <img
        src={img}
        alt={alt}
      />
      <div className="ms-3">
        <p className="shipping-card-label">{label}</p>
        {langLists.map((lang, index) => (
          <div key={index}>
            <a
              className="shipping-card-link"
              target="_blank"
              href={lang.link}
              rel="noreferrer"
            >
              {lang.linkLabel}
            </a>
            <a
              className="shipping-card-link"
              href={lang.link}
              {...(isMobileDevice ? { target: '_blank', rel: 'noopener noreferrer' } : { download: '' })}
            >
              <img
                alt="Download the Documents"
                src={downloadIcon}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ShippingCard;
