import { useSelector } from 'react-redux';

export const useTranslationSteps = (steps1, steps2, steps3, steps4) => {
  const { translations } = useSelector((state: any) => state.account);
  const {
    common: { corpFlow },
  } = translations;

  const { accessibility } = corpFlow;

  const translatedDaoSteps = steps1.map((step, index) => {
    return {
      step: index + 1,
      label: corpFlow[step],
      ariaLabel: accessibility[step],
    };
  });

  const translatedNonDaoSteps = steps2.map((step, index) => {
    return {
      step: index + 1,
      label: corpFlow[step],
      ariaLabel: accessibility[step],
    };
  });

  const translatedFlowOneSteps = steps3.map((step, index) => {
    return {
      step: index + 1,
      label: corpFlow[step],
      ariaLabel: accessibility[step],
    };
  });

  const translatedPaymentExitSteps = steps4.map((step, index) => {
    return {
      step: index + 1,
      label: corpFlow[step],
      ariaLabel: accessibility[step],
    };
  });

  return [translatedDaoSteps, translatedNonDaoSteps, translatedFlowOneSteps, translatedPaymentExitSteps];
};
