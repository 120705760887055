import React from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import DaoThankYouPage from './DaoThankYouPage';
import ConventionalThankYouPage from './ConventionalThankYouPage';
import { conventionalThankyouSubType, thankyouPageType } from '../../../utils/constants';
import Services from './Services';
import Articles from './Articles';

const ThankYouPageContainer = (props) => {
  const { translations } = useSelector((state: any) => state.account);
  const { countryConfigData } = useSelector((state: any) => state.corporateAccount);
  const hideArticles = countryConfigData?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'HIDE_ARTICLE')?.value === 'Y';
  const { thankyou: thankyouContent } = translations;
  const { conventional } = thankyouContent;
  const { request, sorry, exist } = conventional;
  const [searchParams] = useSearchParams();
  const showDaoThankYouPage = searchParams.get('showdaothankyoupage');

  return (
    <>
      {thankyouPageType.conventional && (
        <>
          {props.flow === conventionalThankyouSubType.exist && (
            <ConventionalThankYouPage
              title={exist.TITLE}
              subTitle={exist.SUBTITLE}
            />
          )}
          {props.flow === conventionalThankyouSubType.request && (
            <ConventionalThankYouPage
              title={request.TITLE}
              subTitle={request.SUBTITLE}
            />
          )}
          {props.flow === conventionalThankyouSubType.sorry && (
            <ConventionalThankYouPage
              title={sorry.TITLE}
              subTitle={sorry.SUBTITLE}
            />
          )}
        </>
      )}
      {((thankyouPageType.dao && props.flow === thankyouPageType.dao) ||
        showDaoThankYouPage?.toLowerCase() === 'true') && <DaoThankYouPage />}
      <Services />
      {!hideArticles && <Articles />}
    </>
  );
};
export default ThankYouPageContainer;
