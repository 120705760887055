import {
  GET_DEBIT_SUCCESS,
  SET_DEBIT_STATUS,
  SET_DEBIT_ERROR,
  GET_PAYMENT_PROFILE,
  SET_PAYMENT_PROFILE,
  GET_PAYMENT_STATUS,
  SET_PAYMENT_STATUS,
  SET_CARD_DETAILS,
  SET_IXOPAY_RIDIRECT_URL,
  SET_RETRY_PAYMENT,
  SET_PAYMENT_ERROR,
  SET_PAYMENT_PROP,
  SET_PAYMENT_PROFILE_ERROR,
} from '../actions/actionTypes';

const initialState = {
  loadingDebit: false,
  loadingProfileSucess: false,
  ixopayIframe: false,
  loadingIframe: false,
  loadingGetPaymentStatusSucess: false,
  debit: {
    redirectUrl: '',
  },
  debitError: false,
  paymentProfile: {},
  cardDetails: [],
  paymentStatus: {},
  paymentError: false,
  paymentCard: false,
  paymentRetry: false,
  errorMessages: '',
  paymentProfileCode: -1,
  iframeRetryCount: 0,
  transactionRetryCount: 0,
  timeoutRetryCount: 0,
  iframeTimeoutCount: 0,
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEBIT_SUCCESS: {
      return { ...state, loadingDebit: true, ixopayIframe: false, loadingProfileSucess: false, paymentCard: false };
    }

    case SET_DEBIT_STATUS: {
      return {
        ...state,
        debit: action.payload,
        loadingDebit: false,
        ixopayIframe: true,
        debitError: false,
        loadingIframe: false,
        paymentCard: false,
      };
    }

    case SET_IXOPAY_RIDIRECT_URL: {
      return { ...state, redirectUrl: action.payload };
    }

    case SET_DEBIT_ERROR: {
      return { ...state, debitError: action.payload, loadingDebit: false };
    }

    case GET_PAYMENT_STATUS: {
      return { ...state, loadingGetPaymentStatusSucess: true };
    }

    case SET_PAYMENT_STATUS: {
      return { ...state, paymentStatus: action.payload, loadingGetPaymentStatusSucess: false, ixopayIframe: false };
    }

    case SET_PAYMENT_ERROR: {
      return { ...state, paymentError: action.payload };
    }

    case GET_PAYMENT_PROFILE: {
      return { ...state, loadingProfileSucess: true };
    }

    case SET_PAYMENT_PROFILE: {
      return {
        ...state,
        paymentProfile: action.payload,
        loadingProfileSucess: false,
        ixopayIframe: false,
        paymentCard: true,
        paymentProfileCode: -1,
      };
    }

    case SET_CARD_DETAILS: {
      return { ...state, cardDetails: action.payload };
    }

    case SET_RETRY_PAYMENT: {
      return { ...state, paymentRetry: action.payload };
    }

    case SET_PAYMENT_PROFILE_ERROR: {
      return { ...state, paymentProfile: {}, paymentProfileCode: action.payload };
    }

    case SET_PAYMENT_PROP:
      return { ...state, [action.payload.key]: action.payload.value };

    default:
      return state;
  }
};

export default paymentReducer;
