import React from 'react';
import { Spinner } from 'react-bootstrap';

import './FileTypeImg.css';
import FailUploadIcon from '../../../assets/icons/cancel_circle_red.svg';
import ExcelImg from '../../../assets/icons/file_xls_rgb_black.svg';
import JpgImg from '../../../assets/icons/file_jpg_rgb_black.svg';
import PdfImg from '../../../assets/icons/file_pdf_rgb_black.svg';
import PngImg from '../../../assets/icons/file_png_rgb_black.svg';
import WordImg from '../../../assets/icons/file_doc_rgb_black.svg';
import { fileTypes } from '../../../utils/constants';

const FileTypeImg = ({ fileType }) => {
  const uploading = fileType === 'uploading';
  const fileTypeSrc = (fileType) => {
    switch (fileType) {
      case fileTypes.excel:
        return ExcelImg;
      case fileTypes.jpg:
        return JpgImg;
      case fileTypes.pdf:
        return PdfImg;
      case fileTypes.png:
        return PngImg;
      case fileTypes.word:
        return WordImg;
      case 'fail':
        return FailUploadIcon;
    }
  };
  const fileTypeName = (fileType) => {
    switch (fileType) {
      case fileTypes.excel:
        return 'Excel File';
      case fileTypes.jpg:
        return 'Jpg File';
      case fileTypes.pdf:
        return 'Pdf File';
      case fileTypes.png:
        return 'Png File';
      case fileTypes.word:
        return 'Word File';
      case 'fail':
        return 'Upload Failed';
    }
  };

  return uploading ? (
    <Spinner
      animation="border"
      variant="warning"
      className="file-type-img align-self-center"
    />
  ) : (
    <img
      alt={fileTypeName(fileType)}
      src={fileTypeSrc(fileType)}
      className="file-type-img align-self-center"
    />
  );
};

export default FileTypeImg;
