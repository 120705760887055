export const COUNTRY_PLACEHOLDER = 'e.g. Ireland';
export const FIRST_NAME_PLACEHOLDER = 'e.g. John';
export const LAST_NAME_PLACEHOLDER = 'e.g. Doe';
export const EMAIL_PLACEHOLDER = 'e.g. John.Doe@mail.com';
export const PHONE_CODE_PLACEHOLDER = 'e.g. +31';
export const PHONE_NUMBER_PLACEHOLDER = 'e.g. 123456789';
export const PHONE_EXTENSION_PLACEHOLDER = 'e.g. 11';
export const JOB_PLACEHOLDER = 'e.g. Director';
export const LEGAL_ID_PLACEHOLDER = 'e.g. 12345678B02';
export const BRANCH_CODE_PLACEHOLDER = 'e.g. 00000';
export const COMPANY_NAME_PLACEHOLDER = 'e.g. XYZ System Technologies (Scotland) Limited';
export const TRADING_NAME_PLACEHOLDER = 'e.g. XYZ System Technologies (Scotland) Limited';
export const ADDRESS_LINE_1_PLACEHOLDER = "e.g. Kyrl's Street";
export const ADDRESS_LINE_2_PLACEHOLDER = 'e.g. Centre';
export const CITY_PLACEHOLDER = 'e.g. Cork';
export const STATE_PLACEHOLDER = 'e.g. Ireland';
export const POSTAL_CODE_PLACEHOLDER = 'e.g. T12 X9Y9';
export const PROVINCE_PLACEHOLDER = 'e.g. Munster';
export const WEBSITE_PLACEHOLDER = 'e.g. https://example.com';

export const missingFlags = ['KV', 'XS'];

export const DEFAULT_ADDRESS = {
  addressLine1: [],
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
  province: '',
};

export const DEFAULT_CONTACT_FORM = {
  country: [],
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneType: 'Mobile',
  countryCode: [],
  number: '',
  e164NoFmt: '',
  ext: '',
  job: '',
  department: '',
  preferredLanguage: '',
};

export const DEFAULT_COMPANY_FORM = {
  registeredCountry: [],
  legalCodes: {},
  registrationNotPresent: false,
  validated: false,
  companyName: '',
  tradingName: '',
  vAddress: {
    addressLine1: [],
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    province: '',
  },
  bAddress: {
    addressLine1: [],
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    province: '',
  },
  billingAddressSame: true,
  tradingCompanyNameSame: true,
  companyWebsite: '',
  companyIndustry: '',
  regnoCheckBox: true,
  companyAddressCountry: [],
  billingAddressCountry: [],
  branchCode: '00000',
};

export const DEFAULT_COMPANY_INFORMATION = {
  companyName: '',
  addrCheckApplicable: '',
  companyAddresses: [],
};

export const DEFAULT_PHONE_ROW = [
  { key: 'countryCode', val: [] },
  { key: 'number', val: '' },
  { key: 'ext', val: '' },
];

export const vAddressMapper = {
  addressLine1: 'acc_accAddrCntSDOs_addrSDO_addr1__COM',
  addressLine2: 'acc_accAddrCntSDOs_addrSDO_addr2__COM',
  city: 'acc_accAddrCntSDOs_addrSDO_cty__COM',
  zipCode: 'acc_accAddrCntSDOs_addrSDO_zip__COM',
  state: 'acc_accAddrCntSDOs_addrSDO_state__COM',
  province: 'acc_accAddrCntSDOs_addrSDO_prvn__COM',
} as const;

export const bAddressMapper = {
  addressLine1: 'acc_accAddrCntSDOs_addrSDO_addr1__BIL',
  addressLine2: 'acc_accAddrCntSDOs_addrSDO_addr2__BIL',
  city: 'acc_accAddrCntSDOs_addrSDO_cty__BIL',
  zipCode: 'acc_accAddrCntSDOs_addrSDO_zip__BIL',
  state: 'acc_accAddrCntSDOs_addrSDO_state__BIL',
  province: 'acc_accAddrCntSDOs_addrSDO_prvn__BIL',
} as const;

export const contactMapper = {
  title: 'acc_accAddrCntSDOs_slttn',
  firstName: 'acc_accAddrCntSDOs_fstNm',
  lastName: 'acc_accAddrCntSDOs_fmNm',
  job: 'acc_accAddrCntSDOs_jbTtl',
  email: 'acc_accAddrCntSDOs_eml',
  department: 'acc_accAddrCntSDOs_addrSDO_dptm',
  mobile: 'acc_accAddrCntSDOs_mobNo',
  office: 'acc_accAddrCntSDOs_telNo',
  ext: 'acc_accAddrCntSDOs_telExt',
  prefCommMthd: 'acc_accAddrCntSDOs_prefCommMthd',
} as const;

export const companyLegalMapper = {
  companyLegal: 'acc_accRgsSDOs_taxPayRgsCd__',
} as const;

export const companyNameMapper = {
  companyName: 'acc_custSDO_custNmExt',
} as const;

export const companyFieldsMapper = {
  companyWebsite: 'acc_custSDO_custWeb',
  companyIndustry: 'acc_custSDO_custInd',
} as const;

export const ADDRESS_DISABLED_CATEGORIES = ['city', 'zipCode', 'state', 'province'];

export const CORPORATE_ACCOUNT_URL = 'corporate-account/';

export const OPTION_FLOW_1 = '1';
export const OPTION_FLOW_2 = '2';
export const OPTION_FLOW_3 = '3';

export const translationsObject = {
  common: {
    marketing: {},
    footer: {},
    corpFlow: {},
    announcement: {},
    select: {},
    header: {
      accessibility: {
        label: {},
      },
    },
    timeout: {
      modal: {},
    },
  },
  thankyou: {
    dao: {},
    daoAsync: {},
    conventional: {},
  },
  company: {
    label: {},
    dropdowns: {},
    heading: {},
    placeholder: {},
    legal: {},
    validation: {},
  },
  contact: {
    placeholder: {},
    label: {},
    tooltip: {},
    DEPARTMENT_LIST: {},
    heading: {},
  },
  payment: {
    modal: {},
  },
  summary: {
    common: {},
    company: {},
    contact: {},
    address: {},
  },
  shipping: {
    spinner: {},
    modal: {},
    alert: {},
  },
  validation: {},
  faqs: {
    banner: {},
    contact: {
      btn: {},
    },
    faq: {},
  },
};

export const TRACKING_KEY = 'userTrackId';

export const BRV_EXTERNAL_WEBSITE_ERROR = '280309';

export const pageMapper = {
  landing: 'M01001',
  contact: 'M02001',
  company: 'M03001',
  review: 'M04001',
  shipping: 'M05001',
  payment: 'M06001',
  summary: 'M07001',
  thankyou: 'M08001',
};

export const thankyouPageType = {
  dao: 'dao',
  conventional: 'conventional',
};

export const conventionalThankyouSubType = {
  exist: 'exist',
  request: 'request',
  sorry: 'sorry',
};

export const EtlCodeErrorMapper = {
  BrvServiceError: '9003',
  BrvExternalServiceError: '9011',
  BrvCompanyNameOverrideError: '3001',
  BrvAddressOverrideError: '3002',
  CrossBillingCountryCodeError: '2003',
  DontHaveRegistrationNoError: '2002',
  BreServiceError: '9005',
  LegalExistsInGcdbError: '2001',
  BreFieldsError: '4004',
  NonDaoCountryError: '1001',
  MyBillValidateError: '9001',
  MyBillExistsError: '1002',
  IxopayProfileServiceError: '9007',
  ContactIxopayProfileServiceError: '9012',
  IxopayProfileExistsError: '1004',
  EmailExceedsError: '1003',
  CompanyNameExceedsError: '2004',
  CompanyAddressCountryCrossBillingError: '2005',
  NoBillingTermsFromBre: '2006',
};

export const exitScenarios = [
  EtlCodeErrorMapper.LegalExistsInGcdbError,
  EtlCodeErrorMapper.MyBillExistsError,
  EtlCodeErrorMapper.IxopayProfileExistsError,
];

export const requestScenarios = [
  EtlCodeErrorMapper.BrvCompanyNameOverrideError,
  EtlCodeErrorMapper.BrvAddressOverrideError,
  EtlCodeErrorMapper.CrossBillingCountryCodeError,
  EtlCodeErrorMapper.DontHaveRegistrationNoError,
  EtlCodeErrorMapper.CompanyAddressCountryCrossBillingError,
  EtlCodeErrorMapper.NonDaoCountryError,
];

export const sorryScenarios = [
  EtlCodeErrorMapper.BrvServiceError,
  EtlCodeErrorMapper.BrvExternalServiceError,
  EtlCodeErrorMapper.BreServiceError,
  EtlCodeErrorMapper.BreFieldsError,
  EtlCodeErrorMapper.CompanyNameExceedsError,
  EtlCodeErrorMapper.MyBillValidateError,
  EtlCodeErrorMapper.IxopayProfileServiceError,
  EtlCodeErrorMapper.EmailExceedsError,
  EtlCodeErrorMapper.ContactIxopayProfileServiceError,
  EtlCodeErrorMapper.NoBillingTermsFromBre,
];

export const MAX_RETRY_COUNT = 2;

export const ALT_TAB_TITLE = 'Open An Account';

export const GLOBAL_COUNTRY_CODE = 'G@';

export const daoSteps = [
  'CONTACT_DETAILS_STEP',
  'COMPANY_DETAILS_STEP',
  'REVIEW_DETAILS_STEP',
  'SHIPPING_STEP',
  'PAYMENT_METHOD_STEP',
  'SUMMARY_STEP',
];

export const nonDaoSteps = ['CONTACT_DETAILS_STEP', 'COMPANY_DETAILS_STEP', 'REVIEW_DETAILS_STEP'];

export const flowOneSteps = [
  'CONTACT_DETAILS_STEP',
  'COMPANY_DETAILS_STEP',
  'REVIEW_DETAILS_STEP',
  'PAYMENT_METHOD_STEP',
  'SUMMARY_STEP',
];

export const paymentExitSteps = [
  'CONTACT_DETAILS_STEP',
  'COMPANY_DETAILS_STEP',
  'REVIEW_DETAILS_STEP',
  'SHIPPING_STEP',
  'SUMMARY_STEP',
];

export const shippingCardDocType = {
  rca: 'RCA',
  acc: 'ACC',
};

export const paymentTransactionStatus = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const paymentExitTypes = ['CSH', 'CCD'];

export const MANDATORY_REG_TYPE_CODE = 'acc_accRgsSDOs_regTyCd__';

export const fileTypes = {
  pdf: 'application/pdf',
  png: 'image/png',
  word: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  jpg: 'image/jpeg',
  excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const directionLayout = {
  ltr: 'ltr',
  rtl: 'rtl',
};

export const languageCode = {
  enGB: 'en-GB',
};

export const PROD_URL = 'dao-fe-test.test-dmz.apps.czchooct002.dhl.com'; // for testing

export const addressFields = ['addressLine1', 'addressLine2', 'city', 'state', 'province', 'zipCode'];

export const addressTypes = ['vAddress', 'bAddress'];

export const contactFieldsNames = [
  'email',
  'firstName',
  'lastName',
  'title',
  'preferredCommunicationMethod',
  'preferredLanguage',
  'department',
  'ext',
  'job',
  'countryCode',
  'phoneType',
  'receiveNewsAndUpdates',
];
