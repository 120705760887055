import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';

import './ThankYouPage.css';
import { DynamicImage, Carousel } from '../../common';
import { useMediaQuery } from '../../../hooks/useMediaQuery';

const Articles = () => {
  const { translations } = useSelector((state: any) => state.account);
  const { countryConfigData } = useSelector((state: any) => state.corporateAccount);
  const { thankyou: thankyouContent, image } = translations;
  const { articles, accessibility } = thankyouContent;
  const { articleList } = articles;
  const { articles: accessibilityArticles } = accessibility;
  const { articleList: accessibilityArticleList } = accessibilityArticles;
  const countryCode = countryConfigData?.ctryCfgs?.find(
    (ctryCfg) => ctryCfg.typeCd === 'DEFAULT_DISPLAY_LANGUAGE'
  )?.ctryCd;
  const isDaoCountry = countryConfigData?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DAO_COUNTRY')?.value === 'Y';
  const urlDomain = process.env.REACT_APP_URL;
  const isMobileDevice = useMediaQuery('only screen and (min-width : 320px) and (max-width : 767px)');

  return (
    <>
      <div className="bg-white thankyou-article-wrap row">
        <div className="text-center mx-auto p-0 my-4 thankyou-page-articles-section col-md-10">
          <h2 className="thankyou-article-header">{articles?.ARTICLE_TITLE}</h2>
          {isMobileDevice ? (
            <Carousel
              slide={true}
              interval={null}
              controls={false}
              wrap={true}
              fade={true}
              className="dao-articles-mobile"
            >
              {Object.keys(articleList).map((item) => {
                return (
                  <Carousel.Item key={item}>
                    <a
                      className="thankyou-article-link"
                      href={articleList[item].URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <DynamicImage
                        src={`${urlDomain}/assets/${countryCode}/images/article_${item}.jpg?${image?.value}`}
                        defaultSrc={`${urlDomain}/assets/DEFAULT/images/article_${item}.jpg?${image?.value}`}
                        isDaoCountry={isDaoCountry}
                        alt={accessibilityArticleList[item].IMG_ALT}
                        className="thankyou-article-image"
                      />
                      <p className="thankyou-article-info">
                        {articleList[item].TITLE}
                        <span>{articleList[item].READ}</span>
                      </p>
                      <p className="thankyou-article-title">{articleList[item].HEADING}</p>
                      <p className="thankyou-article-desc">{articleList[item].DESC}</p>
                    </a>
                  </Carousel.Item>
                );
              })}
            </Carousel>
          ) : (
            <Row className="dao-articles-desktop">
              {Object.keys(articleList).map((item) => {
                return (
                  <Col
                    key={item}
                    xs={4}
                  >
                    <div className="p-2">
                      <a
                        className="thankyou-article-link"
                        href={articleList[item].URL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DynamicImage
                          src={`${urlDomain}/assets/${countryCode}/images/article_${item}.jpg?${image?.value}`}
                          defaultSrc={`${urlDomain}/assets/DEFAULT/images/article_${item}.jpg?${image?.value}`}
                          isDaoCountry={isDaoCountry}
                          alt={accessibilityArticleList[item].IMG_ALT}
                          className="thankyou-article-image"
                        />
                        <p className="thankyou-article-info">
                          {articleList[item].TITLE}
                          <span>{articleList[item].READ}</span>
                        </p>
                        <p className="thankyou-article-title">{articleList[item].HEADING}</p>
                        <p className="thankyou-article-desc">{articleList[item].DESC}</p>
                      </a>
                    </div>
                  </Col>
                );
              })}
            </Row>
          )}
        </div>
      </div>
    </>
  );
};

export default Articles;
