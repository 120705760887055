import React from 'react';
import { Modal, Button } from '../../common';

function BasicModal({ show, title, content, action1Title, action1Handler }) {
  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered={true}
        restoreFocus={false}
        className="custom-modal"
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            onClick={action1Handler}
            className="custom-modal-link"
          >
            {action1Title}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BasicModal;
