import React from 'react';

import { Modal, Button } from '../../common';

function CountryModal({ show, changeCountry, modal }) {
  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered={true}
        restoreFocus={false}
        className="custom-modal"
      >
        <Modal.Header>
          <Modal.Title>{modal.HEADING_COUNTRY_CHANGE}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modal.BODY_COUNTRY_CHANGE}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="link"
            onClick={() => changeCountry(true)}
            className="custom-modal-link"
          >
            {modal.BUTTON_CHANGE_COUNTRY}
          </Button>
          <Button
            variant="secondary"
            onClick={() => changeCountry(false)}
          >
            {modal.BUTTON_RETAIN_COUNTRY}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CountryModal;
