import _ from 'lodash';
import { takeEvery, put, call, select, take, race, delay } from 'redux-saga/effects';
import {
  getCountryConfig,
  getCountryRefData,
  getDataMaintainanceForLegal,
  getDocUpload,
  getDocDelete,
  getTranslationsAkamai,
} from '../../api/apiWrapper';
import { companyLegalMapper, MANDATORY_REG_TYPE_CODE, paymentExitTypes } from '../../utils/constants';

import {
  GET_COUNTRY_REG_TYPE_DATA,
  GET_REGISTERED_COUNTRY_CONFIG,
  GET_COMPANY_INFO,
  UPDATE_ADDRESS_DETAIL,
  GET_REGISTERED_COUNTRY_DETAILS,
  GET_BRE_INFO,
  GET_LEGAL_ID_TRANSLATIONS,
  GET_VISITING_ADDRESS,
  GET_MANDATORY_DISABLED_VISITING_ADDRESS_FIELDS,
  GET_BILLING_ADDRESS,
  GET_MANDATORY_DISABLED_BILLING_ADDRESS_FIELDS,
  GET_DOC_UPLOAD,
  GET_DOC_DELETE,
  GET_REGEX_VISITING_COUNTRY,
  GET_REGEX_BILLING_COUNTRY,
} from '../actions/actionTypes';
import {
  getCountryRegTypeData,
  setRegistrationDetails,
  getRegisteredCountryConfig,
  setCompanyRelatedDetails,
  setRegisteredCountryConfig,
  setRegistrationRelatedDetails,
  setCompanyProp,
  setLegalIdTranslations,
  setMandatoryDisabledVisitingAddressFields,
  getMandatoryDisabledVisitingAddressFields,
  setMandatoryDisabledBillingAddressFields,
  getMandatoryDisabledBillingAddressFields,
  getDocUploadSuccess,
  getDocUploadStatusCode,
  setFileUploadConfig,
  setCompanyConfig,
  setCompanyFieldRules,
  setCompanyCountryMandatoryDisbledRules,
  setCompanyKey,
} from '../actions/companyActions';
import { setTranslationsAkamaiError } from '../actions/accountActions';

import { getAddressDetailsSuccess, getBreDetails, getValidateAddressDetails } from '../actions/daoActions';
import {
  setBillingBreRegex,
  setBillingDaoRegex,
  setCompanyLegalBreRegex,
  setCompanyNameBreRegex,
  setCompanyNameDaoRegex,
  setContactBreRegex,
  setContactDaoRegex,
  setVisitingBreRegex,
  setVisitingDaoRegex,
} from '../actions/corporateAccountActions';
import { websiteUSPattern1 } from '../../utils/regEx';

export function* companySaga() {
  yield takeEvery(GET_COUNTRY_REG_TYPE_DATA, onGetCountryRegTypeData);
  yield takeEvery(GET_REGISTERED_COUNTRY_DETAILS, getRegisteredCountryDetailsSaga);
  yield takeEvery(GET_REGISTERED_COUNTRY_CONFIG, getRegisteredCountryConfigSaga);
  yield takeEvery(GET_COMPANY_INFO, getCompanyInfoSaga);
  yield takeEvery(GET_BRE_INFO, getBreInfoSaga);
  yield takeEvery(UPDATE_ADDRESS_DETAIL, updateAddressDetailSaga);
  yield takeEvery(GET_LEGAL_ID_TRANSLATIONS, getLegalIdTranslationsSaga);
  yield takeEvery(GET_VISITING_ADDRESS, onGetVisitingAddressMandatoryDisabledFieldsSaga);
  yield takeEvery(GET_BILLING_ADDRESS, onGetBillingAddressMandatoryDisabledFieldsSaga);
  yield takeEvery(GET_MANDATORY_DISABLED_VISITING_ADDRESS_FIELDS, onGetVisitingAddressMandatoryDisabledFields);
  yield takeEvery(GET_MANDATORY_DISABLED_BILLING_ADDRESS_FIELDS, onGetBillingAddressMandatoryDisabledFields);
  yield takeEvery(GET_REGEX_VISITING_COUNTRY, getRegexVisitingCountrySaga);
  yield takeEvery(GET_REGEX_BILLING_COUNTRY, getRegexBillingCountrySaga);
  yield takeEvery(GET_DOC_UPLOAD, getDocUploadSaga);
  yield takeEvery(GET_DOC_DELETE, getDocDeleteSaga);
}

export function* onGetCountryRegTypeData(action) {
  const { registrationTypeReq, dataMaintainanceReq } = action.payload;
  try {
    const legalIdResponse = yield call(getCountryRefData, registrationTypeReq);
    const legalIds = legalIdResponse?.refDatLst;
    const mandatoryFieldsResp = yield call(getDataMaintainanceForLegal, dataMaintainanceReq);
    const mandatoryLegalIds: any = [];
    const mandatoryFields: any = [];
    const { countryConfigData, optionalLegalIds } = yield select((state) => state.company);
    const configHideMandatoryList = countryConfigData?.ctryRules?.rules?.reduce((mandatoryArray, rule) => {
      if (rule.mandatoryInd === 'N' && rule.attributeName.startsWith(companyLegalMapper.companyLegal)) {
        mandatoryArray.push(rule.attributeName.replace(companyLegalMapper.companyLegal, ''));
      }

      return mandatoryArray;
    }, []);

    if (
      mandatoryFieldsResp &&
      mandatoryFieldsResp.refGcdbDatFldLst &&
      mandatoryFieldsResp.refGcdbDatFldLst.length > 0
    ) {
      if (legalIdResponse && legalIdResponse.refDatLst && legalIdResponse.refDatLst.length > 0) {
        const referenceMandatoryList = mandatoryFieldsResp.refGcdbDatFldLst.filter(
          (data) => data.attrNm?.includes(MANDATORY_REG_TYPE_CODE) && data.manInd === 'Y'
        );

        legalIdResponse.refDatLst.forEach((legalId) => {
          const isPresentInReference = referenceMandatoryList.find((mandatoryField) =>
            mandatoryField.attrNm.includes(legalId.refDatCd)
          );
          const isPresentInConfig = configHideMandatoryList?.includes(legalId.refDatCd);
          if (isPresentInReference && !isPresentInConfig) {
            legalId['mandatory'] = true;
          } else {
            legalId['mandatory'] = false;
          }
          mandatoryLegalIds.push(legalId);
        });
      }

      mandatoryFieldsResp.refGcdbDatFldLst.forEach((fld) => {
        const mandatory = fld.manInd === 'Y';

        if (mandatory) {
          mandatoryFields.push(fld.attrNm);
        }
      });
    }

    const conditionalLegalIds = mandatoryFieldsResp?.refGcdbDatFldLst?.filter(
      (data) => data.attrNm === 'LEADIDENTIFIERGROUP' && data.alwVal
    );

    yield put(setRegistrationDetails({ legalIds, mandatoryFields }));
    yield put(
      setRegistrationRelatedDetails({
        legalIds,
        optionalLegalIds,
        conditionalLegalIds,
      })
    );
  } catch (error) {
    yield put(setCompanyProp('registrationDetails', {}));
  }
}

export function* getRegisteredCountryDetailsSaga(action) {
  const registeredCountry = action.payload;
  const registeredCountryCode = registeredCountry[0]?.code;

  if (registeredCountryCode) {
    const registrationTypeReq = {
      refDatCatTyCd: 'CMF_LGL_IDF_TYP',
      genReq: { refDatCtry: registeredCountryCode },
    };
    const dataMaintainanceReq = { ctryCd: registeredCountryCode };
    const { clientId } = yield select((state) => state.app);
    yield put(getRegisteredCountryConfig({ ctryCd: registeredCountryCode, srcApp: clientId }));
    yield race({
      result: take('SET_REGISTERED_COUNTRY_CONFIG'),
      timeout: delay(2000),
    });
    yield put(getCountryRegTypeData({ registrationTypeReq, dataMaintainanceReq }));
  }
}

export function* getRegisteredCountryConfigSaga(action) {
  try {
    const cntryConfigResponse = yield call(getCountryConfig, action.payload);
    let mandatoryFields: any = [];
    let companyDisabledFields: any = [];

    if (cntryConfigResponse?.ctryRules?.rules[0]?.countryCode === 'IE') {
      mandatoryFields.push('acc_accAddrCntSDOs_addrSDO_zip__COM');
      mandatoryFields.push('acc_accAddrCntSDOs_addrSDO_zip__BIL');
    }

    if (cntryConfigResponse && cntryConfigResponse.ctryRules.rules && cntryConfigResponse.ctryRules.rules.length > 0) {
      mandatoryFields = cntryConfigResponse?.ctryRules?.rules
        .filter((e) => {
          return e.mandatoryInd === 'Y';
        })
        .map((e) => {
          return e.attributeName;
        });

      companyDisabledFields = cntryConfigResponse?.ctryRules?.rules
        .filter((e) => {
          return e.updateAllowedInd === 'N';
        })
        .map((e) => {
          return e.attributeName;
        });
    }

    yield put(
      setCompanyCountryMandatoryDisbledRules({
        mandatoryFields,
        companyDisabledFields,
      })
    );
    yield put(setCompanyRelatedDetails(cntryConfigResponse));
    yield put(setRegisteredCountryConfig(cntryConfigResponse));
    yield put(setCompanyConfig(cntryConfigResponse));
    yield put(setFileUploadConfig(cntryConfigResponse));
    yield put(setCompanyFieldRules(cntryConfigResponse));
    yield put(setContactBreRegex(cntryConfigResponse));
    yield put(setContactDaoRegex(cntryConfigResponse));
    yield put(setCompanyLegalBreRegex(cntryConfigResponse));
    yield put(setCompanyNameBreRegex(cntryConfigResponse));
    yield put(setCompanyNameDaoRegex(cntryConfigResponse));
    yield put(setVisitingBreRegex(cntryConfigResponse));
    yield put(setVisitingDaoRegex(cntryConfigResponse));
    yield put(setBillingBreRegex(cntryConfigResponse));
    yield put(setBillingDaoRegex(cntryConfigResponse));
  } catch (error) {
    yield put(setCompanyProp('countryConfigData', {}));
  }
}

export function* getCompanyInfoSaga(action) {
  const legalCodes = action.payload;
  const { optionalLegalIds, company, countryConfigData } = yield select((state) => state.company);
  const { clientId } = yield select((state) => state.app);
  const businessRegistrations: any = [];
  const skipBrv = countryConfigData?.ctryCfgs?.filter((obj) => obj.typeCd === 'SKIP_LGL_ID_IN_BRV_REQ');

  for (const [key, value] of Object.entries(legalCodes)) {
    if (optionalLegalIds.includes(key) || skipBrv.some((obj) => obj.value === key)) continue;

    businessRegistrations.push({
      registrationType: key,
      registrationNumber: (value as string).toUpperCase(),
    });
  }

  const req = {
    countryCode: company.registeredCountry[0]?.code,
    sourceApp: clientId,
    businessRegistrations,
  };
  yield put(getValidateAddressDetails({ brvRequest: req, skipBrvList: skipBrv }));
}

export function* getBreInfoSaga(action) {
  const legalCodes = action.payload;
  const { mandatoryLegalIds, company, brvRegistrationLst } = yield select((state) => state.company);
  const {
    companyName,
    vAddress,
    bAddress: billingAddress,
    companyWebsite,
    companyIndustry,
    billingAddressSame,
    companyAddressCountry,
    billingAddressCountry,
  } = company;
  const { contact } = yield select((state) => state.contact);
  const { clientId, appTimers } = yield select((state) => state.app);
  const { paymentMethodType, paymentExitSubType } = appTimers;
  const { selectedLanguage, altLanguageCode } = yield select((state) => state.account);
  const { debit } = yield select((state) => state.payment);
  const { redirectUrl } = debit;

  let bAddress;
  if (billingAddressSame) {
    bAddress = { ...vAddress };
  } else {
    bAddress = { ...billingAddress };
  }

  const existingRegistrationLst = Object.keys(legalCodes).map((legalId) => {
    return {
      legalId,
      legalVal: legalCodes[legalId].toUpperCase(),
      mandatory: _.includes(mandatoryLegalIds, legalId),
    };
  });
  const combinedRegistrationLst = [...existingRegistrationLst, ...(brvRegistrationLst || [])];

  const breRequestData = {
    account: {
      customer: {
        dhlCountryCd: contact.country[0]?.code,
        isoCountryCd: contact.country[0]?.code,
        countryNm: contact.country[0]?.name,
        title: contact.title,
        firstNm: contact.firstName,
        lastNm: contact.lastName,
        email: contact.email,
        jobTitle: contact.job,
        department: contact.department,
        preferredLanguage: contact.preferredLanguage,
        phoneLst: [
          {
            type: contact.phoneType,
            phoneCode: contact.countryCode[0].callCode,
            number: contact.number,
            ...(contact.ext && { ext: contact.ext }),
            e164NoFmt: contact.e164NoFmt,
          },
        ],
        registeredCtryCd: company.registeredCountry[0]?.code,
        registrationLst: combinedRegistrationLst,
        companyNm: companyName,
        addrLst: [
          {
            addrLine1: vAddress.addressLine1[0],
            addrLine2: vAddress.addressLine2,
            city: vAddress.city,
            postalCd: vAddress.zipCode,
            ...(vAddress.state && { state: vAddress.state }),
            ...(vAddress?.province && { province: vAddress.province }),
            countryCd: companyAddressCountry[0]?.code,
            billing: false,
          },
          {
            addrLine1: bAddress.addressLine1[0],
            addrLine2: bAddress.addressLine2,
            city: bAddress.city,
            postalCd: bAddress.zipCode,
            ...(bAddress.state && { state: bAddress.state }),
            ...(bAddress.province && { province: bAddress.province }),
            countryCd: billingAddressCountry[0]?.code,
            billing: true,
          },
        ],
        companyWebsite: websiteUSPattern1.test(companyWebsite) ? companyWebsite.toUpperCase() : companyWebsite,
        companyIndustry,
        languageCd: selectedLanguage,
      },
      bre: true,
      sourceApp: clientId,
      fullValidation: true,
      preferredLanguage: altLanguageCode,
      paymentMethod:
        redirectUrl && paymentExitTypes.includes(paymentExitSubType) ? paymentExitSubType : paymentMethodType,
    },
  };
  yield put(getBreDetails(breRequestData));
}

export function* updateAddressDetailSaga(action) {
  const { addressDetail, addressType } = action.payload;
  const { mergeAddr1Addr2, vAddressDisabledFields, bAddressDisabledFields } = yield select((state) => state.company);

  const disabledFields = addressType === 'vAddress' ? vAddressDisabledFields : bAddressDisabledFields;

  const { addressLine1, addressLine2, addressLine3, cityNm, state, province, zip } = addressDetail;

  const address = {
    addressLine1: addressLine1
      ? [addressLine1?.substring(0, 45)]
      : addressLine2
      ? [addressLine2?.substring(0, 45)]
      : [],
    addressLine2: createAddressLine2(mergeAddr1Addr2, addressLine2, addressLine3),
    city: disabledFields.includes('city') ? '' : cityNm,
    state: disabledFields.includes('state') ? '' : state,
    zipCode: disabledFields.includes('zipCode') ? '' : zip,
    province: disabledFields.includes('province') ? '' : province,
  };

  yield put(getAddressDetailsSuccess(address, addressType));
}

export function* getLegalIdTranslationsSaga(action) {
  try {
    const response = yield call(getTranslationsAkamai, action.payload);
    const { selectedLanguage } = response;
    yield put(setLegalIdTranslations(response[selectedLanguage].company));
  } catch (error) {
    yield put(setTranslationsAkamaiError(error));
  }
}

export function* onGetVisitingAddressMandatoryDisabledFieldsSaga(action) {
  try {
    const cntryConfigResponse = yield call(getCountryConfig, action.payload);
    const visitingAddressCountryCode = cntryConfigResponse?.ctryCfgs;
    let mandatoryVisitAddressFields = [];
    let visitAddressDisabledFields = [];

    if (cntryConfigResponse && cntryConfigResponse.ctryRules.rules && cntryConfigResponse.ctryRules.rules.length > 0) {
      mandatoryVisitAddressFields = cntryConfigResponse?.ctryRules?.rules
        .filter((e) => {
          return e.mandatoryInd === 'Y';
        })
        .map((e) => {
          return e.attributeName;
        });

      visitAddressDisabledFields = cntryConfigResponse?.ctryRules?.rules
        .filter((e) => {
          return e.updateAllowedInd === 'N';
        })
        .map((e) => {
          return e.attributeName;
        });
    }

    yield put(
      setMandatoryDisabledVisitingAddressFields({
        mandatoryVisitAddressFields,
        visitAddressDisabledFields,
        visitingAddressCountryCode,
      })
    );
    yield put(setVisitingBreRegex(cntryConfigResponse));
    yield put(setVisitingDaoRegex(cntryConfigResponse));
  } catch (error) {
    yield put(setCompanyProp('registrationDetails', {}));
  }
}

export function* onGetBillingAddressMandatoryDisabledFieldsSaga(action) {
  try {
    const cntryConfigResponse = yield call(getCountryConfig, action.payload);
    const billingAddressCountryCode = cntryConfigResponse?.ctryCfgs;
    let mandatoryBillAddressFields = [];
    let billAddressDisabledFields = [];

    if (cntryConfigResponse && cntryConfigResponse.ctryRules.rules && cntryConfigResponse.ctryRules.rules.length > 0) {
      mandatoryBillAddressFields = cntryConfigResponse?.ctryRules?.rules
        .filter((e) => {
          return e.mandatoryInd === 'Y';
        })
        .map((e) => {
          return e.attributeName;
        });

      billAddressDisabledFields = cntryConfigResponse?.ctryRules?.rules
        .filter((e) => {
          return e.updateAllowedInd === 'N';
        })
        .map((e) => {
          return e.attributeName;
        });
    }

    yield put(
      setMandatoryDisabledBillingAddressFields({
        mandatoryBillAddressFields,
        billAddressDisabledFields,
        billingAddressCountryCode,
      })
    );
    yield put(setBillingBreRegex(cntryConfigResponse));
    yield put(setBillingDaoRegex(cntryConfigResponse));
  } catch (error) {
    yield put(setCompanyProp('registrationDetails', {}));
  }
}

export function* onGetVisitingAddressMandatoryDisabledFields(action) {
  const visitingCountry = action.payload;
  const visitingCountryCode = visitingCountry[0]?.code;

  if (visitingCountryCode) {
    const { clientId } = yield select((state) => state.app);
    yield put(getMandatoryDisabledVisitingAddressFields({ ctryCd: visitingCountryCode, srcApp: clientId }));
  }
}

export function* onGetBillingAddressMandatoryDisabledFields(action) {
  const billingCountry = action.payload;
  const billingCountryCode = billingCountry[0]?.code;

  if (billingCountryCode) {
    const { clientId } = yield select((state) => state.app);
    yield put(getMandatoryDisabledBillingAddressFields({ ctryCd: billingCountryCode, srcApp: clientId }));
  }
}

export function* getRegexVisitingCountrySaga(action) {
  try {
    const cntryConfigResponse = yield call(getCountryConfig, action.payload);
    yield put(setVisitingBreRegex(cntryConfigResponse));
    yield put(setVisitingDaoRegex(cntryConfigResponse));
  } catch (error) {
    yield put(setCompanyProp('countryConfigData', {}));
  }
}

export function* getRegexBillingCountrySaga(action) {
  try {
    const cntryConfigResponse = yield call(getCountryConfig, action.payload);
    yield put(setBillingBreRegex(cntryConfigResponse));
    yield put(setBillingDaoRegex(cntryConfigResponse));
  } catch (error) {
    yield put(setCompanyProp('countryConfigData', {}));
  }
}

export function* getDocUploadSaga(action) {
  try {
    const { altLanguageCode } = yield select((state) => state.account);
    const fileUploadResponse = yield call(getDocUpload, action.payload, altLanguageCode);
    yield put(getDocUploadSuccess(fileUploadResponse.data));
    yield put(getDocUploadStatusCode(fileUploadResponse.status));
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.log('--------error: ', error);
    yield put(getDocUploadStatusCode(error?.response?.status));
    yield put(setCompanyKey('selectedFiles', []));
  }
}

export function* getDocDeleteSaga(action) {
  try {
    yield call(getDocDelete, action.payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('--------error: ', error);
  }
}

const createAddressLine2 = (mergeAddr1Addr2, addressLine2, addressLine3) => {
  let address = '';
  if (mergeAddr1Addr2) {
    if (addressLine2 && addressLine3) {
      address = addressLine2 + ', ' + addressLine3;
    } else if (addressLine2 && !addressLine3) {
      address = addressLine2;
    } else if (!addressLine2 && addressLine3) {
      address = addressLine3;
    }
  } else {
    address = addressLine2;
  }

  return address?.substring(0, 45);
};

export default companySaga;
