import React from 'react';
import { Stepper as RStepper, Step as RStep } from 'react-form-stepper';
import './Stepper.css';

type TStep = {
  step: number;
  label: string;
  ariaLabel: string;
};

type TStepper = {
  activeStep: number;
  steps: TStep[];
};

export const Stepper = (props: TStepper) => {
  const { activeStep, steps } = props;

  return (
    <div className="p-0">
      <RStepper
        activeStep={activeStep}
        connectorStateColors={true}
        connectorStyleConfig={{
          disabledColor: '#8C8C8C',
          activeColor: '#8C8C8C',
          completedColor: '#8C8C8C',
          size: 2,
          style: 'solid',
        }}
        styleConfig={{
          activeBgColor: '#ffffff',
          activeTextColor: '#000000',
          completedBgColor: '#76bd22',
          completedTextColor: '#ffffff',
          inactiveBgColor: '#ffffff',
          inactiveTextColor: '#aaaaaa',
          size: '2.125em',
          circleFontSize: '1rem',
          labelFontSize: '0.825rem',
          borderRadius: '50%',
          fontWeight: '400',
        }}
      >
        {steps.map((step, index) => (
          <RStep
            key={index}
            label={step?.label}
            className="step-btn"
            tabIndex={-1}
            aria-label={step?.ariaLabel}
          />
        ))}
      </RStepper>
    </div>
  );
};
