import { takeEvery, put, call } from 'redux-saga/effects';
import {
  GET_COUNTRY_CONFIG,
  GET_COUNTRY_REF_DATA,
  GET_USER_TRACK_ID,
  GENERATE_TRACK_ID,
  GET_LANDING_PAGE,
} from '../actions/actionTypes';
import {
  getCountryConfigSuccess,
  getCountryRefDataSuccess,
  setTrackingId,
  setCountryRefDataError,
  setUserTrackId,
  setCorporateAccountProp,
  setIsoCountryList,
  setContactBreRegex,
  setContactDaoRegex,
  setVisitingBreRegex,
  setVisitingDaoRegex,
  setBillingBreRegex,
  setBillingDaoRegex,
  setCompanyNameBreRegex,
  setCompanyNameDaoRegex,
  setCompanyLegalBreRegex,
  setLandingPage,
} from '../actions/corporateAccountActions';
import {
  getCountryConfig,
  getCountryRefData,
  generateTrackId,
  getUserTrackId,
  getLandingPage,
} from '../../api/apiWrapper';
import {
  setContactFieldRules,
  setContactFields,
  setPreferredCommunicationMethods,
  setPreferredLanguages,
} from '../actions/contactActions';
import { setOptionFlow } from '../actions/daoActions';
import { setAppTimers, setBlackListUrls } from '../actions/appActions';
import { setDocumentLanguages } from '../actions/shippingActions';
import { setCompanyFieldRules } from '../actions/companyActions';
import { pageMapper } from '../../utils/constants';

function* corporateAccountSaga() {
  yield takeEvery(GET_COUNTRY_REF_DATA, onGetCountryRefData);
  yield takeEvery(GET_COUNTRY_CONFIG, onGetCountryConfig);
  yield takeEvery(GENERATE_TRACK_ID, onGenerateTrackId);
  yield takeEvery(GET_USER_TRACK_ID, onGetUserTrackId);
  yield takeEvery(GET_LANDING_PAGE, onGetLandingPage);
}

export function* onGetCountryRefData(action) {
  try {
    const dhlCountry = yield call(getCountryRefData, action.payload);
    const isoCountry = yield call(getCountryRefData, { refDatCatTyCd: 'ISO_COUNTRY' });
    const ctryList: any = [];

    if (dhlCountry?.refDatLst?.length > 0 && isoCountry?.refDatLst?.length > 0) {
      dhlCountry.refDatLst.forEach((dhlData) => {
        const dhlCountryData = { ...dhlData };
        const isoCountryName = isoCountry.refDatLst.find((isoData) => isoData.refDatCd === dhlData.refDatCd)?.refDatDsc;
        dhlCountryData['extPrm']['isoCtryName'] = isoCountryName;
        ctryList.push(dhlCountryData);
      });
    }

    yield put(getCountryRefDataSuccess({ refDatLst: ctryList }));
    yield put(setIsoCountryList({ refDatLst: ctryList }));
  } catch (error) {
    yield put(setCountryRefDataError());
  }
}

export function* onGetCountryConfig(action) {
  try {
    const cntryConfigResponse = yield call(getCountryConfig, action.payload);

    yield put(getCountryConfigSuccess(cntryConfigResponse));
    yield put(setContactFields(cntryConfigResponse));
    yield put(setContactFieldRules(cntryConfigResponse));
    yield put(setOptionFlow(cntryConfigResponse));
    yield put(setAppTimers(cntryConfigResponse));
    yield put(setBlackListUrls(cntryConfigResponse));
    yield put(setPreferredLanguages(cntryConfigResponse));
    yield put(setPreferredCommunicationMethods(cntryConfigResponse));
    yield put(setDocumentLanguages(cntryConfigResponse));
    yield put(setCompanyFieldRules(cntryConfigResponse));
    yield put(setContactBreRegex(cntryConfigResponse));
    yield put(setContactDaoRegex(cntryConfigResponse));
    yield put(setCompanyLegalBreRegex(cntryConfigResponse));
    yield put(setCompanyNameBreRegex(cntryConfigResponse));
    yield put(setCompanyNameDaoRegex(cntryConfigResponse));
    yield put(setVisitingBreRegex(cntryConfigResponse));
    yield put(setVisitingDaoRegex(cntryConfigResponse));
    yield put(setBillingBreRegex(cntryConfigResponse));
    yield put(setBillingDaoRegex(cntryConfigResponse));
  } catch (error) {
    yield put(setCorporateAccountProp('countryConfigData', {}));
  }
}

export function* onGenerateTrackId(action) {
  try {
    const userTrackIdResponse = yield call(generateTrackId, action.payload);
    yield put(setTrackingId(userTrackIdResponse.userTrackId));
  } catch (error) {
    yield put(setTrackingId(error));
  }
}

export function* onGetUserTrackId(action) {
  try {
    const response = yield call(getUserTrackId, action.payload);
    yield put(setUserTrackId(response.userTrackId));
  } catch (error) {
    yield put(setUserTrackId(error));
  }
}

export function* onGetLandingPage(action) {
  try {
    const response = yield call(getLandingPage, action.payload);
    const mappedPage = Object.keys(pageMapper).find((key) => pageMapper[key] === response.landingPage) ?? 'landing';
    yield put(setLandingPage(mappedPage));
  } catch (error) {
    yield put(setLandingPage(error));
  }
}

export default corporateAccountSaga;
