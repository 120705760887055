import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { FormikControl } from '../../common';
import { WEBSITE_PLACEHOLDER } from '../../../utils/constants';

const AdditionalInfo = () => {
  const { translations } = useSelector((state: any) => state.account);
  const {
    common: { select },
    company: {
      heading,
      label,
      tooltip,
      accessibility,
      error,
      dropdowns: { companyIndustries },
    },
  } = translations;
  const { companyMandatoryFields, company } = useSelector((state: any) => state.company);
  const companyCountryCode = company.registeredCountry[0]?.code;

  const companyIndustryOptions = ['', ...companyIndustries].map((industry) => {
    return {
      value: industry,
      label: industry,
    };
  });

  return (
    <>
      <Row className="field-row">
        <Col>
          <p className="address">{heading.ADDITIONAL_INFORMATION}</p>
        </Col>
      </Row>
      <Row className="field-row">
        <Col>
          <FormikControl
            control="input"
            name="companyWebsite"
            ariaLabel={accessibility.label.COMPANY_WEBSITE}
            type="text"
            label={label.COMPANY_WEBSITE}
            mandatoryLabel={companyMandatoryFields.includes('companyWebsite') ? '*' : ''}
            placeholder={WEBSITE_PLACEHOLDER}
            fieldInfo={{ info: `${companyCountryCode === 'US' ? label.COMPANY_WEBSITE_TOOLTIP : ''}` }}
            delayErrorMsg={true}
            mandatoryMsg={error.website.EMPTY}
          />
        </Col>
      </Row>
      <Row className="field-row">
        <Col>
          <FormikControl
            control="select"
            placeholder={select.INITIAL_VALUE}
            name="companyIndustry"
            ariaLabel={accessibility.label.COMPANY_INDUSTRY}
            label={label.COMPANY_INDUSTRY}
            mandatoryLabel={companyMandatoryFields.includes('companyIndustry') ? '*' : ''}
            options={companyIndustryOptions}
            {...(tooltip?.COMPANY_INDUSTRY && { fieldInfo: { info: tooltip.COMPANY_INDUSTRY } })}
          />
        </Col>
      </Row>
    </>
  );
};

export default AdditionalInfo;
