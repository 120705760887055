import axios from 'axios';

import { TRACKING_KEY } from '../utils/constants';
import { storage } from '../utils/helper';
import { BE_AS_AXIOS, BE_QL_AXIOS, BE_AC_AXIOS } from './constants';

const beQlAxiosApi = axios.create({
  baseURL: process.env.REACT_APP_BE_QL_URL,
});

const beASAxiosApi = axios.create({
  baseURL: process.env.REACT_APP_BE_AS_URL,
});

const beACAxiosApi = axios.create({
  baseURL: process.env.REACT_APP_BE_AC_URL,
});

const akamaiAxiosApi = axios.create({
  baseURL: process.env.REACT_APP_URL,
});

axios.interceptors.request.use(function (config) {
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

const getApiName = (apiName) => {
  if (apiName === BE_AS_AXIOS) {
    return beASAxiosApi;
  } else if (apiName === BE_QL_AXIOS) {
    return beQlAxiosApi;
  } else if (apiName === BE_AC_AXIOS) {
    return beACAxiosApi;
  } else {
    return akamaiAxiosApi;
  }
};

export async function get(url, config, apiName) {
  return await getApiName(apiName)
    .get(url, {
      ...config,
      headers: {
        ...config.headers,
        dhlTrackingId: storage.get(TRACKING_KEY),
      },
    })
    .then((response) => response.data);
}

export async function post(url, data, config, apiName, timeout = 0, fullResp = false) {
  return await getApiName(apiName)
    .post(url, data, {
      headers: {
        ...config.headers,
        dhlTrackingId: storage.get(TRACKING_KEY),
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / total);

        storage.set('percentage', percent);
      },
      timeout,
    })
    .then((response) => {
      if (fullResp) return response;
      else return response.data;
    });
}

export async function patch(url, data, config, apiName) {
  return await getApiName(apiName)
    .patch(url, data, {
      headers: {
        ...config.headers,
        dhlTrackingId: storage.get(TRACKING_KEY),
      },
    })
    .then((response) => response.data);
}
