import { takeEvery, put, call, select } from 'redux-saga/effects';

import { GET_EN_GB_LANGUAGE_COUNTRIES, GET_TRANSLATIONS_AKAMAI, SET_ALT_LANG_CODE } from '../actions/actionTypes';
import {
  setAccountProp,
  setAltLangCode,
  setEnGBLanguageCountries,
  setTranslationsAkamai,
  setTranslationsAkamaiError,
} from '../actions/accountActions';
import { getEnGBLanguageCountries, getLanguageCode, getTranslationsAkamai } from '../../api/apiWrapper';
import { setContactProp } from '../actions/contactActions';

export function* accountSaga() {
  yield takeEvery(GET_TRANSLATIONS_AKAMAI, onGetTranslationsAkamai);
  yield takeEvery(SET_ALT_LANG_CODE, onSetAltLangCodeSaga);
  yield takeEvery(GET_EN_GB_LANGUAGE_COUNTRIES, onGetEnGBLanguageCountries);
}

export function* onGetTranslationsAkamai(action) {
  try {
    const response = yield call(getTranslationsAkamai, action.payload);
    const { selectedLanguage, languages } = response;
    yield put(setTranslationsAkamai(response[selectedLanguage]));
    yield put(setAccountProp('selectedLanguage', selectedLanguage));
    yield put(setAccountProp('languages', languages));
    yield put(setAltLangCode(selectedLanguage));
  } catch (error) {
    yield put(setTranslationsAkamaiError(error));
  }
}

export function* onSetAltLangCodeSaga(action) {
  try {
    const selectedLanguage = action.payload;
    const selectedLanguageTwoLangCode = selectedLanguage.split('-')[0];
    const response = yield call(getLanguageCode, action.payload, selectedLanguageTwoLangCode);
    const { preferredLanguages } = yield select((state) => state.contact);
    const altLanguageCode = response[selectedLanguageTwoLangCode];
    if (preferredLanguages?.length > 0) {
      let preferredLang = preferredLanguages.find((preferredLanguage) => preferredLanguage.value === altLanguageCode);
      if (!preferredLang) {
        preferredLang = preferredLanguages[0];
      }
      yield put(setAccountProp('altLanguageCode', preferredLang.value));
      yield put(setContactProp('preferredLanguage', preferredLang.label));
    }
    if (preferredLanguages?.length === 0) {
      yield put(setAccountProp('altLanguageCode', altLanguageCode));
    }
  } catch (error) {
    yield put(setAccountProp('altLanguageCode', ''));
  }
}

export function* onGetEnGBLanguageCountries(action) {
  try {
    const response = yield call(getEnGBLanguageCountries, action.payload);
    yield put(setEnGBLanguageCountries(response));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('--------error: ', error);
  }
}

export default accountSaga;
