import { takeEvery, put, call, select } from 'redux-saga/effects';
import {
  GET_PAYMENT_PROFILE,
  GET_PAYMENT_STATUS,
  GET_DEBIT_SUCCESS,
  SET_PAYMENT_ETL_LIST,
  SET_PAYMENT_LOADOUT,
  GET_PAYMENT_METHOD_TYPE,
} from '../actions/actionTypes';
import {
  setPaymentProfile,
  setPaymentStatus,
  setDebitDetails,
  setDebitError,
  setCardDetails,
  setIxopayRidirect,
  setPaymentError,
  setPaymentProfileError,
} from '../actions/paymentActions';
import {
  getDebit,
  getPaymentMethodType,
  getPaymentProfile,
  getPaymentStatus,
  setPaymentLoadout,
} from '../../api/apiWrapper';
import { setEtlList, setEtlStatus } from '../actions/appActions';
import { Etl } from '../../utils/types';

export function* paymentSaga() {
  yield takeEvery(GET_DEBIT_SUCCESS, getDebitSaga);
  yield takeEvery(GET_PAYMENT_STATUS, getPaymentStatusSaga);
  yield takeEvery(GET_PAYMENT_PROFILE, getPaymentProfileSaga);
  yield takeEvery(SET_PAYMENT_ETL_LIST, setPaymentEtlListSaga);
  yield takeEvery(SET_PAYMENT_LOADOUT, setPaymentLoadoutSaga);
  yield takeEvery(GET_PAYMENT_METHOD_TYPE, getPaymentMethodTypeSaga);
}

export function* getDebitSaga(action) {
  try {
    const response = yield call(getDebit, action.payload);
    yield put(setDebitDetails(response));
    yield put(setIxopayRidirect(response));
  } catch (error) {
    yield put(setDebitError(error));
  }
}

export function* getPaymentStatusSaga(action) {
  try {
    yield put(setPaymentError(false));
    const response = yield call(getPaymentStatus, action.payload);
    yield put(setPaymentStatus(response));
  } catch (error) {
    yield put(setPaymentError(true));
  }
}

export function* getPaymentProfileSaga(action) {
  try {
    const paymentProfileResponse = yield call(getPaymentProfile, action.payload);
    yield put(setPaymentProfile(paymentProfileResponse));
    yield put(setCardDetails(paymentProfileResponse));
  } catch (error: any) {
    yield put(setPaymentProfileError(error));
  }
}

export function* setPaymentEtlListSaga() {
  let currentEtlList: any = [];
  const paymentEtlList: Etl[] = [];
  const {
    paymentProfileCode,
    iframeRetryCount,
    transactionRetryCount,
    debitError,
    paymentStatus,
    paymentError,
    timeoutRetryCount,
  } = yield select((state) => state.payment);
  const { etlList } = yield select((state) => state.app);

  if (paymentProfileCode >= 400) {
    paymentEtlList.push({ code: '9007', source: 'payment' });
  }

  if (iframeRetryCount >= 3 && debitError) {
    paymentEtlList.push({ code: '5003', source: 'Too many retry to generate IXOPAY iFrame' });
  }

  if (timeoutRetryCount >= 3 && paymentError) {
    paymentEtlList.push({ code: '5005', source: 'Too many retry for IXOPAY timeout' });
  }

  if (transactionRetryCount >= 3 && paymentStatus.success === false) {
    paymentEtlList.push({ code: '5006', source: 'Too many IXOPAY error attemptTransaction' });
  }

  currentEtlList = [...etlList, ...paymentEtlList];
  const isEtl = currentEtlList.length > 0;

  yield put(setEtlStatus(isEtl));
  yield put(setEtlList(currentEtlList));
}

export function* setPaymentLoadoutSaga(action) {
  try {
    yield call(setPaymentLoadout, action.payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('--------error: ', error);
  }
}

export function* getPaymentMethodTypeSaga(action) {
  try {
    yield call(getPaymentMethodType, action.payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('--------error: ', error);
  }
}

export default paymentSaga;
