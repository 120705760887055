import { useEffect, useState } from 'react';

export function useSearchDebounce(delay = 500) {
  const [search, setSearch] = useState('');
  const [delaySearchError, setDelaySearchError] = useState('');
  const [delayError, setDelayError] = useState(false);

  useEffect(() => {
    const delayFn = setTimeout(() => {
      setDelayError(true);
      setDelaySearchError(search);
    }, delay);

    return () => {
      setDelayError(false);
      clearTimeout(delayFn);
    };
  }, [search]);

  return [delaySearchError, setSearch, delayError] as const;
}
