import { takeEvery, put, select, call } from 'redux-saga/effects';

import { Etl } from '../../utils/types';
import { GET_MAINTENANCE_STATUS, UPDATE_ETL_LIST } from '../actions/actionTypes';
import { setEtlStatus, setEtlList, setAppProp, setMaintenanceStatus } from '../actions/appActions';
import { BRV_EXTERNAL_WEBSITE_ERROR, GLOBAL_COUNTRY_CODE } from '../../utils/constants';
import { calculateStringMatchingPercentage, objectsEqual } from '../../utils/helper';
import { getMaintenanceStatus } from '../../api/apiWrapper';

export function* appSaga() {
  yield takeEvery(UPDATE_ETL_LIST, updateEtlListSaga);
  yield takeEvery(GET_MAINTENANCE_STATUS, getMaintenanceStatusSaga);
}

function getContactEtlList(
  contactCountryConfig,
  validateMyBillData,
  email,
  profileExists,
  contactPaymentProfileCode,
  validateMyBillHttpStatus
) {
  const etlList: Etl[] = [];

  const isDaoCountry =
    contactCountryConfig?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DAO_COUNTRY')?.value === 'Y';

  const emailCharLength = contactCountryConfig?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'EMAIL_CHAR_LEN')?.value;

  if (!isDaoCountry) {
    etlList.push({ code: '1001', source: 'contact' });
  }

  if (email.length > emailCharLength) {
    etlList.push({ code: '1003', source: 'contact' });
  }

  if (validateMyBillHttpStatus >= 500) {
    etlList.push({ code: '9001', source: 'contact' });
  } else if (validateMyBillData.valid === false) {
    etlList.push({ code: '1002', source: 'contact' });
  }

  if (profileExists) {
    etlList.push({ code: '1004', source: 'contact' });
  }

  if (contactPaymentProfileCode >= 400) {
    etlList.push({ code: '9012', source: 'payment' });
  }

  return etlList;
}

function getAddressEtlList(companyInformation, values, billingAddressInfo) {
  let visitingAddressOverride = false;
  let billingAddressOverride = false;

  const tempEtlList: Etl[] = [];
  const { companyAddresses } = companyInformation;
  const { companyAddresses: legalIdbillingCompanyAddress } = billingAddressInfo ?? {};

  const visitingAddress = companyAddresses?.find((address) => address.addressType === 'COM');
  const billingAddress = legalIdbillingCompanyAddress?.find((address) => address.addressType === 'BIL') || null;

  if (visitingAddress) {
    visitingAddressOverride = !objectsEqual(visitingAddress, values['vAddress']);
  }

  if (billingAddress) {
    billingAddressOverride = !objectsEqual(billingAddress, values['bAddress']);
  }

  if (visitingAddressOverride) {
    tempEtlList.push({ code: '3002', source: 'company' });
  }

  if (billingAddressOverride) {
    tempEtlList.push({ code: '3002', source: 'company' });
  }

  if (
    companyInformation?.addrCheckApplicable === 'Y' &&
    visitingAddress &&
    billingAddress === null &&
    !values.billingAddressSame &&
    (values['bAddress']?.addressLine1?.length > 0 ||
      values['bAddress']?.addressLine2 !== '' ||
      values['bAddress']?.city !== '' ||
      values['bAddress']?.zipCode !== '' ||
      values['bAddress']?.state !== '' ||
      values['bAddress']?.province !== '')
  ) {
    tempEtlList.push({ code: '3002', source: 'company' });
  }
  // Uncomment the code once enable the logic for BRV call for each legal id

  // if (
  //   companyInformation?.addrCheckApplicable === 'Y' &&
  //   billingAddressInfo?.addrCheckApplicable === 'Y' &&
  //   visitingAddress &&
  //   !values.billingAddressSame &&
  //   (values['bAddress']?.addressLine1?.length < 0 ||
  //     values['bAddress']?.addressLine2 === '' ||
  //     values['bAddress']?.city === '' ||
  //     values['bAddress']?.zipCode === '' ||
  //     values['bAddress']?.state === '' ||
  //     values['bAddress']?.province === '')
  // ) {
  //   tempEtlList.push({ code: '3002', source: 'company' });
  // }

  return tempEtlList;
}

function getCompanyEtlList(
  companyCountryConfig,
  values,
  contactCountryCode,
  companyInformation,
  isValidated,
  brvHttpStatus,
  brvError,
  billingAddressInfo
) {
  let etlList: Etl[] = [];
  let companyCountryCode;
  let vAddressCountryCode;
  let bAddressCountryCode;
  const { companyName, addrCheckApplicable, companyAddresses } = companyInformation;

  const isDaoCountry =
    companyCountryConfig?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'DAO_COUNTRY')?.value === 'Y';

  const maxCompanyNameLength =
    companyCountryConfig?.ctryCfgs?.find(
      (ctryCfg) => ctryCfg.typeCd === 'MAX_COMPANY_NAME_LENGTH' && ctryCfg.ctryCd === values.registeredCountry[0]?.code
    )?.value ?? null;

  const defaultMaxCompanyNameLength = companyCountryConfig?.ctryCfgs?.find(
    (ctryCfg) => ctryCfg.typeCd === 'MAX_COMPANY_NAME_LENGTH' && ctryCfg.ctryCd === GLOBAL_COUNTRY_CODE
  )?.value;

  const exceedCompanyNameLength =
    (maxCompanyNameLength && values.companyName.length > maxCompanyNameLength) ||
    (!maxCompanyNameLength && values.companyName.length > defaultMaxCompanyNameLength);

  const brvExternalWebsiteError =
    brvError && Object.values(brvError).length > 0
      ? Object.values(brvError).some((err: any) => err.error === BRV_EXTERNAL_WEBSITE_ERROR)
      : false;

  const compNmMatchPer =
    companyCountryConfig?.ctryCfgs?.find((ctryCfg) => ctryCfg.typeCd === 'CPNY_NM_SCORING_TRSHLD')?.value || 100;

  if (values.registeredCountry) {
    companyCountryCode = values.registeredCountry[0]?.code;
  }

  if (contactCountryCode !== companyCountryCode) {
    etlList.push({ code: '2003', source: 'company' });
  }

  if (!isDaoCountry) {
    etlList.push({ code: '1001', source: 'company' });
  }

  if (values.registrationNotPresent) {
    etlList.push({ code: '2002', source: 'company' });
  }

  if (brvHttpStatus >= 500) {
    etlList.push({ code: '9003', source: 'company' });
  }

  if (brvExternalWebsiteError) {
    etlList.push({ code: '9011', source: 'company' });
  }

  if (exceedCompanyNameLength) {
    etlList.push({ code: '2004', source: 'company' });
  }

  if (companyInformation?.companyName) {
    const compNameCheckApplied = companyInformation?.compNameCheckApplicable === 'Y';
    if (compNameCheckApplied) {
      const matchingPercentage: any = calculateStringMatchingPercentage(
        companyName.toLowerCase().trim(),
        values.companyName.toLowerCase().trim()
      );
      if (companyName.toLowerCase().trim() !== values.companyName.toLowerCase().trim()) {
        if (matchingPercentage < compNmMatchPer) {
          etlList.push({ code: '3001', source: 'company' });
        }
      }
    }
  }

  if (addrCheckApplicable === 'Y') {
    if (companyAddresses.length > 0) {
      const addressEtl = getAddressEtlList(companyInformation, values, billingAddressInfo);
      etlList = etlList.concat(addressEtl);
    }
  }

  if (values.companyAddressCountry) {
    vAddressCountryCode = values.companyAddressCountry[0]?.code;
  }

  if (values.billingAddressCountry) {
    bAddressCountryCode = values.billingAddressCountry[0]?.code;
  }

  if (vAddressCountryCode || bAddressCountryCode) {
    if (
      companyCountryCode !== vAddressCountryCode ||
      (!values.billingAddressSame && companyCountryCode !== bAddressCountryCode)
    ) {
      etlList.push({ code: '2005', source: 'company' });
    }
  }

  return etlList;
}

export function* updateEtlListSaga(action) {
  const { validateMyBillData, companyInformation, values, contactCountryCode, isValidated } = action.payload;
  let etlList: any = [];

  const { countryConfigData: contactCountryConfig } = yield select((state) => state.corporateAccount);
  const { countryConfigData: companyCountryConfig } = yield select((state) => state.company);
  const { brvHttpStatus, brvError, billingAddressInfo } = yield select((state) => state.DAOReducer);
  const {
    paymentProfileCode: contactPaymentProfileCode,
    paymentProfile: { profileExists = false },
  } = yield select((state) => state.payment);
  const {
    contact: { email },
    validateMyBillHttpStatus,
  } = yield select((state) => state.contact);

  const contactEtlList = getContactEtlList(
    contactCountryConfig,
    validateMyBillData,
    email,
    profileExists,
    contactPaymentProfileCode,
    validateMyBillHttpStatus
  );
  const companyEtlList = getCompanyEtlList(
    companyCountryConfig,
    values,
    contactCountryCode,
    companyInformation,
    isValidated,
    brvHttpStatus,
    brvError,
    billingAddressInfo
  );

  etlList = [...contactEtlList, ...companyEtlList];

  const isEtl = etlList.length > 0 || values?.registrationNotPresent;

  yield put(setEtlStatus(isEtl));
  yield put(setEtlList(etlList));
  yield put(setAppProp('contactCompanyEtlList', etlList));
}

export function* getMaintenanceStatusSaga(action) {
  try {
    const { countryCode, clientId } = action.payload;
    const response = yield call(getMaintenanceStatus);
    if (response.GLOBAL.includes(countryCode ?? '') || (clientId && response[clientId]?.includes(countryCode))) {
      yield put(setMaintenanceStatus(1));
    } else {
      yield put(setMaintenanceStatus(0));
    }
  } catch (error) {
    yield put(setMaintenanceStatus(0));
  }
}

export default appSaga;
